/*!

 File: set-mindset-grid-drag.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Mindset Grid Dragging UI
*/

;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.MindsetGridDrag = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var MindsetGridDrag = (function () {

        var Constructor = function () {

            //
    		// Variables
            //
            var publicAPIs = {};
            var settings = {};

            var startX, deltaX, offsetLeft, bboxWidth, xposRatio, xposRatioInv, raf;
            var elHandle, elBbox, elColorBox, elOutput;
            var direction;
            

            //
    		// Methods
            //
            
            /**
    		* Private methods
            */
            
            var initDrag = function(el){

                // set direction
                direction = (el.classList.contains('ms-grid-drag-north') ? 'north' : 'south') + '-' + (el.classList.contains('ms-grid-drag-east') ? 'east' : 'west') ;

                // get elements
                elBbox = el.querySelector('.js-ms-drag-bounding');
                elColorBox = el.querySelector('.js-ms-grid-drag-box');
                elOutput = el.querySelector('.js-ms-grid-drag-output');
                elHandle = el.querySelector('.js-ms-grid-drag-handle');
                console.log('el',el);
                console.log('elHandle',elHandle);

                // Get initial values
                if(!elOutput){
                    console.error('MindsetGridDrag: missing .js-ms-grid-drag-output field');
                }
                var value = elOutput ? Number(elOutput.value) : 0;
                switch(direction) {
                    case 'south-west':
                    case 'north-west':
                        xposRatioInv = value != '' ? value : 0;
                        xposRatio = Math.round((100 - xposRatioInv) * 100) / 100; // round down
                        break;
                    default:
                        // south east
                        xposRatio = value != '' ? value : 0;
                        xposRatioInv = Math.round((100 - xposRatio) * 100) / 100; // round down
                }

                var userPressed = function(e){

                    // elHandle = e.target.closest('.js-ms-grid-drag-handle');
                    if (!elHandle) return;
    
                    startX = e.clientX;
    
                    offsetLeft = elHandle.getBoundingClientRect().left - elBbox.getBoundingClientRect().left;
                    
                    bboxWidth = elBbox.offsetWidth;
    
                    document.addEventListener('pointermove', userMoved, { passive: true });
                    document.addEventListener('pointerup', userReleased, { passive: true });
                    document.addEventListener('pointercancel', userReleased, { passive: true });
    
                    // Set cursor until release
                    switch(direction) {
                        case 'north-east':
                        case 'south-west': 
                            document.body.style.cursor = 'nesw-resize';
                        break;
                        default:
                            document.body.style.cursor = 'nwse-resize';
                    }
    
                }
    
                var userMoved = function(e){
                    if (!raf) {
                        // This is to account for the larger handle which has to move a bit further than the box it is in
                        var dirOffset = (direction == 'south-west' || direction == 'north-west') ? 40 : 0;
                        // this is to account for the location within the box that the user has clicked
                        deltaX = (e.clientX + dirOffset) - startX;
                        var xpos = offsetLeft + deltaX;
                        //
                        xpos = xpos > 0 ? xpos : 0; // Left constraint
                        xpos = xpos < bboxWidth ? xpos : bboxWidth; // Right constraint
                        
                        xposRatio = (xpos / bboxWidth) * 100;
                        xposRatio = Math.round(xposRatio * 100) / 100; // round down
                        
                        raf = requestAnimationFrame(function(){
                            userMovedRaf(false);
                        });
        
                    }
                }
    
                var userMovedRaf = function(slider){
    
                    xposRatioInv = Math.round((100 - xposRatio) * 100) / 100; // round down
    
                    switch(direction) {
                        case 'north-east':
                            elHandle.style.left = xposRatio + "%";
                            elHandle.style.top = 'calc(' + xposRatioInv + '% - 40px)';
                            elColorBox.style.top = 'calc(' + xposRatioInv + '% - 40px)';
                            elColorBox.style.right = xposRatioInv + "%";
                            if(elOutput && !slider){
                                elOutput.value = xposRatio;
                            }
                            break;
                        case 'south-west':
                            elHandle.style.left = 'calc(' + xposRatio + '% - 40px)';
                            elHandle.style.top = xposRatioInv + "%";
                            elColorBox.style.left = 'calc(' + xposRatio + '% - 40px)';
                            elColorBox.style.bottom = xposRatio + "%";
                            if(elOutput && !slider){
                                elOutput.value = xposRatioInv;
                            }
                            break;
                        case 'north-west':
                            elHandle.style.left = 'calc(' + xposRatio + '% - 40px)';
                            elHandle.style.top = 'calc(' + xposRatio + '% - 40px)';
                            elColorBox.style.left = elColorBox.style.top = 'calc(' + xposRatio + '% - 40px)';
                            if(elOutput && !slider){
                                elOutput.value = xposRatioInv;
                            }
                            break;
                        default:
                            // south east
                            elHandle.style.left = xposRatio + "%";
                            elHandle.style.top = xposRatio + "%";
                            elColorBox.style.right = elColorBox.style.bottom = xposRatioInv + "%";
                            if(elOutput && !slider){
                                elOutput.value = xposRatio;
                            }
                    }
                    //
                    raf = null;
                }
    
                var userReleased = function(e){
                    document.removeEventListener('pointermove', userMoved);
                    document.removeEventListener('pointerup', userReleased);
                    document.removeEventListener('pointercancel', userReleased);
                    // blur button
                    elHandle.blur();
                    // reset cursor
                    document.body.style.cursor = 'default';
                    // run callback
                    if (typeof settings.callBack === "function") { 
                        settings.callBack();
                    }
                }
    
                var userKeydown = function(key){
                    var adv = 1;
                    switch(direction) {
                        case 'north-east':
                            // down / left
                            if(key == 40 || key == 37) {
                                adv=-1;
                            }
                        break;
                        case 'south-east': 
                            // up / left
                            if(key == 38 || key == 37) {
                                adv = -1;
                            }
                        break;
                        case 'south-west': 
                            // down / left
                            if(key == 40 || key == 37) {
                                adv = -1;
                            }
                        break;
                        case 'north-west': 
                            // up / left
                            if(key == 38 || key == 37) {
                                adv=-1;
                            }
                        break;
                    }
                    keyAdvance(adv);
                }
    
                var keyAdvance = function(dir){
                    xposRatio += (dir*10);
                    // round to nearest 10
                    xposRatio = Math.ceil(xposRatio / 10) * 10;
                    xposRatioInv = 100-xposRatio
                    // CLAMP    
                    xposRatio = xposRatio > 100 ? 100 : xposRatio;
                    xposRatio = xposRatio < 0 ? 0 : xposRatio;
                    xposRatioInv = xposRatioInv > 100 ? 100 : xposRatioInv;
                    xposRatioInv = xposRatioInv < 0 ? 0 : xposRatioInv;
                    userMovedRaf(false);
                    // run callback
                    if (typeof settings.callBack === "function") { 
                        settings.callBack();
                    }
                }
                
                // Press Event
                elHandle.addEventListener('pointerdown', userPressed, { passive: true });

                // Keypress event
                elHandle.addEventListener("keydown", function(e) {
                    if(e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {
                        e.preventDefault();
                        userKeydown(e.keyCode);
                    }
                });

                // Hover events
                el.addEventListener('mouseenter', function() {  
                    el.classList.add('ms-grid-drag-container-focussed');
                });
                el.addEventListener('mouseleave', function() {  
                    el.classList.remove('ms-grid-drag-container-focussed');
                });

                // range change
                var sliderUpdate = function(){
                    var value = elOutput.value;

                    switch(direction) {
                        case 'north-east':
                            xposRatio = value;
                            xposRatioInv = 100-value;
                            break;
                        case 'south-west':
                            xposRatio = 100-value;
                            xposRatioInv = value;
                            break;
                        case 'north-west':
                            xposRatio = 100-value;
                            xposRatioInv = value;
                            break;
                        default:
                            // south east
                            xposRatio = value;
                            xposRatioInv = 100-value;
                            
                    }

                    userMovedRaf(true);
                }

                elOutput.addEventListener('change', sliderUpdate);
                elOutput.addEventListener('input', sliderUpdate);

            }


            /**
            * Public methods
            */

            publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;
                settings.callBack = options.callBack || null;

                if(settings.el){
                    initDrag(settings.el);
                }else{
                    console.log('mindset-grid-drag.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

            };

            //
    		// Return the Public APIs
    		//

    		return publicAPIs;

        };

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the MindsetGridDrag function to be assigned to the window object/module
    return MindsetGridDrag;

});
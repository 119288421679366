/*!

 File: set-ajax-update.js
 Author: Ember
 Version: 1.0.1 [MS-901] - table toggle
 JS Dependencies:
     'helper-on' - helper,
     'url-search-params-polyfill' - polyfill,

 CSS Dependencies:

 Description:
    On blur ajax post contents to an end point

*/

function allTheSame(array) {
    var first = array[0];
    return array.every(function(element) {
        return element === first;
    });
}

var setTableToggle = {
    toggleTimer: null,
    init: function (selector) {
        var me = setTableToggle;
        //
        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-table-toggle-btn')) return;

            // Button clicked
            e.preventDefault();

            let elBody = el.closest('tbody');

            //
            if(elBody){
                // Get the next sibling element
                var elToggleBlock = elBody.nextElementSibling;
                if(elToggleBlock && elToggleBlock.classList.contains('js-table-toggle-content')){
                    if(elToggleBlock.classList.contains('hidden')){
                        el.classList.add('toggle-open');
                        elToggleBlock.classList.remove('hidden');
                    }else{
                        el.classList.remove('toggle-open');
                        elToggleBlock.classList.add('hidden');
                    }
                    
                }
            }

        }

        var changeEvt = function(e){

            var el = e.target;

            // Parent click
            if (el.closest('.js-table-check-all')){

                // Button clicked
                e.preventDefault();

                // Get all child checkboxes
                let elBody = el.closest('tbody');

                if(elBody){
                    // Get the next sibling element
                    var elToggleBlock = elBody.nextElementSibling;
                    if(elToggleBlock && elToggleBlock.classList.contains('js-table-toggle-content')){
                        var isChecked = el.checked;
                        var group = el.getAttribute('data-toggle-group');
                        var elChecks = elToggleBlock.querySelectorAll('input[type=checkbox][data-toggle-group="'+group+'"]');
                        //
                        for (var i= 0; i< elChecks.length; i++) {
                            elChecks[i].checked = isChecked;
                            triggerEvent(elChecks[i], 'change');
                        }
                    }
                }

            }else{

                if (el.closest('.js-table-toggle-content') && el.closest('input[type=checkbox]')){

                    var group = el.getAttribute('data-toggle-group');
                    //
                    let elBody = el.closest('tbody');
                    if(elBody){

                        clearTimeout(me.toggleTimer);

                        // try to stop race conditions
                        me.toggleTimer = setTimeout(function(){

                            let array = [];
                            var elChildren = elBody.querySelectorAll('input[data-toggle-group="' + group + '"]');
                            forEachNode(elChildren, function (i, el) {
                                array.push(el.checked);
                            });

                            // Change state of parent
                            // Get the next sibling body
                            var elParentToggleBlock = elBody.previousElementSibling;
                            var parentCheck = elParentToggleBlock.querySelector('.js-table-check-all[data-toggle-group="' + group + '"]');
                            if (parentCheck){
                                parentCheck.classList.remove('indeterminate');
                                if(!allTheSame(array)){
                                    parentCheck.indeterminate = true;
                                }else{
                                    parentCheck.indeterminate = false;
                                    parentCheck.checked = array[0];
                                }
                            }
                            

                            /*
                            // Get the next sibling body
                            var elParentToggleBlock = elBody.previousElementSibling;
                            if(elParentToggleBlock){
                                // Get all the elements in the group
                                var elChildren = elParentToggleBlock.querySelectorAll('input[data-toggle-group="' + group + '"]');
                                forEachNode(elChildren, function (i, el) {
                                    console.log(el.checked);
                                });
                                console.log(elChildren);
                            }
                            */

                        },50);

                        
                    }
                }

            }

        }

        // Attach events
        on('click', document, clickEvt);
        on('change', document, changeEvt);
        
    },
    destroy: function () {

    }
};

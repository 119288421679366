/*!

 File: set-gmap.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:
    jQuery,

 CSS Dependencies:


 Description:
    Basic Google map init

*/

function initZoomControl(map) {
    document.querySelector('.zoom-control-in').onclick = function() {
        map.setZoom(map.getZoom() + 1);
    };
    document.querySelector('.zoom-control-out').onclick = function() {
        map.setZoom(map.getZoom() - 1);
    };
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.querySelector('.zoom-control'));
}

function initMapTypeControl(map, elTarget) {
    var mapTypeControlDiv = document.querySelector('.maptype-control');
    document.querySelector('.maptype-control-map').onclick = function() {
        mapTypeControlDiv.classList.add('maptype-control-is-map');
        mapTypeControlDiv.classList.remove('maptype-control-is-satellite');
        map.setMapTypeId('roadmap');
    };
    document.querySelector('.maptype-control-satellite').onclick = function() {
        mapTypeControlDiv.classList.remove('maptype-control-is-map');
        mapTypeControlDiv.classList.add('maptype-control-is-satellite');
        map.setMapTypeId('hybrid');
    };
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(mapTypeControlDiv);
}

function initFullscreenControl(map) {
    var elementToSendFullscreen = map.getDiv().firstChild;
    var fullscreenControl = document.querySelector('.fullscreen-control');
    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
        fullscreenControl);
        fullscreenControl.onclick = function() {
            if (isFullscreen(elementToSendFullscreen)) {
                exitFullscreen();
            } else {
                requestFullscreen(elementToSendFullscreen);
        }
    };

    document.onwebkitfullscreenchange =
    document.onmsfullscreenchange =
    document.onmozfullscreenchange =
    document.onfullscreenchange = function() {
        if (isFullscreen(elementToSendFullscreen)) {
            fullscreenControl.classList.add('is-fullscreen');
        } else {
            fullscreenControl.classList.remove('is-fullscreen');
        }
    };
}

function isFullscreen(element) {
    return (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) == element;
}
function requestFullscreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen();
    }
}
function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msCancelFullScreen) {
        document.msCancelFullScreen();
    }
}

function disableTab(nodeList){
    if(nodeList && nodeList.length>0){
        forEachNode(nodeList, function (i, el) {
            el.tabIndex = 999;
        });
    }
}


var initGmap = {
    init: function (elTarget) {

        var thisLat = 50,
            thisLong = 2,
            thisZoom = 16,
            thisTitle,
            thisAddr,
            thisUrl;

        thisLat = elTarget.getAttribute('data-map-lat');
        thisLong = elTarget.getAttribute('data-map-long');
        thisZoom = parseInt(elTarget.getAttribute('data-map-zoom'));
        thisTitle = elTarget.getAttribute('data-map-title');
        thisAddr = elTarget.getAttribute('data-map-address');
        thisUrl = elTarget.getAttribute('data-map-url');

        //
        var myMap = {
            bounds: null,
            map: null
        }
        //
        myMap.init = function(selector, latLng, zoom) {
            var myOptions = {
                zoom:zoom,
                center: latLng,
                scrollwheel: false,
                //zoomControl: true,
                //zoomControlOptions: {
                    //style: google.maps.ZoomControlStyle.SMALL
                //},
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true
            }
            //
            this.map = new google.maps.Map(selector, myOptions);
            this.bounds = new google.maps.LatLngBounds();
            // Center marker on resize
            var center;
            function calculateCenter() {
                center = myMap.map.getCenter();
            }
            //
            google.maps.event.addDomListener(myMap.map, 'click', function() {
                this.setOptions({scrollwheel:true});
            });
            // Idle should happen after everything is intialised
            google.maps.event.addDomListener(myMap.map, 'idle', function() {
                calculateCenter();
                // disable tab index
                var elTabbed = elTarget.querySelectorAll('[tabindex]')
                disableTab(elTabbed);
            });
            //
            google.maps.event.addDomListener(window, 'resize', function() {
                myMap.map.setCenter(center);
            });
            //
            initZoomControl(myMap.map);
            initMapTypeControl(myMap.map, elTarget);
            initFullscreenControl(myMap.map);
        };
        //
        myMap.placeMarkers = function() {
            // create a new LatLng point for the marker
            var point = new google.maps.LatLng(parseFloat(thisLat),parseFloat(thisLong));
            // add marker
            var marker = new google.maps.Marker({
                position: point,
                map: myMap.map
            });
            // create the tooltip and its text
            var infoWindow = new google.maps.InfoWindow();
            var html='<h4>'+thisTitle+'<\/h4><p>'+thisAddr+'<\/p>';
            // add a listener to open the tooltip when a user clicks on one of the markers
            google.maps.event.addListener(marker, 'click', function() {
                infoWindow.setContent(html);
                infoWindow.open(myMap.map, marker);
            });
        };
        //
        var myLatLng = new google.maps.LatLng(thisLat, thisLong);
        myMap.init(elTarget, myLatLng, thisZoom);
        myMap.placeMarkers();
    }
}

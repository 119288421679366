/*!

 File: data-attr-check.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:

 Description:
    Helper function for dealing with the existance / value of data attributes on elements

*/

var checkDataAttr = function(el, thisAttr, checkBlank){
    if (el instanceof jQuery || el instanceof $){
        el = el[0];
    }

    var attr = el.getAttribute(thisAttr),
        result = false;

    if(checkBlank){
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (attr != null && attr != 'false' && attr != '0') {
            result = true;
        }
    }else{
        // Default - If data attr exists and isn't empty
        if (attr != null && attr != '') {
            result = true;
        }
    }
    return result;
}

/* Usage:

// allows data-attribute and data-attribute="true"  otherwise returns false
if (checkDataAttr(el, 'data-attribute', true)) {

// If data attr exists and isn't empty
if (checkDataAttr(el, 'data-cell-placeholder')) {

*/

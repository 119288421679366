/*!

 File: set-range-slider.js
 Author: Ember
 Version: 1.0.5 - [MMP-5] - check for csrf token
 JS Dependencies:
    polyfill-raf,
    helper-get-position,
    helper-debounce,
    helper-on,
    helper-off

 CSS Dependencies:
    _site-range-slider-html.scss

 Description:
     Drag to slide
*/


;(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define([], function () {
            return factory(root);
        });
    } else if (typeof exports === 'object') {
        module.exports = factory(root);
    } else {
        root.RangeSlider = factory(root);
    }
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var RangeSlider = (function () {

        var Constructor = function () {

            //
            // Variables
            //
            var publicAPIs = {};
            var settings = {};

            //
            // Methods
            //

            /**
             * Private methods
             */

            var initRange = function (el) {

                var once = true;

                var elInput = el.querySelector('input[type="range"]');
                var elRangeThumb = el.querySelector('.js-range-thumb');

                // values for thumb px offset
                var thumbWidth = elRangeThumb.offsetWidth;
                // in case thumb is hidden and we can't get the width
                if (!thumbWidth || thumbWidth == 0 || thumbWidth == '0') {
                    var style = window.getComputedStyle ? getComputedStyle(elRangeThumb, null) : elRangeThumb.currentStyle;
                    thumbWidth = parseInt(style.width);
                }

                var rangeMin = 0;
                var rangeMax = (thumbWidth * -1); // width of slider button

                var newVal;
                var pxOffset;

                var raf;

                var positionThumb = function (e) {
                    // Get percentage from value
                    var val = elInput.value;
                    var min = elInput.min ? elInput.min : 0;
                    var max = elInput.max ? elInput.max : 100;
                    newVal = Number(((val - min) * 100) / (max - min));
                    // Get a px offset based on thumb width
                    pxOffset = ((newVal * (rangeMax - rangeMin) / 100) + rangeMin);
                    pxOffset = Math.round(pxOffset);
                    //
                    if (!raf) {
                        raf = requestAnimationFrame(positionThumbRaf);
                    }
                }

                var positionThumbRaf = function () {
                    elRangeThumb.style.left = 'calc(' + newVal + '% + ' + pxOffset + 'px)';
                    //
                    // run callback when moving
                    if (typeof settings.moveCallBack === "function") {
                        settings.moveCallBack(elInput);
                    }
                    //
                    raf = null;
                }

                elInput.addEventListener('input', positionThumb);


                var thumbClick = function (e) {
                    elRangeThumb.classList.add('active');
                };

                elInput.addEventListener('touchstart', thumbClick);
                elInput.addEventListener('mousedown', thumbClick);


                var changeEvent = function (e) {
                    // IE11 doesn't support input
                    positionThumb();
                    //
                    if (typeof settings.moveCallBack === "function") {
                        settings.moveCallBack(elInput);
                    }
                    // Change Callback should fire when user releases
                    if (typeof settings.changeCallBack === "function") {
                        settings.changeCallBack();
                    }

                    // On change callback function name can be passed in as a data attribute.
                    let onChangeCallback = checkDataAttr(el, 'data-onchange-callback') ? el.getAttribute('data-onchange-callback') : null;
                    if(onChangeCallback && typeof window[onChangeCallback] === 'function'){
                        // Callback function
                        window[onChangeCallback](el, elInput.value/10);
                    }

                    // update the hidden screen reader only element
                    // const rangeUpdateEl = el.querySelector('.js-range-slider-update');
                    // if (checkDataAttr(el, 'data-behaviour')) {
                    //     let behaviour = el.getAttribute('data-behaviour');
                    //     let behaviourType = checkDataAttr(el, 'data-behaviour-type') ? el.getAttribute('data-behaviour-type') : null;
                    //     rangeUpdateEl.innerHTML = decileScoreDescription(behaviour, elInput.value, behaviourType);
                    // }

                    // Post to backend?
                    postData();
                };

                elInput.addEventListener('change', changeEvent);

                elInput.addEventListener('focus', function () {
                    el.classList.add('focus');
                });

                elInput.addEventListener('blur', function () {
                    el.classList.remove('focus');
                });

                positionThumb();

                // AJAX POSTING

                var disableSlider = function () {
                    el.classList.add('disabled');
                    el.classList.remove('focus');
                    elInput.disabled = true;
                    //
                    elInput.blur();
                }

                var enableSlider = function () {
                    el.classList.remove('disabled');
                    el.classList.add('focus');
                    elInput.disabled = false;
                    //
                    elInput.focus();
                }

                // declare an axios request's cancelToken
                var ajaxRequest = null;

                var postData = function () {
                    // Get URL
                    var updateUrl;
                    // If data attr exists and isn't empty
                    if (checkDataAttr(el, 'data-update-url')) {
                        updateUrl = el.getAttribute('data-update-url');
                    }

                    if (updateUrl) {
                        // Disable slider
                        //disableSlider();

                        // Param object
                        var params = {};

                        // Add input value
                        params['value'] = elInput.value;

                        // Add any extra data to Params
                        var extraData = el.getAttribute('data-extra');
                        if (extraData && extraData != '') {
                            extraData = JSON.parse(extraData);
                            for (var i in extraData) {
                                params[i] = extraData[i];
                            }
                        }

                        // Call back function name can be passed in as a data attribute.
                        var callback;
                        // If data attr exists and isn't empty
                        if (checkDataAttr(el, 'data-callback')) {
                            callback = el.getAttribute('data-callback');
                        }

                        // cancel  previous ajax if exists
                        if (ajaxRequest) {
                            ajaxRequest.cancel('Request cancelled');
                        }

                        // create a new token for upcomming ajax (overwrite the previous one)
                        ajaxRequest = axios.CancelToken.source();


                        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                        var config = {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                            },
                            cancelToken: ajaxRequest.token
                        }

                        // Add csrf token if exists
                        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
                        var csrf;

                        if (elCsrf) {
                            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
                        }

                        // Default error message
                        var errorMessage = 'Sorry, there was a problem with your request.';

                        // pause for effect
                        setTimeout(function () {

                            // AJAX request
                            axios.post(updateUrl, params, config)
                                .then(function (response) {
                                    var responseJson = response.data;

                                    if (responseJson.status == 'success') {

                                        // Show notification
                                        var responseMessage;
                                        //
                                        if (responseJson.message && responseJson.message.text && responseJson.message.text != '') {
                                            Notify({
                                                text: responseJson.message.text,
                                                status: 'success',
                                                offset: 100
                                            }).showNotify();
                                        }

                                        // enable slider
                                        //enableSlider();

                                        // Callback function
                                        if (typeof window[callback] === 'function') {
                                            window[callback](el, response.data);
                                        }
                                    }

                                    // Returned Error
                                    if (responseJson.status == 'error') {
                                        // enable slider
                                        //enableSlider();

                                        // See if a message was returned
                                        if (responseJson.message && responseJson.message.text && responseJson.message.text != '') {
                                            errorMessage = responseJson.message.text;
                                        }

                                        // Show error notification
                                        Notify({
                                            text: errorMessage,
                                            status: 'error',
                                            offset: 100
                                        }).showNotify();

                                    }


                                })
                                .catch(function (error) {

                                    // We don't want error messages when request is cancelled
                                    if (!axios.isCancel(error)) {
                                        // enable slider
                                        //enableSlider();

                                        if (error.response) {
                                            errorMessage += '<br>' + error.response;
                                        }

                                        // Show error notification
                                        Notify({
                                            text: errorMessage,
                                            status: 'error',
                                            offset: 100
                                        }).showNotify();
                                    }

                                });

                        }, 400);


                    }
                }
            }


            /**
             * Public methods
             */

            publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;
                settings.changeCallBack = options.changeCallBack || null;
                settings.moveCallBack = options.moveCallBack || null;

                if (settings.el) {
                    initRange(settings.el);
                } else {
                    console.log('range-slider.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

            };

            //
            // Return the Public APIs
            //

            return publicAPIs;

        };

        //
        // Return the Constructor
        //

        return Constructor;


    })();

    // Returning the RangeSlider function to be assigned to the window object/module
    return RangeSlider;

});





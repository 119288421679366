/*!

 File: set-projective-exercise-activity.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
    Click on an image to position marker
*/

var setProjectiveExerciseActivity = {
    init: function (el) {

        var elNextBtn = el.querySelector('.js-pe-activity-next-btn');

        var elMarkerPlace = el.querySelector('.js-place-marker');

        if(elMarkerPlace){

            var markerPlaced = function(){
                if(elNextBtn){
                    elNextBtn.classList.remove('disabled');
                    elNextBtn.disabled = false;
                }
            }

            var makerPlace = new MarkerPlace;

            // Initialise place
            makerPlace.init({
                el: elMarkerPlace,
                callBack: markerPlaced
            });

        }

        
    },


}

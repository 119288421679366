/*!

 File: set-float-labels.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
     scrollToElement

 CSS Dependencies:

 Description:
    Show / hide scroll to top button

*/

var setScrollTop = {
    init: function (elTarget) {
        console.log('scroll to top')
    },
    destroy: function (target) {
    }
}

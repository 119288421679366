// ============================================== Misc Functions //

// Any Miscellaneous functions can be added here
//
// Ideally they would eventually be refactored into their own modules
//

var testCallback = function (el, response) {
    console.log('testCallback');
    console.log(response);
};

var testPreCallback = function (el) {

    Notify({
        text: 'Pre Callback Success!',
        status: 'success',
        offset: 100
    }).showNotify();

    return (true);

};

var redrawParticipantReportTile = function (el, response) {
    $(el).closest('.js-report-tile').replaceWith(response.html);
};

/**
 * Callback to redirect to a location passed in from a response object
 *
 * @param $target
 * @param responseJson
 */
var redirectAfterUpdate = function ($target, responseJson) {
    //console.log(responseJson);
    window.location.href = responseJson.url;
};

/**
 * Redraw a tracker row after generation
 * @param el
 * @param response
 */
var redrawTrackerRow = function (el, response) {
    var elContainer = el.closest('.js-tracker-row');
    if (elContainer) {
        elContainer.innerHTML = response.html;
        initFields($(elContainer));
    }
};

/**
 * Update module checkboxes after response from server
 * @param el
 * @param response
 */
var updateModuleRelease = function (el, response) {

    if (response.status == 'success') {
        var elTarget = el.closest('.js-module-release-checkboxes');

        // Get all checkboxes
        var elCheckboxes = elTarget.querySelectorAll('input[type=checkbox].js-module-release-checkbox');

        // Convert nodelist to array
        var checkArray = Array.prototype.map.call(elCheckboxes, (function (node) {
            return node;
        }));

        //Get the index of our selected checkbox
        var checkIndex = checkArray.indexOf(el);

        checkArray.forEach(function (elCheck, i) {
            if (el.checked) {
                // all before this one to become checked
                if (i < checkIndex) {
                    elCheck.checked = true;
                }
            } else {
                // all after this one to become unchecked
                if (i > checkIndex) {
                    elCheck.checked = false;
                }
            }

        });
    }

};

/**
 * Hide the alert box that contains an element.
 *
 * @param el
 * @param response
 */
var hideAlertCB = function (el, response) {
    if (response.status == 'success') {
        var elContainer = el.closest('.js-alert');
        if (elContainer) {
            formAlert.hide(elContainer);
        }
    }
};

/**
 * Update the practitioners events list -
 * @param el
 * @param response
 */
var updateEventsList = function (el, response) {
    var elEventsTable = document.querySelector('#js-archived-events-table');
    var elEventsList = document.querySelector('#js-active-events-list');
    var elCountText = document.querySelector('#js-active-events-count');
    //
    if (elEventsTable) {

        if (response.is_archived == 1) {

            // Remove the tile from the interface
            var elSummary = el.closest('.summary-item');
            if (elSummary) {
                elSummary.parentNode.removeChild(elSummary);
            }
            //Add the entity to the data set for the archived events table
            var data = {'entity': response.entity};
            setDataTable.updateRow(elEventsTable, false, data);

        } else {

            // Add the tile to the interface
            var elDiv = document.createElement('div');
            elDiv.innerHTML = response.html;
            var elClone = elDiv.firstElementChild;
            if (elEventsList) {
                elEventsList.append(elClone.cloneNode(true));
            }
            //Remove the entity from the data set for the archived events table
            var data = [{"id": response.entity.id}];
            // remove rows
            setDataTable.externalRemoveRow(elEventsTable, data);
        }

        //Update the count of active events
        if (elEventsList) {
            var elEventsListItems = elEventsList.querySelectorAll('.js-active-event-tile');
            var activeTilesCount = elEventsListItems.length;
            var activeEventsCountText = activeTilesCount + ' active event';
            activeEventsCountText += activeTilesCount == 1 ? '' : 's';
            if (elCountText) {
                elCountText.textContent = activeEventsCountText;
            }
        }

    }

};

var domUpdate = function (response) {

    $(el).closest('.js-report-tile').replaceWith(response.html);
};

/**
 * Update the (normally hidden) section within the range slider intended to describe the final value for screen reader
 * @param el
 * @param score
 */
var updateScoreDescriptionCallback = function (el, score) {
    //console.log('updateScoreDescriptionCallback');
    //console.log(score);
    //Update range slider update section
    const rangeUpdateEl = el.querySelector('.js-range-slider-update');
    let description = decileScoreDescription(el, score);
    if (checkDataAttr(el, 'data-is-self-assessment')) {
        description = 'You believe ' + description;
    }
    rangeUpdateEl.innerHTML = description;
};

/**
 * Return a readable description of a decile score position, in relation to the two poles of growth and survival.
 *
 * If this function is updated, the php equivalent (decile_score_description) must also be updated to match.
 *
 * @return string
 * @param el
 * @param score
 */
var decileScoreDescription = function (el, score) {
    console.log(score);
    if (score < 1 || score > 10) return '';

    let behaviour;
    let behaviourType;
    if (checkDataAttr(el, 'data-behaviour')) {
        behaviour = el.getAttribute('data-behaviour');
        console.log(behaviour);
        behaviourType = checkDataAttr(el, 'data-behaviour-type') ? el.getAttribute('data-behaviour-type') : null;
        console.log(behaviourType);
    }

    //Check whether this is a subscale (e.g Interpersonal effectiveness scale) - these use a different vocabulary
    if (checkDataAttr(el, 'data-is-subscale') && el.getAttribute('data-is-subscale')) {
        return decileScoreDescriptionForSubScale(score, behaviour, behaviourType);
    } else {
        return decileScoreDescriptionForSingleScale(score, behaviour, behaviourType);
    }
};

/**
 * Return a readable description of a decile score position for a single standard scale
 *
 * @param score
 * @param behaviour
 * @param behaviourType
 * @returns {string}
 */
let decileScoreDescriptionForSingleScale = function (score, behaviour, behaviourType) {
    const adverbs = [
        'very significantly ',
        'significantly ',
        '',
        'slightly '
    ];

    const poles = behaviourType !== 'survival' ? ['survival', 'growth'] : ['growth', 'survival'];
    const pole = score < 5 ? poles[0] : (score > 6 ? poles[1] : null);
    let text = '';
    if (score > 4 && score < 7) {
        text = 'neither consistently in Survival or firmly in Growth';
    } else {
        if (score < 5) {
            text = 'tending ' + adverbs[score - 1] + 'towards ' + pole;
        } else {
            text = 'tending ' + adverbs[10 - score] + 'towards ' + pole;
        }
    }

    const description = 'your ' + behaviour + ' habit is ' + text;
    console.log(description);
    return description;
};

/**
 * Return a readable description of a decile score position for a single subscale
 *
 * @param score
 * @param behaviour
 * @param behaviourType
 * @returns {string}
 */
let decileScoreDescriptionForSubScale = function (score, behaviour, behaviourType) {
    const adverbs = ['significantly', '', 'somewhat', 'slightly'];
    let text;
    if (score > 4 && score < 7) {
        text = 'neither higher nor lower';
    } else {
        if (score < 5) {
            text = adverbs[score-1] + ' lower';
        } else {
            text = adverbs[10 - score] + ' higher';
        }
    }

    const description = 'your connection with the ' + behaviourType + ' habit of ' + behaviour + ' is ' + text + ' than others';
    console.log(description);
    return description;
};


/**
 * Accessibility - Font size and high contrast control
 */
$(document).ready(function () {
    var fontSize = 16;

    /* Increase Text */
    $("#increase-text").click(function () {
        if (fontSize >= 24) {
            return false;
        } else {
            fontSize = fontSize + 1;
            $(".main").css("font-size", fontSize + "px");

            console.log('+ ' + fontSize);
        }
    });

    /* Decrease Text */
    $("#decrease-text").click(function () {
        if (fontSize <= 10) {
            return false;
        } else {
            fontSize = fontSize - 1;
            $(".main").css("font-size", fontSize + "px");

            console.log('+ ' + fontSize);
        }
    });

    /* Default */
    $("#normal-text").click(function () {
        var fontSize = 16;

        $(".main").css("font-size", fontSize + "px");
        $('body').removeClass("contrast-white");
        $('body').removeClass("contrast-black");
    });

    /* Contrast Black */
    $("#contrast-b").click(function () {
        $('body').addClass("contrast-black");
        $('body').removeClass("contrast-white");
    });

    /* Contrast White */
    $("#contrast-w").click(function () {
        $('body').addClass("contrast-white");
        $('body').removeClass("contrast-black");
    });
});


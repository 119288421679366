/*!

 File: set-report-chart.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:

 CSS Dependencies:

 Description:
     Build a chart.js chart based on data attributes
*/

;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.ReportChart = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var ReportChart = (function () {

        var Constructor = function () {

    		//
    		// Variables
    		//

    		var publicAPIs = {};

            var settings = {};

    		//
    		// Methods
    		//

    		/**
    		 * A private method
    		 */
    		var buildChart = function (el) {

                var ctx = el.getContext('2d');

                var options = {
                    animation: {
                        duration: 0
                    },
                    tooltips: {enabled: false},
                    hover: {mode: null},
                    plugins: {
                        tooltip: {
                            enabled: false
                        },
                        legend: {
                            position: 'bottom',
                            labels: {
                                boxWidth: 12,
                                padding: 30
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true
                }

                options.scales = {
                    xAxis: {
                    },
                    yAxis: {
                    }
                }


                var yCallback = function(value, index, values) {

                    let intValue = value;

                    if(settings.yLabels && settings.yLabels.length){
                        if(value===0){
                            intValue = '';
                        }else{
                            intValue = settings.yLabels[value-1];
                        }
                    }
                    
                    if(settings.unitsBefore){
                        intValue = settings.unitsBefore + intValue;
                    }

                    if(settings.unitsAfter){
                        intValue = settings.unitsAfter + intValue;
                    }
                    
                    return intValue;
                }

                
                if(settings.type === "bar"){
                    var intYMin = settings.yMin ? Number(settings.yMin) : null;
                    var intYMax = settings.yMax ? Number(settings.yMax) : null;
                    var intYInc = settings.yInc ? Number(settings.yInc) : null;
                    options.scales.yAxis = {
                        min: intYMin,
                        max: intYMax,
                        ticks: {
                            stepSize: intYInc,
                            callback: yCallback
                        }
                    }
                }

                // Vertical labels on x scale
                if(settings.verticalLabels && settings.type === "bar"){
                    options.scales.xAxis['ticks'] = {
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 90
                    }
                }


                // Is it a stacked chart
                if(settings.stacked && settings.type === "bar"){
                    options.scales.xAxis['stacked'] = true;
                    options.scales.yAxis['stacked'] = true;
                }

                // Datalabels stuff
                var datalabels = {
                    display: false
                }

                var dataLabelsFontSize = 16;

                if(settings.showValues){
                    if(settings.unitsBefore || settings.unitsAfter){
                        options.plugins['datalabels'] = {
                            formatter: function(value) {
                                if(settings.unitsBefore){
                                    return settings.unitsBefore + value;
                                }
                                if(settings.unitsAfter){
                                    return value + settings.unitsAfter;
                                }
                            }
                        }
                    }
                }

                /*plugins: {
                    datalabels: {
                      formatter: (val) => {
                        return val + ' kg';
                      }
                    }
                }*/

                // Data
                var dataSets = [];

                // Pie chart setup
                if(settings.type === "pie"){

                    options.scales = null;

                    for(var i=0; i<settings.data.length; i++){

                        if(settings.showValues){
                            var labelColour = '#ffffff';
                            if(settings.data[i].labelColour && settings.data[i].labelColour.length){
                                labelColour = settings.data[i].labelColour;
                            }
                            // Custom text inside slice
                            var dataLabelsData = []
                            if(settings.data[i].dataLabels && settings.data[i].dataLabels.length){
                                dataLabelsData = settings.data[i].dataLabels;
                            }
                            //
                            datalabels = {
                                color: labelColour,
                                align: 'end',
                                offset: 0,
                                font: {
                                    size: dataLabelsFontSize
                                },
                                textAlign: 'center',
                                formatter: function(value, context) {
                                    if(dataLabelsData && dataLabelsData.length){
                                        return dataLabelsData[context.dataIndex];
                                    }
                                }
                            }
                        }
                        
                        var obj = {
                            data: settings.data[i].data,
                            backgroundColor: settings.data[i].colour,
                            hoverBackgroundColor: settings.data[i].colour,
                            hoverOffset: 4,
                            borderWidth: 0,
                            hoverOffset: 0,
                            datalabels: datalabels
                        }

                        dataSets.push(obj);

                    }
                    
                }

                // Bar chart setup
                if(settings.type === "bar"){
                    for(var i=0; i<settings.data.length; i++){

                        if(settings.showValues){
                            var labelColour = '#ffffff';
                            if(settings.data[i].labelColour && settings.data[i].labelColour.length){
                                labelColour = settings.data[i].labelColour;
                            }
                            var labels = null;
                            // show secondary labels
                            if(settings.secondaryData && settings.secondaryData.length){
                                labels = {
                                    title: {
                                        anchor: 'end',
                                        align: 'end',
                                    },
                                    description: {
                                        formatter: function(value, context) {
                                            return settings.secondaryData[context.dataIndex];
                                        }
                                    }
                                }
                            }
                            // Custom text inside bar
                            var dataLabelsData = []
                            if(settings.data[i].dataLabels && settings.data[i].dataLabels.length){
                                dataLabelsData = settings.data[i].dataLabels;
                            }
                            //
                            datalabels = {
                                color: labelColour,
                                font: {
                                    size: dataLabelsFontSize
                                },
                                labels: labels,
                                textAlign: 'center',
                                formatter: function(value, context) {
                                    if(dataLabelsData && dataLabelsData.length){
                                        return dataLabelsData[context.dataIndex];
                                    }
                                }
                            }
                        }

                        var obj = {
                            data: settings.data[i].data,
                            label: settings.data[i].label,
                            backgroundColor: settings.data[i].colour,
                            hoverBackgroundColor: settings.data[i].colour,
                            datalabels: datalabels
                        }

                        dataSets.push(obj);

                    }
                }

                var labels = settings.labels;

                // MULTIPLE / X AXIS
                if(Array.isArray(settings.labels[0])){
                    
                    labels = [];
                    var groupLabels = [];

                    // Add some space to first Xaxis
                    options.plugins.legend.labels['padding'] = 40;

                    // Process labels / group labels
                    for(var i=0; i<settings.labels.length; i++){
                        var label = settings.labels[i][1];
                        labels.push(label);
                        //
                        var groupLabel = settings.labels[i][0]
                        var prevGroupLabel;
                        if(settings.labels[i-1]){
                            prevGroupLabel = settings.labels[i-1].length ? settings.labels[i-1][0] : null;
                        }

                        if(groupLabel != prevGroupLabel){
                            var obj = {
                                groupLabel : groupLabel,
                                index : i
                            }
                            groupLabels.push(obj);
                        }
                    }

                    // BUILD SECONDARY AXIS
                    options['animation'] = {
                        duration: 0,
                        onComplete: function(context) {

                            var chart = context.chart;
                            var chartArea = chart.chartArea;
                            var xAxis = chart.scales['xAxis'];
                            var numTicks = xAxis.ticks.length;

                            var barWidth = xAxis.width / numTicks;

                            ctx.strokeStyle = '#cecece';
                            
                            for(var i=0; i<groupLabels.length; i++){
                                var thisIndex = groupLabels[i].index;
                                var nextIndex;
                                if(i<groupLabels.length-1){
                                    nextIndex = groupLabels[i+1].index;
                                }else{
                                    nextIndex = numTicks;
                                }
                               
                                var startPos = (barWidth * groupLabels[i].index) + chartArea.left;
                                var endPos = (barWidth * nextIndex) + chartArea.left;
                                var centerPos = ((endPos-startPos)/2)+startPos;
                                
                                var yPos = chart.height - 50;
                                ctx.textAlign = 'center';
                                ctx.fillText(groupLabels[i].groupLabel, centerPos, yPos);

                                //
                                ctx.beginPath();
                                ctx.moveTo(startPos, chartArea.bottom);
                                ctx.lineTo(startPos, chartArea.bottom + xAxis.height + 20);
                                
                                ctx.stroke();
                            }

                            // Last line
                            ctx.beginPath();
                            ctx.moveTo(chartArea.right, chartArea.bottom);
                            ctx.lineTo(chartArea.right, chartArea.bottom + xAxis.height + 20);
                            
                            ctx.stroke(); 
                    
                        }
                    }
                    
                }

                // Build chart
                var data = {
                    labels: labels,
                    datasets: dataSets
                };

                var chart = new Chart(ctx, {
                    plugins: [ChartDataLabels],
                    type: settings.type,
                    data: data,
                    options: options
                });


            };


    		/**
            * Public methods
            */
    		publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;
                if(settings.el){

                    settings.type = options.type || null;
                    settings.data = options.data || null;
                    settings.labels = options.labels || null;
                    settings.showValues = options.showValues || null;
                    settings.stacked = options.stacked || null;
                    settings.unitsBefore = options.unitsBefore || null;
                    settings.unitsAfter = options.unitsAfter || null;
                    settings.secondaryData = options.secondaryData || null;
                    settings.verticalLabels = options.verticalLabels || null;
                    settings.yMax = options.yMax || null;
                    settings.yMin = options.yMin || null;
                    settings.yInc = options.yInc || null;
                    settings.yLabels = options.yLabels || null;

                    buildChart(settings.el);

                }else{
                    console.log('set-report-chart.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

    		};


    		//
    		// Return the Public APIs
    		//

    		return publicAPIs;

    	};

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the ReportChart function to be assigned to the window object/module
    return ReportChart;

});


var colourAlias = [
    {
        'name' : 'mp-purple',
        'hex' : '#93579a'
    },
    {
        'name' : 'mp-blue',
        'hex' : '#3d7ebf'
    },
    {
        'name' : 'mp-yellow',
        'hex' : '#f8ca00'
    },
    {
        'name' : 'mp-green',
        'hex' : '#bbc510'
    },
    {
        'name' : 'mp-green-alt',
        'hex' : '#538d46'
    },
    {
        'name' : 'mp-orange',
        'hex' : '#d87b0f'
    },
    {
        'name' : 'mp-orange',
        'hex' : '#d87b0f'
    },
    {
        'name' : 'dstl-red',
        'hex' : '#cb2858'
    },
    {
        'name' : 'dstl-blue',
        'hex' : '#3fbdec'
    },
    {
        'name' : 'dstl-purple',
        'hex' : '#7b69a6'
    },
    {
        'name' : 'dstl-green',
        'hex' : '#37b5b1'
    },
    
]

// Resize charts before printing to hopefully set proper widths
/*function beforePrintHandler () {
    for (var id in Chart.instances) {
        var thisChart = Chart.instances[id];
        if(thisChart.config.type === 'bar'){
            thisChart.resize(1200,'auto');
        }
    }
}*/

var setReportChart = {
    init: function (selector) {

        Chart.defaults.font.family = "'Montserrat'";

        var elReportChart = document.querySelectorAll(selector);
        if(elReportChart && elReportChart.length>0){
            forEachNode(elReportChart, function (i, el) {

                // Get Data
                var chartType = el.dataset.chartType;
                var chartLabels = el.dataset.chartLabels ? JSON.parse(el.dataset.chartLabels) : null;
                var chartData = el.dataset.chartData ? JSON.parse(el.dataset.chartData) : null;
                var showValues = el.dataset.chartShowValues;
                var stacked = el.dataset.chartStacked;
                var unitsBefore = el.dataset.chartUnitsBefore;
                var unitsAfter = el.dataset.chartUnitsAfter;
                var secondaryData = el.dataset.chartDataSecondary ? JSON.parse(el.dataset.chartDataSecondary) : null;
                var verticalLabels = el.dataset.chartVerticalLabels;
                var yMin = el.dataset.chartYmin;
                var yMax = el.dataset.chartYmax;
                var yInc = el.dataset.chartYinc;
                var yLabels = el.dataset.chartYlabels ? JSON.parse(el.dataset.chartYlabels) : null; 

                // convert tokens to hexes
                for(var i=0; i<chartData.length; i++){
                    if(chartData[i].colour && chartData[i].colour.length){
                        for(var j=0; j<chartData[i].colour.length; j++) {
                            if (chartData[i].colour[j].indexOf('#') === -1) {
                                for(var k=0; k<colourAlias.length; k++) {
                                    if(colourAlias[k].name == chartData[i].colour[j]){
                                        chartData[i].colour[j] = colourAlias[k].hex;
                                    }
                                }
                            }
                        }
                    }
                }

                //
                // Create canvas and add to dom
                var canvas = document.createElement('canvas');
                el.appendChild(canvas); // adds the canvas to the body element
                //
                var reportChart = new ReportChart;
                //
                reportChart.init({
                    el: canvas,
                    type: chartType,
                    labels: chartLabels,
                    data: chartData,
                    showValues: showValues,
                    stacked: stacked,
                    unitsBefore: unitsBefore,
                    unitsAfter: unitsAfter,
                    secondaryData: secondaryData,
                    verticalLabels: verticalLabels,
                    yMin: yMin,
                    yMax: yMax,
                    yInc: yInc,
                    yLabels: yLabels
                });

            });

            //window.addEventListener('beforeprint', beforePrintHandler);
            
        }

    }
}

/*!

 File: set-feelings-gauge-activity.js
 Author: Ember
 Version: 1.0.3
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Feelings Gauge Activity UI
*/

var setFeelingsGaugeActivity = {
    init: function (elTarget) {
        var me = setFeelingsGaugeActivity;

        /* ELEMENTS */

        var elContentIntro = elTarget.querySelector('.js-fg-content-intro');
        var elStartBtn = elTarget.querySelector('.js-fg-start-btn');

        var elContentOutro = elTarget.querySelector('.js-fg-content-outro');
        var elFinishBtn = elTarget.querySelector('.js-fg-finish-btn');

        var elCardStack = elTarget.querySelector('.js-emotion-card-stack');
        var elCardStackWrapper = elTarget.querySelector('.js-emotion-card-stack-wrapper');

        var elFeelingsGauge = elTarget.querySelector('.js-fg-activity-fg');

        elNETotal = elTarget.querySelector('.js-fg-card-total-ne');
        elSETotal = elTarget.querySelector('.js-fg-card-total-se');
        elSWTotal = elTarget.querySelector('.js-fg-card-total-sw');
        elNWTotal = elTarget.querySelector('.js-fg-card-total-nw');

        elNEResults = elTarget.querySelector('.js-fg-card-results-ne');
        elSEResults = elTarget.querySelector('.js-fg-card-results-se');
        elSWResults = elTarget.querySelector('.js-fg-card-results-sw');
        elNWResults = elTarget.querySelector('.js-fg-card-results-nw');

        var elIncorrectMessage = elTarget.querySelector('.js-fg-incorrect');
        var elCorrectMessage = elTarget.querySelector('.js-fg-correct');

        /* CARD STACK */

        var cardStack = new CardStack;

        /* OBJECTS */

        var neTotal = 0;
        var seTotal = 0;
        var swTotal = 0;
        var nwTotal = 0;

        // This should be refactored - originally a message would show only if correct
        // Rich changed it to show total instead so had to do a quick fix
        var neMessageTotal = 0;
        var seMessageTotal = 0;
        var swMessageTotal = 0;
        var nwMessageTotal = 0;

        var history = [];

        /* START BUTTON FUNCTIONS */

        var startBtnClick = function(e){
            e.preventDefault();
            // Stop more clicks
            off('click', elStartBtn, startBtnClick);
            // fade out button
            elContentIntro.classList.add('animate-hide');
            //
            setTimeout(function(){
                // Remove button from dom
                elContentIntro.parentNode.removeChild(elContentIntro);
                // Fade in new block
                elCardStackWrapper.classList.remove('hidden');
                elCardStackWrapper.offsetHeight; // force reflow
                elCardStackWrapper.classList.remove('animate-hide');
                // Show cards
                elCardStack.classList.remove('hidden');
                //
                setTimeout(function(){

                    // Card Stack callbacks

                    var cardsInitCallbackFunc = function(){
                        // Activate gauge buttons after intro anim
                        elFeelingsGauge.classList.add('active');
                    };

                    var lastCardCallbackFunc = function(){
                        // End Activity
                        elFeelingsGauge.classList.remove('active');
                        // Fade out card area
                        elCardStackWrapper.classList.add('animate-hide');
                        //
                        setTimeout(function(){
                            // Remove from dom
                            elCardStackWrapper.classList.add('hidden');
                            // Enable submit button
                            elFinishBtn.classList.remove('disabled');
                            elFinishBtn.disabled = false;
                            // Show finish content
                            elContentOutro.classList.remove('hidden');
                            // Show
                            elContentOutro.offsetHeight; // force reflow
                            elContentOutro.classList.remove('animate-hide');
                            //
                        }, 200);
                    };

                    var correctCallbackFunc = function(cardMessage){
                        if(cardMessage && cardMessage != ''){
                            var elMessage;
                            switch(history[history.length-1]) {
                                case 'ne':
                                    //elMessage = elNEResults;
                                    neMessageTotal ++;
                                    if(elNEResults){
                                        elNEResults.innerText = neMessageTotal;
                                    }
                                    break;
                                case 'se':
                                    //elMessage = elSEResults;
                                    seMessageTotal ++;
                                    if(elSEResults){
                                        elSEResults.innerText = seMessageTotal;
                                    }
                                    break;
                                case 'sw':
                                    //elMessage = elSWResults;
                                    swMessageTotal ++;
                                    if(elSWResults){
                                        elSWResults.innerText = swMessageTotal;
                                    }
                                    break;
                                case 'nw':
                                    //elMessage = elNWResults;
                                    nwMessageTotal ++;
                                    if(elNWResults){
                                        elNWResults.innerText = nwMessageTotal;
                                    }
                                    break;
                            }
                            // add card message to quadrant (no longer needed)
                            /*if(elMessage){
                                var htmlBreak = elMessage.innerHTML != '' ? '<br>' : '';
                                elMessage.innerHTML = elMessage.innerHTML + htmlBreak + cardMessage;
                            }*/

                        }
                        if(elCorrectMessage){
                            elCorrectMessage.classList.remove('animate-hide');
                            setTimeout(function(){
                                elCorrectMessage.classList.add('animate-hide');
                            }, 1000);
                        }
                    };

                    var incorrectCallbackFunc = function(){
                        // correct / incorrect don't have undo's but just in case lets remove the last history item
                        history.pop();
                        // show message
                        if(elIncorrectMessage){
                            elIncorrectMessage.classList.remove('animate-hide');
                            setTimeout(function(){
                                elIncorrectMessage.classList.add('animate-hide');
                            }, 1000);
                        }
                    };


                    // Initialise stack
                    cardStack.init({
                        el: elCardStack,
                        cardsInitCallback: cardsInitCallbackFunc,
                        lastCardCallback: lastCardCallbackFunc,
                        correctCallback :correctCallbackFunc,
                        incorrectCallback :incorrectCallbackFunc
                    });


                },200);

            },200);
        }

        
        if(elStartBtn){
            on('click', elStartBtn, startBtnClick);
        }

        /* FEELINGS GAUGE FUNCTIONS */

        var gaugeClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-fg-quadrant-link')) return;

            // Button clicked
            e.preventDefault();

            // Make sure don't double click
            if(!elCardStack.classList.contains('is-animating')){

                // Add an active class so hover doesn't stick on ios
                el.classList.add('active');

                // Set Directions and colours of first card
                var dirClass = el.getAttribute('data-direction');
                var colourClass = el.getAttribute('data-colour');

                //
                if(el.classList.contains('fg-quadrant-link-ne')){
                    neTotal ++;
                    if(elNETotal){
                        elNETotal.innerText = neTotal;
                    }
                    history.push('ne');
                }
                //
                if(el.classList.contains('fg-quadrant-link-se')){
                    seTotal ++;
                    if(elSETotal){
                        elSETotal.innerText = seTotal;
                    }
                    history.push('se');
                }
                //
                if(el.classList.contains('fg-quadrant-link-sw')){
                    swTotal ++;
                    if(elSWTotal){
                        elSWTotal.innerText = swTotal;
                    }
                    history.push('sw');
                }
                //
                if(el.classList.contains('fg-quadrant-link-nw')){
                    nwTotal ++;
                    if(elNWTotal){
                        elNWTotal.innerText = nwTotal;
                    }
                    history.push('nw');
                }

                var quadrantId = el.getAttribute('data-id');

                var cbFunction = function(){
                    // remove total from quadrant
                    switch(history[history.length-1]) {
                        case 'ne':
                            neTotal --;
                            if(elNETotal){
                                elNETotal.innerText = neTotal;
                            }
                            break;
                        case 'se':
                            seTotal --;
                            if(elSETotal){
                                elSETotal.innerText = seTotal;
                            }
                            break;
                        case 'sw':
                            swTotal --;
                            if(elSWTotal){
                                elSWTotal.innerText = swTotal;
                            }
                            break;
                        case 'nw':
                            nwTotal --;
                            if(elNWTotal){
                                elNWTotal.innerText = nwTotal;
                            }
                            break;
                    }
                    //
                    history.pop();
                }

                // Update card stack
                cardStack.removeCard({
                    cardDirection: dirClass,
                    cardColour: colourClass,
                    clickId: quadrantId,
                    undoFunc: cbFunction,
                });

                // After card has faded out
                setTimeout(function(){
                    // Remove active class so hover doesn't stick on ios
                    el.classList.remove('active');
                    el.blur();
                    //
                }, 500);

            }

        }

        on('click', elFeelingsGauge, gaugeClick);

        /* INITIALISE START BUTTON */

        if(elStartBtn){
            elStartBtn.classList.remove('disabled');
        }

    },


}

/*!

 File: helper-sort-table.js
 Author: Mozilla
 Version: 1.0.1
 JS Dependencies:
    'closest' - polyfill
    'polyfill-array-from.js' - polyfill
    'polyfill-array-foreach.js' - polyfill

 Description:
    Helper function for sorting a table alphabetically by a column
    https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript

*/

var getCellValue = function(tr, idx, selector){
    tr = selector ? tr.children[idx].querySelector(selector) : tr.children[idx];
    //
    return tr.innerText || tr.textContent;
}

var comparer = function(idx, asc, selector) {
    return function(a, b) {
        return function(v1, v2) {
            return v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2);
        }(getCellValue(asc ? a : b, idx, selector), getCellValue(asc ? b : a, idx, selector));
    }
};

function sortTable(elTable, cellIdx, asc, selector) {

    if(elTable != null){

        var elTbody = elTable.querySelector('tbody');

        Array.from(elTable.querySelectorAll('tbody tr'))
            .sort(comparer(cellIdx, asc, selector))
            .forEach(function(tr) { elTbody.appendChild(tr) });

    }else{
        console.log('sortTable needs a target!');
    }

}

/*!

 File: set-manage-raters.js
 Author: Ember
 Version: 1.0.4 - [MMP-5] - check for csrf token
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'sortTable' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Manage Raters UI
*/

var setManageRaters = {

    currentGroupId: null,

    currentUserId: null,

    init: function (elTarget) {
        var me = setManageRaters;

        me.initAddRater(elTarget);

        me.initDelRater(elTarget);

        me.initEditRater(elTarget);

        me.initInviteSingleRater(elTarget);

        me.initInviteAllRaters(elTarget);

        me.initGroupMessage(elTarget);

    },

    // add open popup event to add buttons
    initAddRater: function(elTarget){
        var me = setManageRaters;

        // Form save events
        var elModal = elTarget.querySelector('.js-edit-rater-modal');

        // Initialise pop up button
        var addBtnClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-rater-add-btn') && !e.target.closest('.js-rater-no-results-message')) return;

            // Button clicked
            e.preventDefault();

            // Blur button
            e.target.blur();

            // We're not editing a user
            me.currentUserId = null;

            // store the current group
            me.currentGroupId = e.target.closest('.js-rater-group').getAttribute('data-group-id');

            // If I have reached total number of raters show message
            if(me.checkMax(elTarget)){
                openMpDialog(null, 'Sorry you have reached the maximum number of raters', null, null, true);
            }else{
                var elGroup = e.target.closest('.js-rater-group');

                // If I have reached total number of raters for this group show message
                if(me.checkMaxGroup(elGroup)){
                    openMpDialog(null, 'Sorry you have reached the maximum number of raters for this group', null, null, true);
                }else{
                    // open Add Rater modal
                    me.openEditRaterModal(elTarget, elModal, e.target);
                }
            }

        }

        // Attach open event
        on('click', elTarget, addBtnClick);

    },

    // add open popup event to edit buttons
    initEditRater: function(elTarget){
        var me = setManageRaters;

        var elModal = elTarget.querySelector('.js-edit-rater-modal');

        // Initialise pop up button
        var editBtnClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-edit-rater-btn')) return;

            // Button clicked
            e.preventDefault();

            // Blur button
            e.target.blur();

            // store the current user that is being edited
            var elRow = e.target.closest('.js-rater-item');
            me.currentUserId = elRow.getAttribute('data-rater-id');

            // store the current group
            me.currentGroupId = e.target.closest('.js-rater-group').getAttribute('data-group-id');;

            // open the modal
            me.openEditRaterModal(elTarget, elModal, e.target);

        }

        // Init form save event
        me.initSaveEditForm(elTarget, elModal);

        // Attach open event
        on('click', elTarget, editBtnClick);
    },

    // Open the add/edit modal
    openEditRaterModal: function(elTarget, elModal, elBtn){
        var me = setManageRaters;

        // open the 'edit' modal
        $.magnificPopup.open({
            items: {
                src: '.js-edit-rater-modal',
                type: 'inline'
            },

            alignTop: true, // Stick to top of page
            preloader: false,
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in',
            modal: false,

            // When element is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function() {
                    if($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = 'input';
                    }
                },
                open:function(){
                    me.loadAjaxForm(elTarget, elModal, elBtn);
                }
            }
        });
    },

    // Load an Ajax form into the edit modal
    loadAjaxForm: function(elTarget, elModal, elBtn){
        var me = setManageRaters;

        var elResultsContainer = elModal.querySelector('.js-edit-rater-modal-results'),
            elResultsContainerInner = elModal.querySelector('.js-edit-rater-modal-results-inner'),
            elFormContainer = elModal.querySelector('.form-disable'),
            elSpinner = elModal.querySelector('.js-spinner'),
            elSaveButton = elModal.querySelector('.js-edit-rater-modal-save');

        // Reset HTML
        elResultsContainerInner.innerHTML = '';

        // Thinking...
        elSpinner.classList.add('css-spinner-show');
        elSaveButton.disabled = true;
        elSaveButton.classList.add('disabled');

        // find alert block and reset it
        var elAlert = elModal.querySelector('.js-alert');
        if(elAlert){
            formAlert.reset(elAlert);
        }

        // Get url
        var formUrl = elTarget.getAttribute('data-edit-form-url');

        //
        if(formUrl){

            // Are we editing?
            if(me.currentUserId){
                // We're editing - remove trailing slash
                formUrl = formUrl.replace(/\/$/, '');
                // Add Rater ID
                formUrl = formUrl + '/' + me.currentUserId;
            }else{
                // We're adding - need a diff url
                formUrl = elTarget.getAttribute('data-add-form-url');
            }

            // Load the form markup
            var params;
            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Add csrf token if exists
            var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
            var csrf;

            if(elCsrf){
                config.headers['X-CSRF-TOKEN'] = elCsrf.content;
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(formUrl, params, config)
                .then(function (response) {

                    // Stop Thinking...
                    elSpinner.classList.remove('css-spinner-show');

                    var responseJson = response.data;

                    // Returned Success
                    if(responseJson.status=='success'){

                        // Set HTML
                        elResultsContainerInner.innerHTML = responseJson.html;

                        // Get height
                        var h = elResultsContainerInner.offsetHeight;

                        // Hide results
                        elResultsContainerInner.style.display = 'none';
                        elResultsContainerInner.style.maxHeight = '20px';
                        elResultsContainerInner.classList.add('animating');

                        // Group select
                        var elGroupSelect = elModal.querySelector('.js-edit-rater-modal-group-select');

                        if(elGroupSelect) {
                            // Check for full groups
                            me.checkGroupSelect(elTarget, elGroupSelect);

                            // then - if we are adding set the group selected option
                            if(!me.currentUserId && me.currentGroupId){
                                for (i = 0; opt = elGroupSelect.options[i]; i++) {
                                    if (opt.value == me.currentGroupId) {
                                        elGroupSelect.selectedIndex = i;
                                        break;
                                    }
                                }
                            }
                        }

                        // show results
                        elResultsContainerInner.style.display = 'block';
                        elResultsContainerInner.offsetHeight; // force reflow
                        elResultsContainerInner.style.maxHeight = h + 'px';

                        // Reset for next time
                        setTimeout(function(){
                            elResultsContainerInner.classList.remove('animating');
                            elResultsContainerInner.style.maxHeight = '';
                        },400);


                        // Enable save button
                        elSaveButton.disabled = false;
                        elSaveButton.classList.remove('disabled');

                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }

                    }
                })

                .catch(function (error) {
                    // Stop Thinking...
                    elSpinner.classList.remove('css-spinner-show');

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // show error in alert
                    if(elAlert){
                        formAlert.show(elAlert, errorMessage, null, 'danger');
                    }

                });

        }
    },

    // Save event for the AJAX loaded form
    initSaveEditForm: function(elTarget,elModal,elBtn){

        var me = setManageRaters;
        //
        var elSaveButton = elModal.querySelector('.js-edit-rater-modal-save');

        // Form save events
        var elEditForm = elModal.querySelector('form'),
            // Our validation library is still jquery reliant
            $editForm = $('.js-edit-rater-modal form').first();

        if(!elEditForm){
            console.log('WARNING: form element is missing!');
        }

        $editForm.validate({
            submitHandler: function (form) {
                me.editRaterSubmit(elTarget, elModal, elEditForm);
            }
        });

        on('click', elSaveButton, function(e){
            e.preventDefault();
            // trigger submit
            $editForm.submit();
        });

    },

    // Submitting the edit rater form and dealing with response
    editRaterSubmit: function(elTarget, elModal, elEditForm){
        var me = setManageRaters;
        //
        // Serialise all fields (true allows disabled fields)
        var params = serializeArray(elEditForm, true);
        //
        // Get save elements
        var elSaveButton = elModal.querySelector('.js-edit-rater-modal-save'),
            elFormContainer = elModal.querySelector('.form-disable');

        // Get url
        var saveFormUrl = elTarget.getAttribute('data-update-url');

        //
        if(saveFormUrl){
            //
            // Thinking...
            pendingButton.show(elSaveButton);
            pendingForm.disable(elFormContainer);

            // If Editing add ID to url
            if(me.currentUserId){
                // remove trailing slash
                saveFormUrl = saveFormUrl.replace(/\/$/, '');
                // Add Rater ID
                saveFormUrl = saveFormUrl + '/' + me.currentUserId;
            }

            // find alert block and reset it
            var elAlert = elModal.querySelector('.js-alert');
            if(elAlert){
                formAlert.reset(elAlert);
            }

            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Add csrf token if exists
            var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
            var csrf;

            if(elCsrf){
                config.headers['X-CSRF-TOKEN'] = elCsrf.content;
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';


            // AJAX request
            axios.post(saveFormUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // Stop Thinking...
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elFormContainer);

                    // Returned Success
                    if(responseJson.status=='success'){
                        // pause
                        setTimeout(function(){
                            // Close modal
                            $.magnificPopup.close();

                            // Insert data into page
                            setTimeout(function(){
                                if(me.currentUserId){
                                    // we are editing
                                    // Get the row we need to edit
                                    var elEditRow = elTarget.querySelector('.js-rater-item[data-rater-id="' + me.currentUserId + '"]'),
                                        elTable = elEditRow.closest('table');
                                    //
                                    if(responseJson.ratergroup_id === me.currentGroupId){
                                        // we dont need to move group
                                        //
                                        // Replace DOM with markup from string
                                        if(responseJson.html && responseJson.html!= ''){
                                            me.replaceRaterDom(elTarget, elEditRow, responseJson.html)
                                        }
                                    }else{
                                        // we are moving group
                                        //
                                        // remove dom element
                                        me.removeRaterDom(elTarget, elEditRow, function(){
                                            // Then add to new group
                                            me.insertRaterDom(elTarget, responseJson.ratergroup_id, responseJson.html);
                                        });
                                    }

                                }else{
                                    // Add to group
                                    me.insertRaterDom(elTarget, responseJson.ratergroup_id, responseJson.html);
                                }
                            }, 400);

                        }, 800);
                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }

                    }

                })
                .catch(function (error) {
                    // Stop Thinking...
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elFormContainer);

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // show error in alert
                    if(elAlert){
                        formAlert.show(elAlert, errorMessage, null, 'danger');
                    }

                });



        }else{
            console.log('Missing save form URL');
        }

    },


    // add delete event to button
    initDelRater: function(elTarget){

        var me = setManageRaters;

        // Initialise pop up button
        var delBtnClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-rater-remove-btn')) return;

            // Button clicked
            e.preventDefault();

            var elRow = e.target.closest('.js-rater-item');

            var raterName = elRow.querySelector('.js-rater-name').innerText;
            var raterEmail = elRow.querySelector('.js-rater-email').innerText;

            // Get id of rater from button and pass in
            var removeFunc = function(){
                var raterId = elRow.getAttribute('data-rater-id');
                if(raterId){
                    me.removeRater(elTarget, elRow, raterId);
                }
            }

            // bootbox
            openMpDialog(null, 'Are you sure you want to remove this Rater? <br><br>' + raterName + ' - ' + raterEmail, removeFunc, null);

        }

        // Attach open event
        on('click', elTarget, delBtnClick);

    },

    // AJAX remove / delete a rater
    removeRater: function(elTarget, elRaterRow, raterId){

        var me = setManageRaters;

        var delUrl = elTarget.getAttribute('data-del-url');
        //
        if(delUrl){

            // remove trailing slash
            delUrl = delUrl.replace(/\/$/, '');
            // Add Rater ID
            delUrl = delUrl + '/' + raterId;

            // AXIOS config
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Add csrf token if exists
            var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
            var csrf;

            if(elCsrf){
                config.headers['X-CSRF-TOKEN'] = elCsrf.content;
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(delUrl, null, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // Returned Success
                    if(responseJson.status=='success'){
                        me.removeRaterDom(elTarget, elRaterRow);
                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // show notification
                        Notify({
                            text: errorMessage,
                            status: 'danger',
                            offset: 100
                        }).showNotify();

                    }

                })
                .catch(function (error) {

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // show notification
                    Notify({
                        text: errorMessage,
                        status: 'danger',
                        offset: 100
                    }).showNotify();

                });
        }
    },


    // remove rater from DOM
    removeRaterDom:function(elTarget, elRow, callback){
        var me = setManageRaters;

        var elTable = elRow.closest('table'),
            elTds = elRow.querySelectorAll('td'),
            h = elRow.offsetHeight -2;

        // Fade out row
        elRow.classList.add('animating');
        elRow.offsetHeight; // force reflow
        elRow.style.opacity = 0;

        // Pause to allow fade out
        setTimeout(function(){

            elRow.classList.remove('animating');

            // Wrap content of each td in a div which we can animate
            forEachNode(elTds, function (i, el) {
                el.innerHTML = '<div class="js-rater-anim-cell rater-anim-cell" style="height:' + h + 'px;"></div>';
                el.style.paddingTop = 0;
                el.style.paddingBottom = 0;
            });

            // add animation classes
            var elDivs = elRow.querySelectorAll('.js-rater-anim-cell');
            forEachNode(elDivs, function (i, el) {
                el.classList.add('animating');
            });

            elRow.offsetHeight; // force reflow

            // set height
            forEachNode(elDivs, function (i, el) {
                el.style.height = 0;
            });

            // once finished animating
            setTimeout(function(){
                // remove from dom
                elRow.parentNode.removeChild(elRow);
                // If table is empty need to show message
                var elRows = elTable.querySelectorAll('.js-rater-item');

                if(elRows.length === 0){
                    var elMessage = elTable.querySelector('.js-rater-no-results-message');
                    if(elMessage){
                        me.showTableRow(elTarget,elMessage);
                    }
                }

                // Run callback
                if (typeof callback === 'function'){
                    callback();
                }

            }, 300);


        }, 400);

    },

    // Insert rater into DOM
    insertRaterDom:function(elTarget, groudId, raterMarkup, callback){
        var me = setManageRaters;

        // find group
        var elTbody = elTarget.querySelector('[data-group-id="' + groudId + '"] tbody'),
            elTable = elTbody.closest('table');

        // Remove 'empty message'
        var elMessage = elTable.querySelector('.js-rater-no-results-message');
        if(elMessage){
            elMessage.classList.add('hidden');
        }

        // Create a blank element to store result as dom
        var elNewRow = document.createElement('tbody');
        elNewRow.innerHTML = raterMarkup;
        var elNewRowTr = elNewRow.querySelector('tr');
        // maybe add this in markup instead?
        elNewRowTr.classList.add('hidden');
        elNewRowTr.classList.add('highlight');
        // fade out highlight
        me.highlightTimer(elTarget, elNewRowTr);

        // Add to DOM
        elTbody.appendChild(elNewRowTr);

        // sort the table
        //sortTable(elTable, 1, true, '.js-sort');

        // animate in
        me.showTableRow(elTarget, elNewRowTr);

        // Run callback
        if (typeof callback === 'function'){
            callback();
        }

    },

    // Replace the markup of a row with markup from a string
    replaceRaterDom: function(elTarget, elEditRow, htmlString){
        var me = setManageRaters;
        //
        // Create a blank element to store result as dom
        var elNewRow = document.createElement('tbody');
    	elNewRow.innerHTML = htmlString;
        var elNewRowTr = elNewRow.querySelector('tr');
        // maybe add this in markup instead?
        elNewRowTr.classList.add('animating');
        // replace DOM element
    	elEditRow.parentNode.replaceChild(elNewRowTr, elEditRow);
        // show as changed
        elNewRowTr.classList.add('highlight');
        me.highlightTimer(elTarget, elNewRowTr);
    },

    // animate in a table row
    showTableRow:function(elTarget, elRow){
        var me = setManageRaters;

        var elTds = elRow.querySelectorAll('td');

        // get height
        elRow.classList.remove('hidden');
        var h = elRow.offsetHeight-2;
        elRow.classList.add('hidden');

        // Wrap content of each td in a div which we can animate
        forEachNode(elTds, function (i, el) {
            el.innerHTML = '<div class="js-rater-anim-cell rater-anim-cell" style="height:0;">' + el.innerHTML + '</div>';
            el.style.paddingTop = 0;
            el.style.paddingBottom = 0;
        });

        // show row
        elRow.style.opacity = 0;
        elRow.classList.remove('hidden');

        // add animation class
        var elDivs = elRow.querySelectorAll('.js-rater-anim-cell');
        forEachNode(elDivs, function (i, el) {
            el.classList.add('animating');
        });

        elRow.offsetHeight; // force reflow

        // set height
        forEachNode(elDivs, function (i, el) {
            el.style.height = h+'px';
        });

        // Pause to allow slide in
        setTimeout(function(){
            //

            // cleanup - remove divs put padding back
            forEachNode(elTds, function (i, el) {
                //el.innerHTML = '<div class="js-rater-anim-cell rater-anim-cell" style="height:0;">' + el.innerHTML + '</div>';
                el.style.paddingTop = '';
                el.style.paddingBottom = '';
                //
                var elDiv = el.querySelector('.js-rater-anim-cell');
                el.innerHTML = elDiv.innerHTML;
            });

            // fade in
            elRow.classList.add('animating');
            elRow.offsetHeight; // force reflow
            elRow.style.opacity = 1;

            setTimeout(function(){
                elRow.classList.remove('animating');
            }, 400);

        }, 400);

    },


    // Invite a single rater button event
    initInviteSingleRater: function(elTarget){
        var me = setManageRaters;

        // Initialise pop up button
        var inviteBtnClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-rater-invite-single-btn')) return;

            // Button clicked
            e.preventDefault();

            // Blur button
            e.target.blur();

            // Thinking...
            pendingButton.show(e.target);

            // Get row
            var elRow = e.target.closest('.js-rater-item');

            var inviteFunc = function(){
                // disable row
                elRow.classList.add('disabled');
                elRow.offsetHeight; // force reflow
                elRow.classList.add('animating');

                // get id of rater
                var raterArray = [elRow.getAttribute('data-rater-id')];
                if(raterArray.length){
                    me.inviterRaters(elTarget, raterArray, e.target);
                }
            }

            var cancelFunc = function(){
                // stop Thinking...
                pendingButton.hide(e.target);
            }

            // bootbox
            openMpDialog(null, 'Press OK to send a questionnaire invite email to this rater.', inviteFunc, cancelFunc);
        }

        // Attach open event
        on('click', elTarget, inviteBtnClick);

    },

    // Invite all raters button event
    initInviteAllRaters: function(elTarget){
        var me = setManageRaters;

        // Initialise pop up button
        var inviteAllBtnClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-rater-invite-all-btn')) return;

            // Button clicked
            e.preventDefault();

            // Blur button
            e.target.blur();

            // Build array of ids to invite
            var raterArray = [];

            var elRaterRows = elTarget.querySelectorAll('.js-rater-item');
            forEachNode(elRaterRows, function (i, el) {
                raterArray.push(el.getAttribute('data-rater-id'));
            });

            if(raterArray.length > 2){
                // We have enough raters to invite
                // Thinking...
                pendingButton.show(e.target);
                //
                me.openInviteRatersModal(elTarget, raterArray, e.target)

            }else{
                // User hasn't added enough raters
                openMpDialog(null, 'Please add at least 3 raters.', null, null, true);
            }

        }

        // Attach open event
        on('click', elTarget, inviteAllBtnClick);

    },

    // Open an invite raters modal and load preview content
    openInviteRatersModal: function(elTarget, raterArray, elBtn){
        var me = setManageRaters;

        var elModal = elTarget.querySelector('.js-invite-rater-modal');

        // Add send click event
        elSaveButton = elModal.querySelector('.js-invite-rater-modal-save');

        var saveClick = function(e){
            e.preventDefault();
            // Thinking...
            pendingButton.show(elSaveButton);

            // invite all raters
            var successCallBack = function(){
                // Stop Thinking
                pendingButton.hide(elSaveButton);
                //
                setTimeout(function(){
                    //close modal
                    $.magnificPopup.close();
                    setTimeout(function(){
                        // We need to refresh the page as the user will now have different options available
                        // This may be better if it redirects to a thank-you page
                        window.location.href = window.location.href;
                    },800);
                },800);
            };
            me.inviterRaters(elTarget, raterArray, e.target, successCallBack);
        }

        on('click', elSaveButton, saveClick);


        // open the 'edit' modal
        $.magnificPopup.open({
            items: {
                src: '.js-invite-rater-modal',
                type: 'inline'
            },

            alignTop: true, // Stick to top of page
            preloader: false,
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in',
            modal: false,

            callbacks: {
                beforeOpen:function(){
                    var elRaterTotal = elModal.querySelector('.js-rater-total');
                    if(elRaterTotal && raterArray.length>0){
                        elRaterTotal.innerHTML = raterArray.length;
                    }
                },
                open:function(){
                    var elResultsContainer = elModal.querySelector('.js-invite-rater-modal-results'),
                        elResultsContainerInner = elModal.querySelector('.js-invite-rater-modal-results-inner'),
                        elSpinner = elModal.querySelector('.js-spinner');

                    // Reset HTML
                    elResultsContainerInner.innerHTML = '';

                    // Thinking...
                    elSpinner.classList.add('css-spinner-show');
                    elSaveButton.disabled = true;
                    elSaveButton.classList.add('disabled');

                    // find alert block and reset it
                    var elAlert = elModal.querySelector('.js-alert');
                    if(elAlert){
                        formAlert.reset(elAlert);
                    }

                    // Get url
                    var formUrl = elTarget.getAttribute('data-invite-preview-url');

                    //
                    if(formUrl){
                        // Load the form markup
                        var params;
                        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                        var config = {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                            }
                        }

                        // Add csrf token if exists
                        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
                        var csrf;

                        if(elCsrf){
                            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
                        }

                        // Default error message
                        var errorMessage = 'Sorry, there was a problem with your request.';

                        // AJAX request
                        axios.post(formUrl, params, config)
                            .then(function (response) {

                                var responseJson = response.data;

                                // Stop Thinking...
                                elSpinner.classList.remove('css-spinner-show');

                                if(responseJson.status=='success'){
                                    // Set HTML
                                    elResultsContainerInner.innerHTML = responseJson.html;

                                    // Get height
                                    var h = elResultsContainerInner.offsetHeight;

                                    // Hide results
                                    elResultsContainerInner.style.display = 'none';
                                    elResultsContainerInner.style.maxHeight = '20px';
                                    elResultsContainerInner.classList.add('animating');

                                    // show results
                                    elResultsContainerInner.style.display = 'block';
                                    elResultsContainerInner.offsetHeight; // force reflow
                                    elResultsContainerInner.style.maxHeight = h + 'px';

                                    // Reset for next time
                                    setTimeout(function(){
                                        elResultsContainerInner.classList.remove('animating');
                                        elResultsContainerInner.style.maxHeight = '';
                                    },400);

                                    // Enable save
                                    elSaveButton.disabled = false;
                                    elSaveButton.classList.remove('disabled');

                                }

                                // Returned Error
                                if(responseJson.status=='error'){

                                    // See if a message was returned
                                    if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                        errorMessage = responseJson.message.text;
                                    }

                                    // show error in alert
                                    if(elAlert){
                                        formAlert.show(elAlert, errorMessage, null, 'danger');
                                    }

                                }

                            })
                            .catch(function (error) {
                                // Stop Thinking...
                                elSpinner.classList.remove('css-spinner-show');

                                if(error.response){
                                    errorMessage  += '<br>' + error.response;
                                }

                                // show error in alert
                                if(elAlert){
                                    formAlert.show(elAlert, errorMessage, null, 'danger');
                                }

                            });
                    }
                },
                close:function(){
                    // Modal open button - stop thinking
                    pendingButton.hide(elBtn);
                    // Remove save click event
                    off('click', elSaveButton, saveClick);

                }
            }
        });

    },

    // Ajax invite raters
    inviterRaters: function(elTarget, raterArray, elInviteBtn, callback) {

        var me = setManageRaters;

        // Get url
        var inviteUrl = elTarget.getAttribute('data-invite-url');

        if(inviteUrl){

            // Load the form markup
            var params = {};
            params.rater_ids = raterArray;

            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Add csrf token if exists
            var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
            var csrf;

            if(elCsrf){
                config.headers['X-CSRF-TOKEN'] = elCsrf.content;
            }

            // test data
            // var elRaterStatus = elTarget.querySelector('.js-rater-item[data-rater-id="12"] .js-rater-invite-status');

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(inviteUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // stop Thinking...
                    pendingButton.hide(elInviteBtn);

                    // Returned Success
                    if(responseJson.status=='success'){

                        // change status after pause
                        setTimeout(function(){
                            // update table rows
                            var invitedRaters = responseJson.invited_raters;
                            //
                            if(invitedRaters){
                                for(var i=0; i<invitedRaters.length; i++){
                                    // change status
                                    var elRow = elTarget.querySelector('.js-rater-item[data-rater-id="' + invitedRaters[i].id + '"]');

                                    // enable row
                                    elRow.classList.remove('disabled');
                                    //elRow.classList.remove('animating');

                                    if(invitedRaters[i].html && invitedRaters[i].html!= ''){
                                        me.replaceRaterDom(elTarget, elRow, invitedRaters[i].html)
                                    }

                                    /*elRaterStatus.innerHTML = invitedRaters[i].invited_markup;
                                    // show as changed
                                    elRow.classList.add('highlight');
                                    me.highlightTimer(elTarget, elRow);*/

                                }
                            }
                        }, 800);

                        // Run callback
                        if (typeof callback === 'function'){
                            callback();
                        }

                        //console.log('The following raters have been invited: ' + response.data.invited_rater_ids);
                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // show error notification
                        Notify({
                            text: errorMessage,
                            status: 'danger',
                            offset: 100
                        }).showNotify();

                    }
                })

                .catch(function (error) {
                    alert('Sorry, there was an error. Please try again.');
                });
        }

    },


    // Submit the group message modal form
    initGroupMessage: function(elTarget){
        var me = setManageRaters;

        // Attach submit event
        var elForms = elTarget.querySelectorAll('.js-rater-group-message-form');
        forEachNode(elForms, function (i, el) {
            on('submit', el, function(e){
                e.preventDefault();

                // Get save URL
                var saveFormUrl = el.getAttribute('data-update-url') ? el.getAttribute('data-update-url') : el.getAttribute('action');

                // Get field
                var elInput = el.querySelector('.js-rater-group-message-input');

                // Disable field
                elInput.disabled = true;

                // Disable button
                var elSubmit = el.querySelector('button[type="submit"]');
                pendingButton.show(elSubmit);

                // find alert block and reset it
                var elAlert = el.querySelector('.js-alert');
                if(elAlert){
                    formAlert.reset(elAlert);
                }

                // Get form data
                var thisName = elInput.getAttribute('name'),
                    thisVal = elInput.value;

                // Build Params
                var params = {};
                params[thisName] = thisVal;

                // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                var config = {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }

                // Add csrf token if exists
                var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
                var csrf;

                if(elCsrf){
                    config.headers['X-CSRF-TOKEN'] = elCsrf.content;
                }

                // Default error message
                var errorMessage = 'Sorry, there was a problem with your request.';

                // AJAX request
                axios.post(saveFormUrl, params, config)
                    .then(function (response) {

                        var responseJson = response.data;

                        // enable form
                        elInput.disabled = false;
                        pendingButton.hide(elSubmit);

                        //
                        if(responseJson.status=='success'){

                            // Close modal
                            setTimeout(function(){
                                $.magnificPopup.close();
                            }, 800);

                        }

                        // Returned Error
                        if(responseJson.status=='error'){

                            // See if a message was returned
                            if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                errorMessage = responseJson.message.text;
                            }

                            // show error in alert
                            if(elAlert){
                                formAlert.show(elAlert, errorMessage, null, 'danger');
                            }


                        }
                    })
                    .catch(function (error) {
                        // enable form
                        elInput.disabled = false;
                        pendingButton.hide(elSubmit);
                        //
                        if(error.response){
                            errorMessage  += '<br>' + error.response;
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }
                    });
            });

        });
    },

    // Check every option in a select for full rater groups and disable
    checkGroupSelect:function(elTarget, elGroupSelect){
        var me = setManageRaters;
        //
        var opt,i;
        // Go through each group and check which are full
        for (i = 0; opt = elGroupSelect.options[i]; i++) {
            if(opt.value && opt.value!=''){
                // Get group div based on option ID
                var elTempGroup = elTarget.querySelector('.js-rater-group[data-group-id="' + opt.value + '"]');
                // Pass group div into checkMaxGroup function
                // and use to determine disabled state
                opt.disabled = me.checkMaxGroup(elTempGroup);
            }
        }
    },

    // Check if we have reached the maximum numbers of raters
    checkMax: function (elTarget) {
        var totalMax = elTarget.getAttribute('data-total-max-raters'),
            elRaters = elTarget.querySelectorAll('.js-rater-item'); // NodeList

        return elRaters.length >= totalMax;
    },

    // Check if we have reached the maximum numbers of raters for a given group
    checkMaxGroup: function(elGroup){
        var groupTotal = elGroup.getAttribute('data-group-max-raters');
        //
        if(groupTotal && groupTotal>0){
            var elRaters = elGroup.querySelectorAll('.js-rater-item'); // NodeList

            return elRaters.length >= groupTotal;
        }else{
            return false;
        }
    },


    // remove highlight after a while
    highlightTimer: function(elTarget, elRow){
        setTimeout(function(){
            elRow.classList.remove('highlight');
        }, 5000);
    }



}

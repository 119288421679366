/*!

 File: set-ajax-button.js
 Author: Ember
 Version: 1.0.10 [MMP-5] - check for csrf token
 JS Dependencies:
    'helper-on' - helper,

 Description:
    Make an ajax request on button click, then display a toastr
    This is a Vanilla JS version of the ADMIN set-ajax-button.js

*/

var setAjaxButton = {
    init: function (selector) {

        var me = setAjaxButton;

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            // Get URL
            var updateUrl;
            // If data attr exists and isn't empty
            if (checkDataAttr(el, 'data-update-url')) {
                updateUrl = el.getAttribute('data-update-url');
            }else{
                updateUrl = el.getAttribute('href');
            }

            if(updateUrl){
                // Disable button
                pendingButton.show(el);
                //
                if (checkDataAttr(el, 'data-confirm-message')) {
                    var message = el.getAttribute('data-confirm-message');
                    var title;
                    if(el.getAttribute('data-confirm-title') && el.getAttribute('data-confirm-title') != ''){
                        title = el.getAttribute('data-confirm-title');
                    }
                    openMpDialog(title, message, function(){
                        // Make request after close
                        setTimeout(function(){
                            me.sendRequest(el, updateUrl);
                        },400);
                    },function(){
                        pendingButton.hide(el);
                    });
                }else{
                    // Make request
                    me.sendRequest(el, updateUrl);
                }

            }else{
                console.log('setAjaxButton missing data-update-url');
            }

        }

        // Attach open event
        on('click', document, clickEvt);

    },
    sendRequest: function (el, updateUrl) {

        // Param object
        var params = {};

        // Element ID
        // If data attr exists and isn't empty
        if (checkDataAttr(el, 'data-id')) {
            params['id'] = el.getAttribute('data-id');
        }

        // Add any extra data to Params
        var extraData = el.getAttribute('data-extra');
        if(extraData && extraData != ''){
            extraData = JSON.parse(extraData);
            for (var i in extraData) {
                params[i] = extraData[i];
            }
        }

        // Call back function name can be passed in as a data attribute.
        var callback;
        // If data attr exists and isn't empty
        if (checkDataAttr(el, 'data-callback')) {
            callback = el.getAttribute('data-callback');
        }


        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
        var config = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        }

        // Add csrf token if exists
        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
        var csrf;

        if(elCsrf){
            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
        }

        // Default error message
        var errorMessage = 'Sorry, there was a problem with your request.';


        // pause for effect
        setTimeout(function(){

            // AJAX request
            axios.post(updateUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    if(responseJson.status=='success'){

                        // If I am in a datatable then replace content (still JQUERY)
                        $target = $(el);
                        var $dtParent = $target.closest('.js-datatable');
                        if($dtParent.length>0){
                            var $dtRow = $target.closest('tr');
                            setDataTable.updateRow($dtParent, $dtRow, response.data);
                        }

                        // Show notification
                        var responseMessage;
                        //
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            Notify({
                                text: responseJson.message.text,
                                status: 'success',
                                offset: 100
                            }).showNotify();
                        }

                        // Process any domUpdate content
                        if(responseJson.updates && responseJson.updates.length){
                            responseJson.updates.forEach(function (item) {
                                var elUpdate = document.querySelector('#' + item.id);
                                if(elUpdate){
                                    if(item.value && item.value !=''){
                                        elUpdate.value = item.value;
                                    }
                                    if(item.html && item.html !=''){
                                        elUpdate.innerHTML = item.html;
                                    }
                                    // initialise any custom fields
                                    if(typeof initFields === 'function'){
                                        initFields(elUpdate);
                                    }
                                }
                            });
                        }

                        // Reset button
                        pendingButton.hide(el);

                        // Callback function
                        if (typeof window[callback] === 'function') {
                            window[callback](el, response.data);
                        }

                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // Reset button
                        pendingButton.hide(el);

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // Show error notification
                        Notify({
                            text: errorMessage,
                            status: 'error',
                            offset: 100
                        }).showNotify();
                    }
                })
                .catch(function (error) {

                    console.log(error);

                    // Reset button
                    pendingButton.hide(el);

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // Show error notification
                    Notify({
                        text: errorMessage,
                        status: 'error',
                        offset: 100
                    }).showNotify();

                });

        }, 400);

    }
}

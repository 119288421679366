/*!

 File: set-habit-tracker.js
 Author: Ember
 Version: 1.0.4 - [MMP-5] - check for csrf token
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Habit Tracker UI
*/

var setHabitTracker = {
    init: function (elTarget) {
        var me = setHabitTracker;

        me.initEditHabit(elTarget);

        me.initListGridNav(elTarget);

    },

    initEditHabit: function (elTarget) {
        var me = setHabitTracker;

        var elModal = elTarget.querySelector('.js-edit-pht-modal');

        // Initialise pop up button
        var editBtnClick = function(e){
            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-pht-item')) return;

            // Button clicked
            e.preventDefault();

            // open Add Rater modal
            me.openEditHabitModal(elTarget, elModal, e.target.closest('.js-pht-item'));

        }

        // Attach open event
        on('click', elTarget, editBtnClick);

        //
        me.initSaveEditForm(elTarget, elModal);

    },


    // Open the add/edit modal
    openEditHabitModal: function(elTarget, elModal, elBtn){
        var me = setHabitTracker;

        // Set Title
        var elModalTitle = elModal.querySelector('.js-modal-title');
        var modalTitle = elBtn.getAttribute('data-modal-title');
        modalTitle = modalTitle ? modalTitle : 'Edit habit';

        elModalTitle.textContent = modalTitle;

        // open the 'edit' modal
        $.magnificPopup.open({
            items: {
                src: '.js-edit-pht-modal',
                type: 'inline'
            },

            alignTop: true, // Stick to top of page
            preloader: false,
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in',
            modal: false,

            // When element is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function() {
                    if($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = 'input';
                    }
                },
                open:function(){
                    me.loadAjaxForm(elTarget, elModal, elBtn);
                }
            }
        });
    },

    // Load an Ajax form into the edit modal
    loadAjaxForm: function(elTarget, elModal, elBtn){
        var me = setHabitTracker;

        var elResultsContainer = elModal.querySelector('.js-edit-pht-modal-results'),
            elResultsContainerInner = elModal.querySelector('.js-edit-pht-modal-results-inner'),
            elFormContainer = elModal.querySelector('.form-disable'),
            elSpinner = elModal.querySelector('.js-spinner'),
            elSaveButton = elModal.querySelector('.js-edit-pht-modal-save');

        // Reset HTML
        elResultsContainerInner.innerHTML = '';

        // Thinking...
        elSpinner.classList.add('css-spinner-show');
        elSaveButton.disabled = true;
        elSaveButton.classList.add('disabled');

        // find alert block and reset it
        var elAlert = elModal.querySelector('.js-alert');
        if(elAlert){
            formAlert.reset(elAlert);
        }

        // Get url
        var formUrl = elTarget.getAttribute('data-edit-form-url');

        //
        if(formUrl){

            // Get id of button
            var habitId = elBtn.getAttribute('data-habit-id');
            // remove trailing slash
            formUrl = formUrl.replace(/\/$/, '');
            // Add day ID
            formUrl = formUrl + '/' + habitId;

            // Load the form markup
            var params;
            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Add csrf token if exists
            var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
            var csrf;

            if(elCsrf){
                config.headers['X-CSRF-TOKEN'] = elCsrf.content;
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(formUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // Stop Thinking...
                    elSpinner.classList.remove('css-spinner-show');

                    // Returned Success
                    if(responseJson.status=='success'){
                        // Set HTML
                        elResultsContainerInner.innerHTML = responseJson.html;

                        // Get height
                        var h = elResultsContainerInner.offsetHeight;

                        // Hide results
                        elResultsContainerInner.style.display = 'none';
                        elResultsContainerInner.style.maxHeight = '20px';
                        elResultsContainerInner.classList.add('animating');

                        // Init fields
                        initFields($(elResultsContainerInner));
                        // @// TODO: Improve this
                        var elToggleBtns = elResultsContainerInner.querySelector('.js-btn-toggle');
                        if(elToggleBtns){
                            setBtnToggle.init();
                        }

                        // show results
                        elResultsContainerInner.style.display = 'block';
                        elResultsContainerInner.offsetHeight; // force reflow
                        elResultsContainerInner.style.maxHeight = h + 'px';

                        // Reset for next time
                        setTimeout(function(){
                            elResultsContainerInner.classList.remove('animating');
                            elResultsContainerInner.style.maxHeight = '';
                        },400);

                        // Enable save button
                        elSaveButton.disabled = false;
                        elSaveButton.classList.remove('disabled');

                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }
                    }

                })
                .catch(function (error) {

                    // Stop Thinking...
                    elSpinner.classList.remove('css-spinner-show');

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // show error in alert
                    if(elAlert){
                        formAlert.show(elAlert, errorMessage, null, 'danger');
                    }

                });

        }

    },

    // Save event for the AJAX loaded form
    initSaveEditForm: function(elTarget,elModal){
        var me = setHabitTracker;

        var elSaveButton = elModal.querySelector('.js-edit-pht-modal-save');

        // Form save events
        var elEditForm = elModal.querySelector('form'),
            // Our validation library is still jquery reliant
            $editForm = $('.js-edit-pht-modal form').first();

        if(!elEditForm){
            console.log('WARNING: form element is missing!');
        }

        $editForm.validate({
            submitHandler: function (form) {
                me.editHabitSubmit(elTarget, elModal, elEditForm);
            }
        });

        on('click', elSaveButton, function(e){
            e.preventDefault();
            // trigger submit
            $editForm.submit();
        });

    },

    // Submitting the edit habit form and dealing with response
    editHabitSubmit: function(elTarget, elModal, elEditForm){
        var me = setHabitTracker;

        // Serialise all fields (true allows disabled fields)
        var params = serializeArray(elEditForm, true);

        // Get save elements
        var elSaveButton = elModal.querySelector('.js-edit-pht-modal-save'),
            elFormContainer = elModal.querySelector('.form-disable');

        // Get url
        var saveFormUrl = elTarget.getAttribute('data-update-url');

        if(saveFormUrl){
            //
            // Thinking...
            pendingButton.show(elSaveButton);
            pendingForm.disable(elFormContainer);

            // find alert block and reset it
            var elAlert = elModal.querySelector('.js-alert');
            if(elAlert){
                formAlert.reset(elAlert);
            }

            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Add csrf token if exists
            var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
            var csrf;

            if(elCsrf){
                config.headers['X-CSRF-TOKEN'] = elCsrf.content;
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(saveFormUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // Stop Thinking...
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elFormContainer);

                    // Returned Success
                    if(responseJson.status=='success'){
                        // pause
                        setTimeout(function(){
                            // Close modal
                            $.magnificPopup.close();

                            // Update Grid
                            setTimeout(function(){

                                // find entry
                                var elTrackerItem = document.querySelector('.js-pht-item[data-habit-id="' + responseJson.entity.position + '"]');
                                var itemStatus = responseJson.entity.participanthabittrackerresponsestatus_alias;

                                if(elTrackerItem){
                                    elTrackerItem.classList.remove('pht-item-completed', 'pht-item-not-completed');
                                    elTrackerItem.classList.add('pht-item-' + itemStatus);
                                    var elEmotion = elTrackerItem.querySelector('.js-pht-block-emotion');
                                    if(elEmotion){
                                        var emotionText = '';

                                        if(responseJson.primary_emotion_title && responseJson.primary_emotion_title != ''){
                                            emotionText += responseJson.primary_emotion_title;
                                        }
                                        if(responseJson.secondary_emotion_title && responseJson.secondary_emotion_title != ''){
                                            emotionText += ' and ' + responseJson.secondary_emotion_title;
                                        }
                                        elEmotion.innerText = emotionText;
                                    }
                                }

                                // Do we need to add before / after line?
                                var elTrackerItemNext = elTrackerItem.nextElementSibling;
                                if(elTrackerItemNext){
                                    if(itemStatus == 'completed'){
                                        if(elTrackerItemNext.classList.contains('pht-item-completed')){
                                            elTrackerItem.classList.add('pht-item-completed-after');
                                            elTrackerItemNext.classList.add('pht-item-completed-before');
                                        }
                                    }else{
                                        elTrackerItem.classList.remove('pht-item-completed-after');
                                        elTrackerItemNext.classList.remove('pht-item-completed-before');
                                    }
                                }

                            }, 400);

                        }, 800);
                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }

                    }

                })
                .catch(function (error) {
                    // Stop Thinking...
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elFormContainer);

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // show error in alert
                    if(elAlert){
                        formAlert.show(elAlert, errorMessage, null, 'danger');
                    }
                });

            }else{
                console.log('Missing save form URL');
            }

    },

    // Switch between grid and list view
    initListGridNav: function(elTarget){
        var me = setHabitTracker;

        var elListGridNav = document.querySelector('.js-pht-grid-list-nav');

        var viewSwitch = function(elBtn) {
            // switch nav
            var elActive = elListGridNav.querySelector('.active')
            if(elActive){
                elActive.classList.remove('active');
            }
            elBtn.classList.add('active');
            // Fade out list
            var elList = elTarget.querySelector('.js-pht-list');
            if(elList){
                // Hide content
                elList.classList.add('show');
                elList.offsetHeight; // force reflow
                elList.classList.add('animating');
                //
                setTimeout(function(){
                    if(elBtn.getAttribute('data-type')=='grid'){
                        elList.classList.add('pht-grid');
                    }else{
                        elList.classList.remove('pht-grid');
                    };
                    //
                    elList.offsetHeight; // force reflow
                    // Show content
                    elList.classList.remove('animating');
                    setTimeout(function(){
                        elList.classList.remove('show');
                    },400);
                    //
                },400);
            }

        }

        // Initialise click
        var listGridClick = function(e){
            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-pht-grid-list-nav-item')) return;

            // Button clicked
            e.preventDefault();

            // Get parent link
            var elLink = e.target.closest('.js-pht-grid-list-nav-item');

            // Switch view
            if(!elLink.classList.contains('active')){
                viewSwitch(elLink);
            }

        }

        // Attach open event
        if(elListGridNav){
            on('click', elListGridNav, listGridClick);
        }

    }

}

/*!

 File: set-dropdown.js
 Author: Ember
 Version: 1.0.3 [MINA-764] - close main menu
 JS Dependencies:
    set-overlay,
    helper-on,
    'closest' polyfill

 CSS Dependencies:
    _site-dropdown.scss

 Description:
     Click event on dropdown buttons
*/

var setDropdown = {
    init: function () {

        var me = setDropdown;

        // Button click
        var dropClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-dropdown-toggle-btn')) return;

            // Button clicked
            e.preventDefault();
            var elTarget = e.target.closest('.js-dropdown-toggle'),
                elMenu = elTarget.querySelector('.js-dropdown-toggle-menu');

            // Open or close
            if(elTarget.classList.contains('dropdown-open')){
                // close
                me.hideAllDrop();
                // remove close eventHandler
                off('click', document, dropOutClick);
            }else{
                // open
                // close any open mobile menus
                if(setMobileNav){
                    setMobileNav.closeNav();
                }
                //
                me.showDrop(elTarget);
                // set close eventHandler
                on('click', document, dropOutClick);
            }

        };

        // Click outside
        var dropOutClick = function(e){
            if(!e.target.closest('.js-dropdown-toggle')){
                // remove close eventHandler
                off('click', document, dropOutClick);
                //
                e.preventDefault();
                me.hideAllDrop();
            }
        }

        // attach event
        on('click', document, dropClick);

    },

    showDrop: function(elTarget){
        var me = setDropdown;
        // Hide anything open
        me.hideAllDrop();
        // show as open
        elTarget.classList.add('dropdown-open');
        // target dropdown
        var elBtn = elTarget.querySelector('.js-dropdown-toggle-btn'),
            btnId = elBtn.href.split('#')[1];
            elDropBlock = elTarget.querySelector('#' + btnId);
        // show as visible
        elBtn.setAttribute('aria-expanded', 'true');
        elDropBlock.hidden = false;
        elDropBlock.setAttribute('aria-hidden', 'false');
        // Show overlay
        setOverlay.show();
    },

    hideAllDrop: function(){
        // Hide any open dropdowns
        var elDropdown = document.querySelectorAll('.dropdown-open');
        [].forEach.call(elDropdown, function(el, i){
            el.classList.remove('dropdown-open');
            // target dropdown block
            var elBtn = el.querySelector('.js-dropdown-toggle-btn'),
                btnId = elBtn.href.split('#')[1];
                elDropBlock = el.querySelector('#' + btnId);
            // show as hidden
            elBtn.setAttribute('aria-expanded', 'false');
            elDropBlock.hidden = true;
            elDropBlock.setAttribute('aria-hidden', 'true');
        });
        // Hide overlay
        setOverlay.hide();
    },

}

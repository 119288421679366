/*!

 File: set-card-stack.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Card Sorting UI used by feelings gauge and card sorter
*/
;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.CardStack = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var CardStack = (function () {

        var Constructor = function () {

    		//
    		// Variables
    		//

    		var publicAPIs = {};

            var settings = {};
            settings.dataStore = {};
            settings.undoFunc = null;

            var cardBackup = [];


    		//
    		// Methods
    		//

    		/**
    		 * A private method
    		 */
    		var showCardStack = function (el) {

                el.classList.remove('hidden');
                //
                setTimeout(function(){
                    // Animate in cards
                    el.classList.remove('emotion-card-stack-hidden');
                    //
                    // Activate Gauge
                    setTimeout(function(){
                        // Init Callback function
                        if (typeof settings.cardsInitCallback == 'function') {
                            settings.cardsInitCallback.call();
                        }
                    },600);

                    //
                },200);

            };

            var updateData = function (el) {
                
                // Get Data field
                var elDataInput = el.querySelector('.js-card-sorter-data');
                console.log('updateData');
                if(elDataInput){
                    elDataInput.value = JSON.stringify(settings.dataStore);
                }else{
                    console.log('Hidden field with class .js-card-sorter-data required');
                }
            }
            
            var undoCard = function (e) {
                
                //
                var el = e.target.closest('.js-card-sort-undo');
                var elParent = settings.el;

                // Closest captures self as well a parents
                if (!el) return;

                e.preventDefault();

                if(cardBackup.length > 0){

                    if(!elParent.classList.contains('is-animating')){

                        elParent.classList.add('is-animating');

                        // Create card element
                        var elDiv = document.createElement('div');
                        var lastCard = cardBackup.slice(-1)[0];
                        elDiv.className = lastCard.classes;
                        elDiv.classList.add('animating');
                        elDiv.setAttribute('data-id', lastCard.id);
                        elDiv.innerHTML = lastCard.html;

                        // Prepend to dom
                        var elFirstCard = elParent.querySelector('.js-emotion-card');
                        if(elFirstCard){
                            elParent.insertBefore( elDiv, elFirstCard );
                        }

                        // remove from array
                        cardBackup.pop();

                        if(cardBackup.length < 1){
                            el.classList.remove('active');
                        }

                        // REMOVE LAST ELEMENT FROM DATA
                        delete settings.dataStore[lastCard.id];

                        // Set Data field
                        updateData(elParent);
                        
                        // Feelings guage passes in a CB function
                        if (typeof settings.undoFunc == 'function') { 
                            settings.undoFunc(); 
                        }

                        setTimeout(function(){ 
                            elDiv.classList.remove('animating');
                            ///
                            setTimeout(function(){ 
                                // return classes to default
                                elDiv.className = 'emotion-card shadow js-emotion-card';
                                // allow next click
                                elParent.classList.remove('is-animating'); 
                            }, 200);
 
                        }, 400);

                    }

                }

            };


    		/**
            * Public methods
            */
    		publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;
                if(settings.el){

                    settings.cardsInitCallback = options.cardsInitCallback || null;
                    settings.lastCardCallback = options.lastCardCallback || null;
                    settings.correctCallback = options.correctCallback || null;
                    settings.incorrectCallback = options.incorrectCallback || null;

                    showCardStack(settings.el);

                    // Attach open event
                    on('click', settings.el, undoCard);

                }else{
                    console.log('set-card-stack.js is missing a dom element');
                }



                // Returning the current object for chaining functions
                return this;

    		};


            publicAPIs.removeCard = function(options){

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                var el = settings.el;

                if(el){

                    if(!el.classList.contains('is-animating')){

                        el.classList.add('is-animating');

                        // Is this the last card?
                        var elCardStackAll = el.querySelectorAll('.js-emotion-card');
                        var lastCard = elCardStackAll.length == 1 ? true : false;

                        // Get first card
                        var elCardStackFirst = elCardStackAll[0];

                        // Process Data
                        var cardId = elCardStackFirst.getAttribute('data-id');
                        var quadrantId = options.clickId;

                        // Check if correct
                        var correct = true;
                        var cardCorrectId = elCardStackFirst.getAttribute('data-correct-id');
                        if(cardCorrectId && cardCorrectId!=''){
                            if(cardCorrectId!=quadrantId){
                                correct = false;
                            }
                        }

                        if(correct){
                            // Show correct message callback?
                            if (typeof settings.correctCallback == 'function') {
                                var elCardText = elCardStackFirst.querySelector('.js-card-text');
                                var cardText = '';
                                if(elCardText){
                                    cardText = elCardText.innerText; 
                                }
                                settings.correctCallback.call(this,cardText);
                            }
                            
                            // process card
                            //
                            settings.dataStore[cardId] = quadrantId;
                            //
                            if (!options.clickId) console.log('Missing click ID');

                            // Set Data field
                            updateData(el);

                            // Add direction and colour class
                            var dirClass = options.cardDirection ? 'emotion-card-animate-' + options.cardDirection : 'emotion-card-animate-n';
                            var cardColour = options.cardColour ? 'emotion-card-fg-' + options.cardColour : 'emotion-card-fg-recharge';
                            //
                            elCardStackFirst.classList.add(dirClass, cardColour);

                            // Add card markup to array
                            var backupObj = {
                                classes: elCardStackFirst.className,
                                id: cardId,
                                html: elCardStackFirst.innerHTML
                            }
                            cardBackup.push(backupObj);

                            // disable / enable undo
                            var elUndo = el.querySelector('.js-card-sort-undo');
                            if(elUndo){
                                elUndo.classList.add('active');
                            }

                            settings.undoFunc = options.undoFunc;

                            // After colour change
                            setTimeout(function(){
                                // Fade out first card
                                elCardStackFirst.classList.add('animating');

                                // After fade out
                                setTimeout(function(){

                                    // Remove first card from dom
                                    elCardStackFirst.parentNode.removeChild(elCardStackFirst);

                                    // Pause
                                    setTimeout(function(){

                                        if(!lastCard){

                                            // Activate stack
                                            el.classList.remove('is-animating');

                                        }else{

                                            // Last card callback
                                            if (typeof settings.lastCardCallback == 'function') {
                                                settings.lastCardCallback.call();
                                            }

                                        }


                                    }, 400);

                                }, 500);

                            }, 200);
                        }else{
                            
                            // Show external message
                            if (typeof settings.incorrectCallback == 'function') {
                                settings.incorrectCallback.call();
                            }

                            // do a wiggle
                            elCardStackFirst.classList.add('incorrect');

                            setTimeout(function(){ 
                                elCardStackFirst.classList.remove('incorrect');
                                // Activate stack
                                el.classList.remove('is-animating');
                            }, 750);
                            
                        }

                    }

                }else{
                    console.log('Missing Dom element');
                }

                // Supporting function chaining
                return this;

            }


    		//
    		// Return the Public APIs
    		//

    		return publicAPIs;

    	};

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the CardStack function to be assigned to the window object/module
    return CardStack;

});

/*!

 File: set-clipboard-copy.js
 Author: Ember
 Version: 1.0.1 [MINA-1188]
 JS Dependencies:
    'helper-on' - helper,

 Description:
    Copy text to clipboard and show notification
*/


var setClipboardCopy = {
    init: function (selector) {

        var me = setClipboardCopy;

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            var text = el.getAttribute('data-content');

            if(text && text!= ''){
                me.copyText(text);
            }

        }

        // Attach open event
        on('click', document, clickEvt);

    },
    copyText: function (text) {

        var fallbackCopyTextToClipboard = function(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
          
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
          
            try {
              var successful = document.execCommand('copy');
              //var msg = successful ? 'successful' : 'unsuccessful';
              if(successful){
                copySuccess();
              }else{
                copyError();
              }
              
            } catch (err) {
                copyError();
            }
          
            document.body.removeChild(textArea);
        }

        var copyTextToClipboard = function(text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                copySuccess();
            }, function(err) {
                copyError();
            });
        }

        var copySuccess = function(){
            Notify({
                text: 'Copied to clipboard',
                status: 'success',
                offset: 100
            }).showNotify();
        }

        var copyError = function(){
            Notify({
                text: 'Could not copy to clipboard',
                status: 'error',
                offset: 100
            }).showNotify();
        }

        copyTextToClipboard(text);

    }
}

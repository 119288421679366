/*!

 File: form-ajax-submit.js
 Author: Ember
 Version: 1.0.10 [MMP-5] - check for csrf token
 JS Dependencies:

 CSS Dependencies:

 Description:
    Vanilla JS helper for submitting a form via ajax

*/

var submitAjaxForm = function(elTarget){

    // might have passed in a jquery object
    if (elTarget instanceof jQuery){
        elTarget = elTarget[0];
    }

    // Elements
    var elSaveButton = elTarget.querySelector('.js-ajax-form-save'),
        elFormAlert = elTarget.querySelector('.js-alert');

    // If no save just get submit button
    if(!elSaveButton){
        elSaveButton = elTarget.querySelector('[type=submit]');
    }

    // Call back function name can be passed in as a data attribute.
    var callback;
    // If data attr exists and isn't empty
    if (checkDataAttr(elTarget, 'data-callback')) {
        callback = elTarget.getAttribute('data-callback');
    }

    // Thinking...
    pendingButton.show(elSaveButton);
    pendingForm.disable(elTarget);

    // Get submit URL from action or data-ajax-submit (if it exists)
    var saveFormUrl = elTarget.getAttribute('data-update-url') ? elTarget.getAttribute('data-update-url') : elTarget.getAttribute('action');

    // Get modal ID
    var modalId = elTarget.getAttribute('data-modal-id') ? elTarget.getAttribute('data-modal-id') : null;

    // Get redirect ID
    var redirectUrl = elTarget.getAttribute('data-redirect-url') ? elTarget.getAttribute('data-redirect-url') : null;

    // Show Results
    var showResults = function(resultText, resultHtml){

        // If there is a page redirect just do that
        if(redirectUrl){
            window.location.href = redirectUrl;
            //
            return;
        }

        // If there is a modal on the page show it
        if(modalId){

            // Put returned content into modal
            var elModal = document.querySelector('#' + modalId);

            if(elModal){
                var elModalContent =  elModal.querySelector('.modal-body');
                if(resultHtml && elModalContent){
                    elModalContent.innerHTML = resultHtml;
                }
                // show a modal
                $.magnificPopup.open({
                    items: {
                        src: '#' + modalId
                    },
                    type: 'inline',
                    alignTop: true, // Stick to top of page
                    removalDelay: 500, //delay removal by X to allow out-animation
                    overflowY: 'scroll',
                    mainClass: 'mfp-zoom-in',
                    modal: true
                });
            }else{
                console.log('form-ajax-submit: Modal missing');
            }
            //
            // enable form
            pendingButton.hide(elSaveButton);
            pendingForm.enable(elTarget);
            //
            return;

        }else{

            // if there is HTML to show or an inline alert to update we need to scroll to it
            if(resultHtml || elFormAlert){

                // Scroll to top of form
                var scrollPos = getPosition(elTarget).y - 150; // Take into account possible floating header

                //or scroll to alert position
                if(elFormAlert){
                    scrollPos = getPosition(elFormAlert).y - 150;
                }

                // force positive
                scrollPos = scrollPos > 0 ? scrollPos : 0;

                // Scroll with animation
                // scrollIt(scrollPos, 500, 0, function(){
                ScrollIt({
                    pos: scrollPos,
                    speed: 400,
                    offset: 90,
                    callback: function(){
                        // pause for effect
                        setTimeout(function(){
                            // enable form
                            pendingButton.hide(elSaveButton);
                            pendingForm.enable(elTarget);

                            // Show alert in form
                            if(elFormAlert){
                                // Show alert (still expecting jquery object)
                                formAlert.show($(elFormAlert), resultText, false, status);
                            }else if (resultHtml){
                                // Replace form with results
                                var elFormContainer = elTarget.querySelector('.js-ajax-form-container');
                                if(elFormContainer){
                                    var elFormContainerInner = elFormContainer.querySelector('.js-ajax-form-container-inner');
                                    var elFormResult = elFormContainer.querySelector('.js-ajax-form-result');

                                    // Keep height when changing content
                                    elFormContainer.style.height = elFormContainer.offsetHeight + 'px';

                                    if(elFormContainerInner){
                                        // Fade out form
                                        elFormContainerInner.classList.add('animate-opacity', 'animate-hide');
                                        // after fade out
                                        setTimeout(function(){
                                            // hide form
                                            elFormContainerInner.style.display = 'none';
                                            // show results
                                            if(elFormResult){
                                                // add html
                                                elFormResult.innerHTML = resultHtml;
                                                elFormResult.classList.add('animate-hide');
                                                //
                                                elFormResult.offsetHeight; // force reflow
                                                //
                                                elFormResult.classList.add('animate-opacity');
                                                elFormResult.classList.remove('hidden');
                                                elFormResult.style.display = 'block';
                                                //
                                                elFormResult.offsetHeight; // force reflow
                                                // Fade in
                                                elFormResult.classList.remove('animate-hide');
                                            }else{
                                                console.log('form-ajax-submit: missing .js-ajax-form-result to show HTML results');
                                            }
                                        }, 400);

                                    }else{
                                        console.log('form-ajax-submit: missing .js-ajax-form-container-inner to show HTML results');
                                    }

                                }else{
                                    console.log('form-ajax-submit: missing .js-ajax-form-container to show HTML results');
                                }

                            }

                        }, 400);
                    }
                }).runScroll();

            }else{
                // if there is no HTML or Alert markup we just need to show a Notify toast
                Notify({
                    text: resultText,
                    status: 'success',
                    offset: 100
                }).showNotify();

                // enable form
                pendingButton.hide(elSaveButton);
                pendingForm.enable(elTarget);
            }

        }

    }

    // Post Form
    if(saveFormUrl){

        // Serialise all fields (true allows disabled fields)
        var params = serializeArray(elTarget, true);

        // Are we uploading files?
        var elFileList = elTarget.querySelectorAll('[type=file]');

        if(elFileList && elFileList.length>0){
            // Need to send as a formData object if form may contains images to upload
            // Not supported in <IE10
            var thisFormData = new FormData();

            // Loop through serialised array and add each to form data object
            // ES6 object loop
            Object.keys(params).forEach(function (item) {
                thisFormData.append(item, params[item]);
            });

            // Now append any file fields
            forEachNode(elFileList, function (i, el) {
                var files = el.files;
                thisFormData.append(el.getAttribute('name'), files[0]);
            });

            // Set AJAX options
            params = thisFormData;

        }

        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
        var config = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        }

        // Add csrf token if exists
        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
        var csrf;

        if(elCsrf){
            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
        }

        // Default error message
        var errorMessage = 'Sorry, there was a problem with your request.';

        // Send Request
        axios.post(saveFormUrl, params, config)
            .then(function (response) {

                var responseJson = response.data;

                var responseText;
                var responseHtml;
                var responseStatus;

                // Returned Success
                if(responseJson.status=='success'){

                    // Returned HTML
                    if(responseJson.html && responseJson.html != ''){
                        responseHtml = responseJson.html;
                    }
                    // Returned Message
                    if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                        responseText = responseJson.message.text;
                    }

                    // show response
                    showResults(responseText, responseHtml, responseStatus);

                    // Run callback
                    if (typeof window[callback] === 'function') {
                        window[callback](elTarget, response.data);
                    }
                }

                // Returned Error
                // Just show a toast
                if(responseJson.status=='error'){

                    // See if a message was returned
                    if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                        errorMessage = responseJson.message.text;
                    }
                    
                    Notify({
                        text: errorMessage,
                        status: 'error',
                        offset: 100
                    }).showNotify();
        
                    // enable form
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elTarget);
                }

            })
            .catch(function (error) {
                console.log(error);
                // enable form
                pendingButton.hide(elSaveButton);
                pendingForm.enable(elTarget);

                if(error.response){
                    errorMessage  += '<br>' + error.response;
                }

                // Show error notification
                Notify({
                    text: errorMessage,
                    status: 'error',
                    offset: 100
                }).showNotify();
            });

    }


}

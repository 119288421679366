/*!

 File: set-ajax-modal.js
 Author: Ember
 Version: 1.0.2 [MMP-5] - check for csrf token
 JS Dependencies:
    'focus-first'  - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 Description:
    Click button, open modal, load contents
*/

;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.AjaxModal = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var AjaxModal = (function () {

        var Constructor = function () {

            //
    		// Variables
    		//

    		var publicAPIs = {};

            var settings = {};
            settings.elBtn = null;
            settings.elModal = null;

            /**
    		 * A private method
    		 */

            var clickEvt = function(e){
                var el = e.target;

                var elBtn = settings.elBtn = el.closest(settings.selector);

                // Closest captures self as well a parents
                if (!settings.elBtn) return;

                // Button clicked
                e.preventDefault();

                // Get modal
                // If data attr exists and isn't empty
                var modalId;
                if (checkDataAttr(elBtn, 'data-modal')) {
                    modalId = el.getAttribute('data-modal');
                }else{
                    modalId = '#ajax-modal';
                }

                var elModal = settings.elModal = document.querySelector(modalId);

                if(elModal){
                    // Get modal title
                    var dataTitle = elBtn.getAttribute('data-modal-title');
                    // URL might be further up the tree
                    if(!dataTitle || dataTitle==''){
                        var elDataTitle = elBtn.closest('div[data-modal-title]');
                        if(elDataTitle){
                            dataTitle = elDataTitle.getAttribute('data-modal-title');
                        }
                    }

                    // Set modal title text
                    var elModalTitle = elModal.querySelector('.js-modal-title');
                    if(elModalTitle && dataTitle && dataTitle != ''){
                        elModalTitle.innerText = dataTitle;
                    }

                    // Init the close button
                    var elCloseBtn = elModal.querySelector('.js-modal-close');
                    on('click', elCloseBtn, function(e){
                        e.preventDefault();
                        $.magnificPopup.close();
                    });

                    // Open modal
                    openModal(elModal);

                }
                
            }

            var openModal = function(elModal){

                // Open function to be sent to 'openMpForm' as callback
                var openCallback = function(){
                    loadAjaxForm(elModal);
                }

                // Close function to be sent to 'openMpForm' as callback
                var closeCallback = function(){
                    // remove any modal querystrings
                }

                // Trigger magnific popup form
                openMpForm($(elModal), true, true, openCallback, closeCallback);

            }

            var loadAjaxForm = function(elModal){
                var elResultsContainer = elModal.querySelector('.js-ajax-modal-results'),
                    elResultsContainerInner = elModal.querySelector('.js-ajax-modal-results-inner'),
                    elSpinner = elModal.querySelector('.js-spinner');

                // Reset HTML
                elResultsContainerInner.innerHTML = '';

                // Thinking...
                elSpinner.classList.add('css-spinner-show');

                // find alert block and reset it
                var elAlert = elModal.querySelector('.js-alert');
                if(elAlert){
                    formAlert.reset(elAlert);
                }

                // Get url
                var markupUrl = settings.elBtn.getAttribute('data-markup-url');

                // URL might be further up the tree
                if(!markupUrl || markupUrl==''){
                    var elMarkupUrl = settings.elBtn.closest('div[data-markup-url]');
                    if(elMarkupUrl){
                        markupUrl = elMarkupUrl.getAttribute('data-markup-url');
                    }
                }

                if(markupUrl){
                    // Param object
                    var params = {};

                    // Element ID
                    // If data attr exists and isn't empty
                    if (checkDataAttr(settings.elBtn, 'data-id')) {
                        params['id'] = settings.elBtn.getAttribute('data-id');
                    }

                    // Add any extra data to Params
                    var extraData = settings.elBtn.getAttribute('data-extra');
                    if(extraData && extraData != ''){
                        extraData = JSON.parse(extraData);
                        for (var i in extraData) {
                            params[i] = extraData[i];
                        }
                    }

                    // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
                    var config = {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                        }
                    }

                    // Add csrf token if exists
                    var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
                    var csrf;

                    if(elCsrf){
                        config.headers['X-CSRF-TOKEN'] = elCsrf.content;
                    }
                    
                    // Default error message
                    var errorMessage = 'Sorry, there was a problem with your request.';

                    // Pause for effect
                    setTimeout(function(){
                        axios.post(markupUrl, params, config)
                            .then(function (response) {

                                var responseJson = response.data;

                                // Stop Thinking...
                                elSpinner.classList.remove('css-spinner-show');

                                // Returned Success
                                if(responseJson.status=='success'){
                                    // Set HTML
                                    elResultsContainerInner.innerHTML = responseJson.html;

                                    // Get height
                                    var h = elResultsContainerInner.offsetHeight;

                                    var animatingClass = h < 200 ? 'animating-fast' : 'animating';

                                    // Hide results
                                    elResultsContainerInner.style.display = 'none';
                                    elResultsContainerInner.style.maxHeight = '20px';
                                    elResultsContainerInner.classList.add(animatingClass);

                                    // Init fields
                                    initFields($(elResultsContainerInner));

                                    // show results
                                    elResultsContainerInner.style.display = 'block';
                                    elResultsContainerInner.offsetHeight; // force reflow
                                    elResultsContainerInner.style.maxHeight = h + 'px';

                                    // Reset for next time
                                    setTimeout(function(){
                                        elResultsContainerInner.classList.remove('animating');
                                        elResultsContainerInner.style.maxHeight = '';
                                    },400);

                                }

                                // Returned Error
                                if(responseJson.status=='error'){

                                    // See if a message was returned
                                    if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                        errorMessage = responseJson.message.text;
                                    }

                                    // show error in alert
                                    if(elAlert){
                                        formAlert.show(elAlert, errorMessage, null, 'danger');    
                                    }

                                }

                            })
                            .catch(function (error) {

                                // Stop Thinking...
                                elSpinner.classList.remove('css-spinner-show');

                                if(error.response){
                                    errorMessage  += '<br>' + error.response;
                                }

                                // show error in alert
                                if(elAlert){
                                    formAlert.show(elAlert, errorMessage, null, 'danger');
                                }

                            });


                    }, 400);

                }else{
                    console.log('set-ajax-modal.js missing data-markup-url');
                }

            }



            /**
            * Public methods
            */

            publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.selector = options.selector || null;

                // Attach open event
                if(settings.selector){
                    on('click', document, clickEvt);
                }else{
                    console.log('set-ajax-modal.js is missing a dom element'); 
                }
                

                // Returning the current object for chaining functions
                return this;

            };

            //
    		// Return the Public APIs
    		//

    		return publicAPIs;
        };

        //
    	// Return the Constructor
    	//

    	return Constructor;

    })();

    // Returning the AjaxModal function to be assigned to the window object/module
    return AjaxModal;

});

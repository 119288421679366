/*!

 File: set-datatables.js
 Author: Ember
 Version: 1.2.34 [MMP-111] - Data table - data-label attribute

 JS Dependencies:
    jQuery,
    datatables.js     https://datatables.net/,
    data-attr-check.js (check for existance of data attribute)
    form-pending.js (form ‘thinking’ functions)
    set-magnific.js (magnific popup helper functions)

 CSS Dependencies:
    _custom-datatables.scss

 Description: Datatables UI helper

*/

// Suppress alert warnings
$.fn.dataTableExt.errMode = 'throw';

// Plugin for moving pagination to selected row
// https://datatables.net/plug-ins/api/row().show()
$.fn.dataTable.Api.register('row().show()', function() {
    var page_info = this.table().page.info();
    // Get row index
    var new_row_index = this.index();
    // Row position
    var row_position = this.table().rows()[0].indexOf( new_row_index );
    // Already on right page ?
    if( row_position >= page_info.start && row_position < page_info.end ) {
        // Return row object
        return this;
    }
    // Find page number
    var page_to_display = Math.floor( row_position / this.table().page.len() );
    // Go to that page
    this.table().page( page_to_display );
    // Return row object
    return this;
});

// Data Table Functions
var setDataTable = {
    init: function ($target) {

        var me = setDataTable;

        var thisEditableRows = false;
        var thisEditableCells = false;

        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-editable-rows', true)) {
            thisEditableRows = true;
        }

        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-editable-cells', true)) {
            thisEditableCells = true;
        }

        var $targetTable = $target.find('.table:first');

        var $thisModal = $target.find('.mfp-modal').first();

        // Get ID of element and use this as selector
        var $targetId = $('#' + $targetTable.attr('id'));

        // Display settings
        //
        // Search limit
        var searchLimit = 10;
        if ($target.data('search-limit')) {
            searchLimit = $target.data('search-limit');
        }
        // Buttons
        var domR = '';
        var buttonSettings = [];

        // Array for defining which columns are included in CSV export
        var csvColumns = [];

        // Get data from head cells
        $targetTable.find('thead th').each(function (i) {
            // csv Columns
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (!checkDataAttr($(this), 'data-csv-exclude', true)) {
                csvColumns.push(i);
            }  
        });

        if (checkDataAttr($target, 'data-show-button-csv', true)) {
            //buttonSettings.push('csv');
            var test = {
                extend: 'csvHtml5',
                footer: true,
                bom: true,
                exportOptions: {
                    columns: csvColumns
                }
                /*customize: function (csv) {
                    return "My header\n\n"+  csv;
                }*/
            }
            buttonSettings.push(test);
        }

        if (checkDataAttr($target, 'data-show-button-copy', true)) {
            buttonSettings.push('copy');
        }

        if (checkDataAttr($target, 'data-show-button-pdf', true)) {
            // Will require new build of datatables
            buttonSettings.push('pdf');
        }

        if (buttonSettings.length>0) {
            domR = 'B';
        }

        // B - Buttons
        // l - length changing input control
        // f - filtering input
        // t - The table!
        // i - Table information summary
        // p - pagination control
        // r - processing display element

        var domSettings = '<"html5buttons"' + domR + '>l<"clear">ftip';

        // Process Columns
        var sortColumns = [];
        // Array for columns which have placeholder text
        var placeholderColumns = [];
        // Array for column info in AJAX data
        var ajaxColumnNames = [];
        // Array for columns which are booleans
        var booleanColumns = [];
        // Array for columns which are hidden (but still in table)
        var hiddenColumns = [];
        // Apply column classes to cell
        var classColumns = [];
        var classColumnsClasses = [];
        //
        var labelColumns = [];
        var labelColumnsLabels = [];
        // Array for default sort columns
        var order = [];

        // Editable - process table UI (before th loop)
        if(thisEditableRows){
            // Add blank col to Thead (Needs to happen before th loop)
            $targetTable.find('thead tr').append('<th data-no-sort></th>');
        }

        // Get data from head cells
        $targetTable.find('thead th').each(function (i) {
            // Disable sortable?
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr($(this), 'data-no-sort', true)) {
                sortColumns.push(i);
            }

            // boolean Column?
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr($(this), 'data-col-boolean', true)) {
                booleanColumns.push(i);
            }

            // hidden Column?
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr($(this), 'data-col-hidden', true)) {
                hiddenColumns.push(i);
            }

            // Placeholder text for new rows
            //default placeholder
            var rowText = 'Custom row';
            // If data attr exists and isn't empty
            if (checkDataAttr($(this), 'data-cell-placeholder')) {
                rowText = $(this).data('cell-placeholder');
            }
            // add placeholder text to array
            placeholderColumns.push(rowText);

            // Cell classes and data-labels?
            // allows data-attribute and data-attribute="true"  otherwise returns false
            var colClasses = [];
            var colLabels = [];
            if (checkDataAttr($(this), 'data-col-class', true) || checkDataAttr($(this), 'data-label', true)) {
                classColumns.push(i);
                if(checkDataAttr($(this), 'data-col-class', true)){
                    colClasses = $(this).data('col-class');
                }
                if(checkDataAttr($(this), 'data-label', true)){
                    colLabels = $(this).data('label');
                }
            }
            classColumnsClasses.push(colClasses);
            labelColumnsLabels.push(colLabels);


            // Column data
            // If data attr exists and isn't empty
            if (checkDataAttr($(this), 'data-col-property')) {
                // Specify data and sort by column if required
                var tempColData = {'data':$(this).data('col-property')};
                // If data attr exists and isn't empty
                if (checkDataAttr($(this), 'data-col-sort-by')) {
                    tempColData = {'data': {
                        _: $(this).data('col-property'),
                        sort: $(this).data('col-sort-by')
                    }, 'type':'string'}; // So DT doesn't default to date format
                }
                //
                ajaxColumnNames.push(tempColData);
            }

            // Default sort
            // If data attr exists and isn't empty
            if (checkDataAttr($(this), 'data-col-default-sort-dir')) {
                //console.log('order'+i);
                //console.log($(this).data('col-default-sort-dir'));
                var tempSort = [];
                // push index
                tempSort.push(i);
                // push direction
                tempSort.push($(this).data('col-default-sort-dir'));
                //
                order.push(tempSort);
            }

        });

        // Editable - process table UI (after th loop)
        if(thisEditableRows){
            // Push button column HTML to renderer array
            var editBtnHtml = '<button class="btn btn-primary btn-xs js-datatable-edit-row">Edit</button>';
            var removeBtnHtml = '';
            var deleteBtnHtml = '';
            var viewBtnHtml = '';


            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr($target, 'data-disable-edit', true)) {
                editBtnHtml = '';
            }

            // If data attr exists and isn't empty
            if (checkDataAttr($target, 'data-remove-url')) {
                removeBtnHtml = '<button class="btn btn-warning btn-xs js-datatable-remove-row">Remove</button>';
            }

            // If data attr exists and isn't empty
            if (checkDataAttr($target, 'data-delete-url')) {
                deleteBtnHtml = '<button class="btn btn-danger btn-xs js-datatable-delete-row">Delete</button>';
            }

            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr($target, 'data-disable-delete', true)) {
                deleteBtnHtml = '';
            }

            // If data attr exists and isn't empty
            if (checkDataAttr($target, 'data-form-view-url')) {
                viewBtnHtml = '<button class="btn btn-info btn-xs js-datatable-view-row">View</button>';
            }

            // Build Edit column code
            var editCol = {data: null, defaultContent: '<div class="btn-group">' + viewBtnHtml + editBtnHtml + removeBtnHtml + deleteBtnHtml + '</div>', class: 'dt-edit-btn-cell'};

            // Do we need an edit column after all?
            if(editBtnHtml === '' && removeBtnHtml === '' &&  deleteBtnHtml === '' &&  viewBtnHtml === '') {
                // Remove blank col from THEAD
                $targetTable.find('thead th').last().remove();
                // Remove last sortColumns Array items
                sortColumns.splice(-1,1);
            } else {
                // add edit column
                ajaxColumnNames.push(editCol);
            }
        }


        // Init Event - should come before Data table declaration
        $targetTable.on('init.dt', function(e){
            // Get datatable instance
            var thisTable = $(this).DataTable();
            // Get datatable as dom object
            var $thisTableDom = $(thisTable.table().container());

            // If we don't have any pages hide pagination
            $thisTableDom.find('.dataTables_paginate')
                .css( 'display', thisTable.page.info().pages <= 1 ? 'none' : 'block');

            // If we have less than 10 rows we don't need the entries per page viewer
            $thisTableDom.find('.dataTables_length')
                .css( 'display', thisTable.rows().count() <= 10 ? 'none' : 'block');

            // Show search if there are many rows
            $thisTableDom.find('.dataTables_filter')
                .css( 'display', thisTable.rows().count() <= searchLimit ? 'none' : 'block');

            // Editable rows
            if(thisEditableRows){
                me.initEditableRows($target, thisTable, $thisTableDom, $thisModal);
            }

            // Editable cells
            if(thisEditableCells){
                me.initEditableCells($target, thisTable, $thisTableDom, $thisModal);
            }
        });

        // page update event
        $targetTable.on('draw.dt', function (e) {

            // Get datatable instance
            var thisTable = $(this).DataTable();
            // Get datatable as dom object
            var $thisTableDom = $(thisTable.table().container());

            // Remove pag first/last if there aren't many pages
            if(thisTable.page.info().pages < 6){
                $thisTableDom.find('.paginate_button.first').remove();
                $thisTableDom.find('.paginate_button.last').remove();
            }

            // show/hide pagination based on number of pages
            $thisTableDom.find('.dataTables_paginate')
                .css( 'display', thisTable.page.info().pages <= 1 ? 'none' : 'block');

            // Show total rows amount
            var $totalCount = $(this).closest('.ibox').find('.js-datatable-total').first();
            if($totalCount.length){
                //
                $totalCount.text(thisTable.data().count());
            }


        });

        // Scroll to top on pagination event
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-scroll-on-pag', true)) {
            $targetTable.on('page.dt', function(e) {
                $('html, body').animate({
                    scrollTop: $targetTable.offset().top -250
                }, 400);
            });
        }


        // If there is a data source populate Table from AJAX data
        var thisData;
        var thisAjax = null;
        var thisColumns = null;

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-source-url')) {
            thisData = $target.data('source-url');
            thisAjax = {
                url: thisData,
                dataSrc: '' // read the data from a plain array rather than an array in an object
            };
            thisColumns = ajaxColumnNames;
        }

        // Try to cancel all XHR requests when user navigates away
        if(thisAjax){
            // pre ajax request
            $targetTable.on('preXhr.dt', function (e) {
                $(window).on('beforeunload', function() {
                    //$targetTable.DataTable().context[0].jqXHR.abort();
                    $targetTable.DataTable().settings()[0].jqXHR.abort();
                });
            });
        }

        var lengthOptions = [ 10, 25, 50, 100 ];
        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-length-options')) {
            lengthOptions = $target.data('length-options');
        }

        // If data attr exists and isn't empty
        var lengthDefault = 10;
        if (checkDataAttr($target, 'data-length-default')) {
            lengthDefault = $target.data('length-default');
        }


        // State save
        var stateSave = false;
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if(checkDataAttr($target, 'data-disable-save-state', true)) {
            stateSave = false;
        }

        // datatable options
        var dtOptions = {

            ajax: thisAjax,
            columns: thisColumns,
            rowId: 'id',

            //scrollY: null,
            //scrollCollapse: thisScrollCollapse,
            //scrollX: thisScrollX,
            //fixedColumns: thisFixedColumns,
            lengthMenu: lengthOptions,
            pageLength: lengthDefault,
            responsive: false,
            dom: domSettings,
            deferRender: true, // Only render the rows we need
            buttons: buttonSettings,
            pagingType: 'full_numbers',
            'language': {
                'lengthMenu': '_MENU_ records per page',
                'paginate': {
                    'first': '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    'last': '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    'next': '<i class="fa fa-angle-right" aria-hidden="true"></i>',
                    'previous': '<i class="fa fa-angle-left" aria-hidden="true"></i>'
                }
            },
            order: order, // Column sorting
            stateSave: stateSave,
            columnDefs: [
                {
                    // Sortable columns
                    targets: sortColumns,
                    orderable: false
                },
                {
                    // Hidden columns
                    targets: hiddenColumns,
                    visible: false
                },
                {
                    // Boolean columns
                    targets: booleanColumns,
                    render: function( data, type, row ) {
                        // Return data for sorting
                        if (type == "sort" || type == 'type'){
                            return data;
                        }

                        // Return html for rendering
                        if(data===true || data==='true' || data=== 1 || data==='1'){
                            return '<span style="display:none;">1</span> <i class="fa fa-check text-navy"></i>';
                        }else{
                            return '<span style="display:none;">0</span> <i class="fa fa-times text-danger"></i>';
                        }
                    }
                },
                {
                    // Column classes
                    targets: classColumns,
                    createdCell: function (td, cellData, rowData, row, col) {
                        $(td).addClass(classColumnsClasses[col]);
                        $(td)[0].setAttribute('data-label',labelColumnsLabels[col]);
                    }
                }
                // {
                //     // Column classes
                //     targets: labelColumns,
                //     createdCell: function (td, cellData, rowData, row, col) {
                //         console.log('yo', labelColumnsLabels[col]);
                //         $(td)[0].setAttribute('data-label',labelColumnsLabels[col]);
                //     }
                // }
            ],
            createdRow: function ( row, data, index ) {
                // Add classes / data attributes
                me.processRow($(row), data, false);

            },
            initComplete: function () {
                // Create search filters
                me.initFilters($target);

            },
            // Totalling columns
            footerCallback: function(row, data, start, end, display) {

                var api = this.api();
                var sum;

                colTotalNumeric = function(column){
                    var sum = column.reduce(function (a, b) {
                        var x = parseFloat(a) || 0;
                        var y = parseFloat(b) || 0;
                        return x + y;
                    }, 0);
                    return sum;
                }

                dataIsNumeric = function(data){
                    var tempData;
                    // we need to find the first cell that isn't undefined / empty
                    for(var i=0; i<data.length; i++){
                        if (data[i] != null && data[i] != undefined && data[i] != ''){
                            tempData = data[i];
                            break;
                        }
                    }

                    // Work out if value is an integer
                    // parsint will turn dates into numbers so we use Number
                    return !isNaN(Number(tempData));
                }

                processColSum = function(column){

                    var colTotal;
                    var $colHead = $(column.header());

                    // If data attr exists and isn't empty
                    if(checkDataAttr($colHead, 'data-col-free-text')) {
                        // Free text
                        colTotal = $colHead.data('col-free-text');

                    }else{

                        // allows data-attribute and data-attribute="true"  otherwise returns false
                        if(checkDataAttr($colHead, 'data-col-concat', true)) {
                            // Take all unique items and concatenate them
                            var uniqueArray = column.data().unique();
                            colTotal = '';
                            for(var i=0; i<uniqueArray.length; i++){
                                if(uniqueArray[i]!=''){
                                    colTotal += uniqueArray[i] + ', ';
                                }
                            }
                        }else{

                            if(dataIsNumeric(column.data())){
                                // Numeric total
                                colTotal = colTotalNumeric(column.data());
                                //console.log('dataIsNumeric ' + colTotal);
                            }else{
                                // number of unique items (except blanks)
                                var uniqueArray = column.data().unique();
                                var uniqueTotal = 0;
                                for(var i=0; i<uniqueArray.length; i++){
                                    if(uniqueArray[i]!=''){
                                        uniqueTotal++
                                    }
                                }
                                colTotal = uniqueTotal;
                            }

                        }

                    }

                    // Add to footer
                    $(column.footer()).html(colTotal);
                }


                // Total of whole table
                api.columns('[data-col-sum]').every(function () {
                    processColSum(this);
                });

                // Total of current page
                api.columns('[data-col-sum-page]', { page: 'current' }).every(function () {
                    processColSum(this);
                });
            }
        }


        // Add extra DT options

        // Scroll Y
        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-scroll-y')) {
            thisScrollY = $target.data('scroll-y');
            if(thisScrollY === true){
                thisScrollY = '200px';
            }
            // add to options object
            dtOptions.scrollY = thisScrollY;
            dtOptions.scrollCollapse = true;
        }

        // Scroll X
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-scroll-x', true)) {
            // add to options object
            dtOptions.scrollX = true;
        }
        if (checkDataAttr($target, 'data-fixed-col', true)) {
            // add to options object
            dtOptions.fixedColumns = true;
        }

        // init dataTable
        $targetTable.DataTable(dtOptions);


        // Error reporting on page
        $.fn.dataTable.ext.errMode = 'none';

        $targetTable.on( 'error.dt', function ( e, settings, techNote, message ) {
            $targetTable.after( jQuery.parseJSON(settings.jqXHR.responseText).error );
        });

        //

    },

    // Editing rows using a modal form
    initEditableRows:function($target, thisTable, $thisTableDom, $thisModal){
        var me = setDataTable;

        var $editRow;

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-insert-url')) {

            // If data attr exists and isn't empty
            var context = 'row';
            if (checkDataAttr($target, 'data-entity-type-readable')) {
                // Get context
                context = $target.data('entity-type-readable');
            }

            var $btnTarget;
            var btnHtml;

            // Put button in ibox title if there is only one DT in ibox
            if(checkDataAttr($target, 'data-add-btn-parent', true)){
                btnHtml = '<div class="ibox-btn"><button class="btn btn-primary btn-sm js-dt-add-row" data-test="dt-add-button"><i class="fa fa-plus" title=""></i> Add new ' + context + '</button></div>';
                $btnTarget = $target.closest('.ibox').find('.ibox-title-inner');
            }else{
                btnHtml = '<div class="dt-new-row-btn"><button class="btn btn-primary js-dt-add-row" data-test="dt-add-button"><i class="fa fa-plus" title=""></i> Add new ' + context + '</button></div>';
                $btnTarget = $target;
            }
            $btnTarget.append(btnHtml);

            // allows data-attribute and data-attribute="true"  otherwise returns false
            //add-btn-parent

            // Might want to add button to parent iBox

            //$target.closest('.ibox').find('.ibox-title').append(btnHtml);

            //$btnTarget = $target.closest('.ibox');

            //<div class="ibox-btn">
                                    //<a href="" class="btn btn-info btn-sm"><i class="fa fa-plus"></i> Add new enquiry</a>
                                //</div>

            // Add row event
            $btnTarget.on( 'click', '.js-dt-add-row', function (e) {
                e.preventDefault();

                // we're not editing an existing row
                $editRow = null;

                // Tell modal which button was clicked
                $thisModal.data('btn-source', $(this));

                // Set Modal title
                me.setModalTitle($target, $thisModal, 'Add');

                // open modal
                //$thisModal.modal('show');
                me.openModal($target, thisTable, $thisModal, $editRow);
            });

        }


        // Edit row button
        $thisTableDom.on( 'click', '.js-datatable-edit-row, .js-datatable-view-row', function (e) {
            e.preventDefault();
            //
            $editRow = $(this).closest('tr');

            // process inline modal
            if($thisModal.find('.form-group').length){
                $thisModal.find('.form-group').each(function(){
                    var $field = $(this).find('.form-control:first');
                    var thisName = $field.attr('name');
                    $field.val(thisTable.row( $editRow ).data()[thisName]);
                });
            }
            // Set Modal title
            var thisAction = 'Edit';
            if($(this).hasClass('js-datatable-view-row')){
                thisAction = 'View';
            }
            me.setModalTitle($target, $thisModal, thisAction);


            // Tell modal which button was clicked
            $thisModal.data('btn-source', $(this));

            // open modal
            //$thisModal.modal('show');
            me.openModal($target, thisTable, $thisModal, $editRow);

        });

        // remove row button
        $thisTableDom.on( 'click', '.js-datatable-remove-row', function (e) {
            e.preventDefault();
            // Remove URL
            var removeUrl;
            // If data attr exists and isn't empty
            if (checkDataAttr($target, 'data-remove-url')) {
                removeUrl = $target.data('remove-url');
            }
            //
            if(removeUrl){
                me.removeRow($target, $(this).closest('tr'), thisTable, removeUrl, 'remove')
            }
        });

        // delete entry button
        $thisTableDom.on( 'click', '.js-datatable-delete-row', function (e) {
            e.preventDefault();
            // If data attr exists and isn't empty
            if (checkDataAttr($target, 'data-delete-url')) {
                var removeUrl = $target.data('delete-url');
                me.removeRow($target, $(this).closest('tr'), thisTable, removeUrl, 'delete')
            }
        });

        // delete entry within edit modal
        var $modalDelBtn = $thisModal.find('.js-dt-modal-del').first();
        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-delete-url')) {
            $modalDelBtn.click(function(e){
                e.preventDefault();
                //
                var removeUrl = $target.data('delete-url');
                me.removeRow($target, $thisModal.data('btn-source').closest('tr'), thisTable, removeUrl, 'delete');
            });
        }


        // Open Modal Event
        if($thisModal.length){
            // Add event to Cancel button
            $thisModal.find('.js-dt-modal-close').click(function(e){
                e.preventDefault();
                $.magnificPopup.close();
            });
        }

    },


    // Editing cells using a modal form
    initEditableCells:function($target, thisTable, $thisTableDom, $thisModal){
        var me = setDataTable;

        if($thisModal.length){

            // Edit cell button
            $thisTableDom.on( 'click', 'tbody td', function (e) {

                var colEq = thisTable.cell( this ).index().column;
                var $colTh = $(thisTable.column( colEq ).header());

                // check if column has form URL
                var editCellFormUrl = null;
                // If data attr exists and isn't empty
                if (checkDataAttr($colTh, 'data-form-url')){
                    editCellFormUrl = $colTh.data('form-url');
                }

                // check if column has form VIEW URL
                var $thisSpan = $(this).find('span').first();

                // Am I a view cell?
                // allows data-attribute and data-attribute="true"  otherwise returns false
                var action = checkDataAttr($thisSpan, 'data-cell-view', true) ? 'View' : 'Edit';

                // If data attr exists and isn't empty
                if (checkDataAttr($colTh, 'data-form-view-url')){
                    // If data attr exists and isn't empty
                    if (action == 'View'){
                        // Switch to view instead of edit
                        editCellFormUrl = $colTh.data('form-view-url');
                    }
                }

                if (editCellFormUrl && checkDataAttr($thisSpan, 'data-cell-id')) {
                    e.preventDefault();

                    // Set Modal title
                    me.setModalTitle($target, $thisModal, action);

                    var $editCell = $(this);
                    var $editRow = $(this).closest('tr');

                    // open modal
                    me.openModal($target, thisTable, $thisModal, $editRow, $editCell, editCellFormUrl);

                }

            });

            // Add event to Cancel button
            $thisModal.find('.js-dt-modal-close').click(function(e){
                e.preventDefault();
                $.magnificPopup.close();
            });

        }

    },

    // Set and open modal
    openModal: function($target, thisTable, $thisModal, $editRow, $editCell, editCellFormUrl){
        var me = setDataTable;

        var formUrl = null;

        var $saveButton = $thisModal.find('.js-dt-modal-save').first();
        var $modalDelBtn = $thisModal.find('.js-dt-modal-del').first();

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-form-url')) {
            formUrl = $target.data('form-url');
        }

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-form-view-url')) {
            formUrl = $target.data('form-view-url');
        }

        if(editCellFormUrl){
            formUrl = editCellFormUrl;
        }

        // Am I a 'view' form from a row button
        var viewForm = false;
        if($thisModal.data('btn-source') && $thisModal.data('btn-source').hasClass('js-datatable-view-row')){
            viewForm = true;
        }

        // am I a view form from a cell
        if($editCell){
            if (checkDataAttr($editCell.find('span').first(), 'data-cell-view', true)){
                viewForm = true;
            }
        }

        // 'Edit' form can be different from 'add' form
        if($editRow){
            if (checkDataAttr($target, 'data-form-edit-url')) {
                formUrl = $target.data('form-edit-url');
                console.log('formUrl = ' +formUrl);
            }
        }

        // If I'm a view button we don't need save in modal
        $saveButton.show();
        $modalDelBtn.hide();
        if(viewForm){
            $saveButton.hide();
        }else{
            // Only want delete if URL exists // and not an add form
            if($editRow){
                if (checkDataAttr($target, 'data-delete-url')) {
                    $modalDelBtn.show();
                }
            }
        }


        // Clear any previous error Messages
        $thisModal.find('.alert').hide();

        // Open function to be sent to 'openMpForm' as callback
        var openCallback = function(){
            // once modal is open load data (if necessary)
            if(formUrl){
                // Load our form fields using AJAX
                me.loadAjaxForm($target, thisTable, $thisModal, $editRow, $editCell, formUrl);
            }else{
                // Our modal form is already built so just need to initialise the save form event
                me.initSaveEditForm($target, thisTable, $thisModal, $editRow, $editCell);
            }
        }

        // Close function to be sent to 'openMpForm' as callback
        var closeCallback = function(){
            // Reset modal
            me.closeModal($target, $thisModal);
        }

        // Trigger magnific popup form
        openMpForm($thisModal, true, true, openCallback, closeCallback);

    },

    // Reset and close modal
    closeModal: function($target, $thisModal) {

        var $saveButton = $thisModal.find('.js-dt-modal-save:first'),
            $resultsContainerInner = $thisModal.find('.js-datatable-modal-results-inner');
        //
        $saveButton.unbind('click');
        pendingButton.hide($saveButton);
        pendingForm.enable($resultsContainerInner);
        $saveButton.removeClass('disabled').prop('disabled', false);
        //
        var $resultsContainerInner = $thisModal.find('.js-datatable-modal-results-inner');

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-form-url')) {
            // if its an AJAX form we need to clear HTML
            $resultsContainerInner.html('');
        }else{
            // if it's an inline form we need to clear fields
            resetForm($resultsContainerInner);
        }

    },

    // Remove a DT row
    removeRow: function($target, $editRow, thisTable, removeUrl, action){
        // Get entry ID
        var rowId = thisTable.row( $editRow ).data().id,
            rowEmail = thisTable.row( $editRow ).data().email;

        // Comfirm delete
        var context = 'entry';
        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-entity-type-readable')) {
            context = $target.data('entity-type-readable').toLowerCase();
        }
        var message = 'Are you sure you want to ' + action + ' this ' + context + '?';
        if(rowEmail){
            message += '<br><em>' + rowEmail + '</em>'
        }
        //
        setBootBox.open(null, message, function(){
            // Build params for form
            var params = [];
            params.push({name: 'id', value: rowId});
            //
            $.ajax({
                type: 'POST',
                url: removeUrl,
                data: $.param(params),
                success: function(response) {
                    var responseJson = jQuery.parseJSON(response);
                    // On success remove from datatable
                    thisTable.row($editRow).remove().draw();
                }
            });
            // Might have been triggered from modal
            // close modal
            $.magnificPopup.close();
        });
    },

    // Add or update the contents of a DT row
    updateRow: function($target, $editRow, responseJson){

        // In case dom selector is passed in
        if($target instanceof jQuery === false){
            $target = $($target);
        }

        if(responseJson) {
            if (typeof responseJson.entity != 'undefined' || typeof responseJson.entities != 'undefined'){

                var me = setDataTable;

                var rowNode;

                var $targetTable = $target.find('.table:first'),
                    thisTable = $targetTable.DataTable(),
                    $thisTableDom = $(thisTable.table().container());

                // Remove any lingering updated classes
                $thisTableDom.find('.dt-new-row').removeClass('dt-new-row');

                if($editRow){
                    // Edit existing row
                    rowNode = thisTable.row($editRow).data(responseJson.entity).draw().show().draw( false ).node();
                    // Add classes to new row
                    me.processRow($(rowNode), responseJson.entity, true);

                }else{
                    // single row returned
                    if(responseJson.entity && responseJson.entity != ''){
                        // add row and paginate to new row (show)
                        rowNode = thisTable.row.add(responseJson.entity).draw().show().draw( false ).node();
                        // Add classes to new row
                        me.processRow($(rowNode), responseJson.entity, true);
                    }
                    // array of rows returned
                    if(responseJson.entities && responseJson.entities != ''){
                        responseJson.entities.forEach(function(entity) {
                            rowNode = thisTable.row.add(entity).draw().show().draw( false ).node();
                            // Add classes to new row
                            me.processRow($(rowNode), entity, true);
                        });

                    }

                }


                // show/hide pagination based on number of pages
                /*$thisTableDom.find('.dataTables_paginate')
                    .css( 'display', thisTable.page.info().pages <= 1 ? 'none' : 'block');*/

            }else{
                console.log('Pass back a response entity or entities to update datatable');
            }

        }

    },

    // add new classes to rows (and remove any existing ones)
    processRow: function($row, data, updated){

        // Rows may have 'odd' / 'even' classes, we want to keep those
        var origClass='even';
        if($row.hasClass('odd')){
            origClass = 'odd';
        }
        // Remove any lingering classes
        $row.removeClass().addClass(origClass);

        if(updated){
            $row.addClass('dt-new-row');
        }

        // And new classes
        if(data.rowclass && data.rowclass!=''){
            $row.addClass(data.rowclass);
        }
        // Hide buttons in row
        if(data.hidedel && data.hidedel!=''){
            $row.find('.btn.js-datatable-delete-row').hide();
        }
        if(data.hideremove && data.hideremove!=''){
            $row.find('.btn.js-datatable-remove-row').hide();
        }
        if(data.hideview && data.hideview!=''){
            $row.find('.btn.js-datatable-view-row').hide();
        }
        if(data.hideedit && data.hideedit!=''){
            $row.find('.btn.js-datatable-edit-row').hide();
        }
        $row.find('span[data-cell-id]').closest('td').addClass('cell-hover');

        // Init fields in row
        initFields($row)
    },

    // load contents of modal form
    loadAjaxForm: function($target, thisTable, $thisModal, $editRow, $editCell, formUrl){
        var me = setDataTable;

        // vars
        var params;
        var $resultsContainer = $thisModal.find('.js-datatable-modal-results');
        var $resultsContainerInner = $resultsContainer.find('.js-datatable-modal-results-inner');
        var $spinner = $resultsContainer.find('.ajax-spinner');
        var $saveButton = $thisModal.find('.js-dt-modal-save');

        // Reset HTML
        $resultsContainerInner.html('');

        // Thinking...
        $spinner.show().fadeTo(0.01, 1);
        $saveButton.addClass('disabled').prop('disabled', true);


        // Remove trailing slash
        formUrl =  formUrl.replace(/\/$/, "");

        var thisFormUrl = formUrl;

        // If we're editing an existing entry need to add ID to end of URL
        if($editRow){
            // Get ID of selected row
            var rowId = thisTable.row( $editRow ).data().id;
            // add ID
            thisFormUrl = formUrl + '/' + rowId;
        }
        // If we're editing a cell need to add ID to end of URL
        var viewCell = null;
        if($editCell){
            var $thisSpan = $editCell.find('span').first();
            // get id of cell
            var cellId = $thisSpan.data('cell-id');
            // add ID
            thisFormUrl = formUrl + '/' + cellId;
            // Are we viewing a cell instead of editing
            if (checkDataAttr($thisSpan, 'data-cell-view', true)){
                viewCell = true;
            }
        }

        // Default error message
        var errorMessage = '<p>Sorry, there was a problem with your request.</p>';

        // Clear any previous JSON Calls
        if (typeof xhr_array['dt_editor'] !== 'undefined') {
            xhr_array['dt_editor'].abort();
        }

        // Get form fields with AJAX call
        xhr_array['dt_editor'] = $.ajax({
            url: thisFormUrl,
            data: params,
            dataType: 'json',
            success: function(responseJson) {

                // Keep height
                $resultsContainer.css({'min-height':$resultsContainer.height()});

                // Fade out spinner
                $spinner.fadeTo(200, 0, function(){

                    // stop thinking
                    $saveButton.removeClass('disabled').prop('disabled', false);

                    $spinner.hide();

                    // Returned Success
                    if(responseJson.status=='success'){

                        // Get the response HTML string and convert it to nodes,
                        // that way it can be manipulated before being inserted
                        var responseHtml = jQuery.parseHTML(responseJson.html);

                        // Wrap HTML result in a form so we can run Jquery Validate on it
                        //var $form = $('<form></form>');
                        //$form.html(responseHtml);

                        // Set HTML and hide
                        $resultsContainerInner.slideDown(0.01).fadeTo(0.01, 1).html(responseHtml);;
                        //$form.appendTo($resultsContainerInner);

                        // Init any JS fields in form
                        initFields($resultsContainerInner, function(){

                            // Callback function to ensure fields are intialised before...
                            me.setFields($target, $thisModal, $resultsContainerInner);

                        });

                        // Init the save button
                        me.initSaveEditForm($target, thisTable, $thisModal, $editRow, $editCell);

                        // If button that opened me was a 'view' button disable all fields
                        var viewForm = false;
                        if($thisModal.data('btn-source') && $thisModal.data('btn-source').hasClass('js-datatable-view-row')){
                            viewForm = true;
                        }
                        // If cell that opened me was a 'view' disable all fields
                        if (viewCell) {
                            viewForm = true;
                        }
                        // disable all fields
                        if(viewForm){
                            me.disableFormFields($thisModal);
                        }

                        // show
                        $resultsContainerInner.hide().slideDown(300, function(){
                            // Focus the first fields (if not mobile)
                            if($(window).width() > 700) {
                                $resultsContainerInner.find(':input:visible:enabled:first').focus();
                            }
                        });

                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        $thisModal.find('.alert').html(errorMessage).show();

                    }

                });

            },
            error: function(response, textStatus, errorThrown) {

                // stop thinking
                $spinner.hide();

                // Try and get some data out of response
                if(response.status){
                    errorMessage += '<br>' + response.status + '<br>';
                }

                if(response.responseText){
                    errorMessage += '<br>' + response.responseText;
                }

                if(textStatus){
                    errorMessage += '<br>' + textStatus;
                }

                if(errorThrown){
                    errorMessage += '<br>' + errorThrown;
                }

                $thisModal.find('.alert').html(errorMessage).show();

            }
        });

    },

    // Save event for inline modal or an AJAX modal
    initSaveEditForm: function($target, thisTable, $thisModal, $editRow, $editCell){

        var me = setDataTable;

        var $saveButton = $thisModal.find('.js-dt-modal-save:first');
        var $resultsContainerInner = $thisModal.find('.js-datatable-modal-results-inner');
        var $thisTableDom = $(thisTable.table().container());

        // If <form> doesn't exist add it here
        if(!$resultsContainerInner.find('form').length){
            $resultsContainerInner.wrapInner( '<form></form>');
        }

        var $resultsForm = $resultsContainerInner.find('form');

        // Validate form
        $resultsForm.validate({
            submitHandler: function (form) {

                var saveFormUrl;
                // Edit URL
                // If data attr exists and isn't empty
                if (checkDataAttr($target, 'data-update-url')) {
                    saveFormUrl = $target.data('update-url');
                }
                // Add URL (different to edit URL)
                if(!$editRow && !$editCell){
                    // If data attr exists and isn't empty
                    if (checkDataAttr($target, 'data-insert-url')) {
                        saveFormUrl = $target.data('insert-url');
                    }
                }

                // Edit URL might be on column th
                if($editCell){
                    var colEq = thisTable.cell( $editCell ).index().column;
                    var $colTh = $(thisTable.column( colEq ).header());
                    // If data attr exists and isn't empty
                    if (checkDataAttr($colTh, 'data-update-url')) {
                        saveFormUrl = $colTh.data('update-url');
                    }
                }
                // We can't serailize the form in case there is a nested form so need to serialize all individual fields
                var serialisedForm = $resultsContainerInner.find('input[name], textarea[name], select[name]').serializeArray();
                // add ID of entry to params
                var rowId;
                if($editRow){
                    // Get ID of selected row
                    rowId = thisTable.row( $editRow ).data().id;
                    // Sometimes the server might return the ID as a hidden field in the form, check for it to prevent duplication
                    if(!$resultsContainerInner.find('input[name*="id"]').length){
                        // This must be formatted the same format as serializeArray results (name:x, value:y)
                        serialisedForm.push({name: 'id', value: rowId});
                    }
                }

                // Thinking...
                pendingButton.show($saveButton);
                pendingForm.disable($resultsContainerInner);
                //
                if(saveFormUrl){

                    // Are we uploading files? (fall back to regular serialised array if not)
                    var hasFiles = $resultsContainerInner.find('input:file').length ? true : false;

                    // Default AJAX options (serialised data)
                    var thisData = $.param(serialisedForm);
                    var thisProcessData = true;
                    var thisContentType = 'application/x-www-form-urlencoded; charset=UTF-8';

                    if(hasFiles){

                        // Need to send as a formData object if form may contains images to upload
                        // Not supported in <IE10
                        var thisFormData = new FormData();


                        // Loop through serialised array and add each to form data object
                        for (var i=0; i<serialisedForm.length; i++){
                            thisFormData.append(serialisedForm[i].name, serialisedForm[i].value);
                        }

                        // Now append any file fields
                        $resultsContainerInner.find('input:file').each(function(){
                            if($(this).val() != ''){
                                var files = $(this).get(0).files;
                                thisFormData.append($(this).attr('name'), files[0]);
                            }
                        });

                        // Set AJAX options
                        thisData = thisFormData;
                        thisProcessData = false;
                        thisContentType = false;
                    }

                    // Default error message
                    var errorMessage = '<p>Sorry, there was a problem with your request.</p>';

                    // AJAX request
                    $.ajax({
                        url: saveFormUrl,
                        data: thisData,
                        processData: thisProcessData,
                        contentType: thisContentType,
                        type: 'POST',
                        success: function(response) {
                            var responseJson = jQuery.parseJSON(response);

                            // Stop thinking
                            pendingForm.enable($resultsContainerInner);
                            pendingButton.hide($saveButton, function(){

                                setTimeout(function(){

                                    // Returned Success
                                    if(responseJson.status=='success'){

                                        if(responseJson.redirect_url && responseJson.redirect_url!=""){

                                            // Redirect whole page?
                                            window.location.href = responseJson.redirect_url;

                                        }else{

                                            // Else close modal and show update

                                            // Close modal
                                            $.magnificPopup.close();

                                            // Pause to allow close
                                            setTimeout(function(){

                                                // Put data into table
                                                if($editCell){
                                                    $editRow = $editCell.closest('tr');
                                                }

                                                // Update / Add DT row
                                                me.updateRow($target, $editRow, responseJson);

                                            }, 400);

                                        }

                                    }

                                    // Returned Error
                                    if(responseJson.status=='error'){

                                        // See if a message was returned
                                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                            errorMessage = responseJson.message.text;
                                        }

                                        $thisModal.find('.alert').html(errorMessage).show();

                                        // Add specific error messages to fields
                                        if(responseJson.fields){
                                            for(var i=0; i<responseJson.fields.length; i++){
                                                var $errorField = $resultsContainerInner.find('[name="' + responseJson.fields[i].name + '"]');
                                                // Hidden fields don't have form-groups etc
                                                if($errorField.attr('type') != 'hidden'){
                                                    $errorField.closest('.form-group').addClass('error');
                                                    var errorLabelHtml = '<label class="error" for="check-v">' + responseJson.fields[i].message + '</label>';
                                                    $errorField.parent().append(errorLabelHtml);
                                                }
                                            }
                                        }

                                    }

                                }, 400);

                            });
                        },
                        error: function(response, textStatus, errorThrown) {

                            if(response.status){
                                errorMessage += '<br>' + response.status + '<br>';
                            }

                            if(response.responseText){
                                errorMessage += '<br>' + response.responseText;
                            }

                            if(textStatus){
                                errorMessage += '<br>' + textStatus;
                            }

                            if(errorThrown){
                                errorMessage += '<br>' + errorThrown;
                            }

                            // Stop thinking
                            pendingForm.enable($resultsContainerInner);
                            pendingButton.hide($saveButton, function(){
                                //
                                $thisModal.find('.alert').html(errorMessage).show();
                            });
                        }
                    });

                }else{
                    // Just stick data into table
                    console.log('stick data into table');
                }

            }
        });

        // Set the click event
        $saveButton.unbind('click').click(function(e){
            e.preventDefault();
            // Submit form (validate first)
            $resultsForm.submit();

        });

    },

    // Set modal title (view, edit, add etc)
    setModalTitle: function($target, $thisModal, action){

        var context = 'Entry';
        var faClass;
        switch(action) {
            case 'Add':
                faClass = 'fa-plus';
                break;
            case 'View':
                faClass = 'fa-eye';
                break;
            case 'Edit':
                faClass = 'fa-pencil-square-o';
                break;
        }


        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-entity-type-readable')) {
            context = $target.data('entity-type-readable');
        }
        $thisModal.find('.modal-title span').text(action+' '+context);
        $thisModal.find('.modal-title i').removeClass().addClass('fa '+faClass);
        $thisModal.find('.js-dt-modal-del span').text(context);
    },

    // Disable all fields in a modal form
    disableFormFields: function($thisModal){

        $thisModal.find('.js-datatable-modal-results-inner :input').prop('disabled', true);
        $thisModal.find('.js-datatable-modal-results-inner select').prop('disabled', true).trigger('chosen:updated');
        // Disable any async uploaders
        $thisModal.find('.js-async-upload').each(function(){
            setAsyncUpload.disableAsyncUpload($(this));
        });

    },

    // Set the content / status of fields in modal form based on data attribute
    setFields: function($target, $thisModal, $resultsContainerInner){

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-set-field')) {
            // Get field array from data
            var setFields = $target.data('set-field');
            // Array for storing new hidden fields (if required)
            var hiddenFieldArray = [];

            if(setFields && setFields != null && setFields != undefined && setFields != '') {

                // function to generate a hidden field - for single selects and items without an already existing field
                var addNewField = function (i) {
                    var newField = $('<input>').attr({
                        type: 'hidden',
                        //type: 'text', // switch to 'text' for testing
                        name: setFields[i].field_name,
                        value: setFields[i].value
                    });
                    hiddenFieldArray.push(newField);
                }

                // Loop through object array
                for (var i = 0; i < setFields.length; i++) {
                    // Get field
                    var $setFieldsField = $thisModal.find('.form-control[name="' + setFields[i].field_name + '"]');
                    // Multiselects dont have name attributes so try looking for a 'data-name'
                    if (!$setFieldsField.length) {
                        $setFieldsField = $thisModal.find('.form-control[data-name="' + setFields[i].field_name + '"]');
                    }

                    // If field already exists in the form
                    if ($setFieldsField.length) {
                        // Disable field
                        $setFieldsField.prop('readonly', true);

                        // Update val - (we're not checking if that select option exists)
                        $setFieldsField.val(setFields[i].value);

                        // If I'm a single select need to create a blank field as you can't have 'readonly' selects (and we want the data posted on submit)
                        // (Multi selects already have hidden fields)
                        if ($setFieldsField.prop('type') == 'select-one') {
                            addNewField(i);
                        }

                        // Trigger 'Chosen' update if needed
                        if ($setFieldsField.hasClass('js-select')) {
                            $setFieldsField.prop('disabled', true).trigger('chosen:updated');
                            $setFieldsField.change(); // also trigger browser change event to set .values-container
                        }

                    } else {
                        // If doesn't already exist create a hidden field
                        addNewField(i);
                    }

                }

                // If we have created hidden fields add them to form
                if (hiddenFieldArray.length > 0) {
                    $resultsContainerInner.append(hiddenFieldArray);
                }
            }

        }

    },

    // Create table search filters
    initFilters: function($target){

        var $datatableFilters = $target.find('.js-datatable-filters');

        if($datatableFilters.length && $target.find('.table:first [data-filter-column]').length){
            // We only want to show the clear filters but if there is at least one column with unique data
            var showClearBtn = false;

            // Run filter creation for every column that has 'filter-column' data attribute
            $target.find('.table:first').dataTable().api().columns('[data-filter-column]').every( function () {

                var column = this;

                // for pre-selecting select value
                var selectVal = null;
                // If data attr exists and isn't empty
                if (checkDataAttr($(column.header()), 'data-filter-val')) {
                    selectVal = $(column.header()).data('filter-val');
                }

                var filterType = 'select';
                // If data attr exists and isn't empty
                if (checkDataAttr($(column.header()), 'data-filter-type')) {
                    filterType = $(column.header()).data('filter-type');
                }

                var selectType = '';
                // If data attr exists and isn't empty
                if (checkDataAttr($(column.header()), 'data-select-type')) {
                    selectType = $(column.header()).data('select-type');
                }

                // we only want to generate a filter if there is more than one unique option in the column
                if(column.data().unique().length>1){

                    // We'll need a clear button
                    showClearBtn = true;

                    var title = $(column.header()).html();

                    switch(filterType) {
                        case 'date':
                            // Create date picker field

                            // Get template for select from HTML
                            var dateHtml = $datatableFilters.find('.js-datatable-filters-blank-date').html();

                            if(dateHtml){
                                var $dateBlock = $(dateHtml);

                                // Add field
                                $dateBlock.appendTo($datatableFilters);

                                // Is there already a search on this column
                                var s = column.search();
                                s = (s.length && s[0] == '^') ? s.slice(1) : s; // Remove ^ from beginning (see search regex)
                                s = (s.length && s[s.length-1] == '$') ? s.substr(0, s.length - 1) : s; // Remove $ from end (see search regex)

                                // Get column data
                                var dataArray = column.data().unique();

                                // Remove null entries from array
                                dataArray = dataArray.filter(function( val ) {
                                    return val !== null;
                                });

                                // set enabled dates (may break on non-default date formats)
                                var enabledDates = '[';
                                for(var i=0; i<dataArray.length; i++){
                                    enabledDates += '"' + dataArray[i] + '"';
                                    if(i<dataArray.length-1){
                                        enabledDates += ',';
                                    }
                                }
                                enabledDates += ']';

                                var $dateInput = $dateBlock.find('input').first();

                                // Set label
                                var $label = $dateBlock.find('label').first();
                                if($label.length && !$label.hasClass('sr-only')){
                                    $label.text(title);
                                }

                                // Set placeholder / data attr
                                $dateInput.attr('placeholder', title)
                                    .attr('data-enable-dates', enabledDates);

                                // Set name attribute
                                // If data attr exists and isn't empty
                                if (checkDataAttr($(column.header()), 'data-filter-name')) {
                                    $dateInput.attr('name', $(column.header()).data('filter-name'));
                                }

                                // Run search on columns
                                var runSearch = function(val){
                                    column
                                        .search(val ? '^'+val+'$' : '', true, false)
                                        .draw();
                                }

                                // Bootstrap datepicker change event
                                var changeCallback = function(){
                                    val = $.fn.dataTable.util.escapeRegex(
                                        $dateInput.val()
                                    );
                                    runSearch(val);
                                }

                                // When clearing datepicker trigger datepicker update to run search
                                $dateInput.on('change', function() {
                                    if($(this).val() === ''){
                                        // Clear datepicker
                                        $dateBlock.find('.js-datepicker').datepicker('clearDates');
                                        runSearch('');
                                    }
                                });

                                // init datepicker
                                setDatePicker.init($dateBlock.find('.js-datepicker').first(), changeCallback);

                                // Set date if search already exists
                                if(s && s!=''){
                                    $dateBlock.find('.js-datepicker').first().datepicker('setDate', s);
                                }

                            }

                            break;

                        case 'date-range':
                            // Create date range picker field

                            // Get template for select from HTML
                            var dateRangeHtml = $datatableFilters.find('.js-datatable-filters-blank-date-range').html();

                            if(dateRangeHtml){
                                var $dateRangeBlock = $(dateRangeHtml);

                                // Add field
                                $dateRangeBlock.appendTo($datatableFilters);

                                // Set label
                                var $label = $dateRangeBlock.find('label').first();
                                if($label.length && !$label.hasClass('sr-only')){
                                    $label.text(title);
                                }

                                // Set name attribute
                                var $dateInput = $dateRangeBlock.find('input').first();
                                // If data attr exists and isn't empty
                                if (checkDataAttr($(column.header()), 'data-filter-name')) {
                                    $dateInput.attr('name', $(column.header()).data('filter-name'));
                                }
                                //@TODO this is only going to add a name to the first input in a daterange block

                                // Is there already a search on this column
                                var s = column.search();
                                s = (s.length && s[0] == '^') ? s.slice(1) : s; // Remove ^ from beginning (see search regex)
                                s = (s.length && s[s.length-1] == '$') ? s.substr(0, s.length - 1) : s; // Remove $ from end (see search regex)

                                // Run search on columns
                                var runSearch = function(val){
                                    if(!val || val===''){
                                        // Regular search (range search doesn't seem to clear)
                                        column
                                            .search(val ? '^'+val+'$' : '', true, false)
                                            .draw();
                                    }else{
                                        // Range search
                                        column
                                        .search(val ? "^"+val+"$" : "^" + "-" + "$", true, false, true)
                                        .draw();
                                    }

                                }

                                var $dateInputFrom = $dateRangeBlock.find('input').first();
                                var $dateInputTo = $dateRangeBlock.find('input').last();

                                // Get date format from input
                                var dateFomat = ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD-MMM-YYYY'];
                                // If data attr exists and isn't empty
                                if (checkDataAttr($dateInputFrom, 'data-format')) {
                                    // Moment seems to want it uppercase for some reason
                                    dateFomat = $dateInputFrom.data('format').toUpperCase();
                                }

                                // Bootstrap datepicker change event
                                var changeCallback = function(){

                                    var dateStartVal = $dateInputFrom.val();
                                    var dateEndVal = $dateInputTo.val();

                                    if(dateStartVal && dateEndVal) {

                                        var dateStart = moment(dateStartVal, dateFomat);
                                        var dateEnd = moment(dateEndVal, dateFomat);

                                        var filteredData = column
                                            .data()
                                            .filter(function (value, index) {

                                                var evalDate = value === "" ? 0 : moment(value, dateFomat);

                                                if (evalDate.isBetween(dateStart, dateEnd)) {
                                                    return true;
                                                }
                                                return false;
                                            });

                                        // Build pipe sperated list of values
                                        var val = "";
                                        for (var i = 0; i < filteredData.length; i++) {
                                            val += filteredData[i] + "|";
                                        }
                                        val = val.slice(0, -1);

                                        // Run search on column using list
                                        if(val.length<1){
                                            // need to output no results if outside of range (rather than returning every)
                                            runSearch('noresults');
                                        }else{
                                            runSearch(val);
                                        }

                                    }


                                }

                                // When clearing datepicker, trigger datepicker update to run search
                                $dateRangeBlock.on('change', 'input', function() {
                                    if($dateInputFrom.val() === '' && $dateInputTo.val() === ''){
                                        // Clear datepicker
                                        $dateRangeBlock.find('input').datepicker('clearDates');
                                        // Clear search
                                        runSearch('');
                                    }
                                });

                                // init datepicker
                                setDatePicker.init($dateRangeBlock.find('.js-datepicker').first(), changeCallback);

                                // Set date if search already exists (not perfect but good enough)
                                if(s && s!=''){
                                    $dateRangeBlock.find('input').datepicker('setDate', s);
                                }

                            }

                            break;

                        default:
                            // Create select field

                            // Get template for select from HTML
                            var selectHtml = $datatableFilters.find('.js-datatable-filters-blank-select').html();

                            if(selectHtml){
                                var $selectBlock = $(selectHtml);
                                var $select = $selectBlock.find('select').first();

                                // Make it a multiselect?
                                if(selectType==='multiple'){
                                    $select.attr('multiple', 'true');
                                }

                                // Is there already a search on this column
                                var s = column.search();
                                s = (s.length && s[0] == '^') ? s.slice(1) : s; // Remove ^ from beginning (see search regex)
                                s = (s.length && s[s.length-1] == '$') ? s.substr(0, s.length - 1) : s; // Remove $ from end (see search regex)


                                // There might be a label in the block
                                var $label = $selectBlock.find('label').first();
                                var firstOptionText = title;
                                if($label.length && !$label.hasClass('sr-only')){
                                    $label.text(title);
                                    firstOptionText = 'Please Select';
                                }

                                // Remove leading white space
                                firstOptionText = firstOptionText.trim();

                                // add a blank title option
                                $select.append( '<option value="">'+firstOptionText+'</option>' );

                                // Set name attribute
                                // If data attr exists and isn't empty
                                if (checkDataAttr($(column.header()), 'data-filter-name')) {
                                    $select.attr('name', $(column.header()).data('filter-name'));
                                }

                                // Is it a multiple column (eg tag list)
                                var multiColumn = false;
                                // allows data-attribute and data-attribute="true"  otherwise returns false
                                if (checkDataAttr($(column.header()), 'data-filter-multiple', true)) {
                                    multiColumn = true;
                                }

                                // Get column data
                                var dataArray = column.data().unique();

                                // Remove null entries from array
                                dataArray = dataArray.filter(function( val ) {
                                    return val !== null;
                                });

                                // Strip tags and split multiples
                                for(var i=0; i<dataArray.length; i++){
                                    // Split multiples by commas (eg a group of span tags)
                                    var splitter = multiColumn ? ',' : '';

                                    // Remove <a>
                                    dataArray[i] = dataArray[i].replace(/<a\b[^>]*>/g,'')// Remove opening tag
                                    .replace(/<\/a>/g, splitter);// Remove closing tag

                                    // Remove <span>
                                    dataArray[i] = dataArray[i].replace(/<span\b[^>]*>/g,'')// Remove opening tag
                                    .replace(/<\/span>/g, splitter);// Remove closing tag

                                    // Remove any multiple commas / trailing commas
                                    dataArray[i] = dataArray[i].split(',') // split the string.
                                        .filter(function(item){ // Then filter out empty items
                                            return item !== '';
                                        })
                                        .toString(); // turn back into string
                                    // remove trailing comma
                                    //dataArray[i] = dataArray[i].replace(/,\s*$/, "");
                                }


                                if(multiColumn){
                                    // new array to store results
                                    var multipleArray = [];

                                    // Split each array item and add to end of new array
                                    for(var i=0; i<dataArray.length; i++){
                                        var arraySplit = dataArray[i].split(", ");
                                        multipleArray = multipleArray.concat(arraySplit);
                                    }

                                    // clear array dataArray
                                    dataArray.length = 0;

                                    // populate dataArray removing any duplicates
                                    $.each(multipleArray, function(i, el){
                                        if($.inArray(el, dataArray) === -1) {
                                            dataArray.push(el);
                                        }
                                    });
                                }


                                // Alphabetic sort
                                dataArray.sort();

                                // Instances where a text column needs to be sorted by date
                                // allows data-attribute and data-attribute="true"  otherwise returns false
                                if (checkDataAttr($(column.header()), 'data-filter-chron', true)) {
                                    var testDate = new Date(dataArray[0]);
                                    if(testDate !== "Invalid Date" && !isNaN(testDate)){
                                        // sort whole array by date
                                        dataArray = dataArray.sort(function(a,b){
                                            return new Date(a) - new Date(b);
                                        });
                                    }
                                }



                                // Populate select based on column data
                                dataArray.each( function ( d, j ) {
                                    if(d && d!=''){
                                        // If this option equals the current search term
                                        var thisSelected = '';
                                        if (d == s){
                                            thisSelected = 'selected';
                                        }
                                        // If a value has been set
                                        if(selectVal && selectVal == d){
                                            thisSelected = 'selected';
                                        }
                                        // Create option
                                        $select.append( '<option value="' + d + '" ' + thisSelected + '>' + d + '</option>' );
                                    }
                                });

                                // Change event
                                $selectBlock.find('select').first()
                                    .on('change', function() {
                                        // Convert value of select (multi or single) to an array
                                        var val = [];
                                        //
                                        var selectVal = $(this).val();
                                        //
                                        if($.isArray(selectVal)){
                                            val = selectVal;
                                        }else{
                                            if(selectVal){
                                                val.push(selectVal);
                                            }
                                        }

                                        // Go through array
                                        for(var i=0; i<val.length; i++){
                                            if(val[i]){
                                                // Escape the expression so we can perform a regex match
                                                val[i] = $.fn.dataTable.util.escapeRegex(val[i]);
                                            }
                                        }
                                        // Create pipe seperated string
                                        val = val.join('|');

                                        // Run search
                                        if(multiColumn){
                                            // Search within (to allow for comma seperated list)
                                            column
                                                // search( input [, regex[ , smart[ , caseInsen ]]] )
                                                .search(val ? val : '', true, false, false)
                                                .draw();
                                        }else{
                                            // search for exact result
                                            column
                                                // search( input [, regex[ , smart[ , caseInsen ]]] )
                                                .search(val ? '^'+val+'$' : '', true, false, false)
                                                .draw();
                                        }

                                    });

                                // Add select
                                $selectBlock.appendTo($datatableFilters);

                                // init chosen
                                initFields($selectBlock);

                                // Trigger change event to update DT
                                $selectBlock.find('select').first().trigger('change');

                            }

                    }
                }

            });

            // Add clear filters button
            var buttonHtml = $datatableFilters.find('.js-datatable-filters-blank-button').html();// Get template for button from HTML

            if(buttonHtml && showClearBtn){
                var $buttonBlock = $(buttonHtml);
                var $button = $buttonBlock.find('button').first();

                $button.text('Clear Filters').click(function(e){
                    e.preventDefault();
                    // Clear all searches
                    $target.find('.table:first').dataTable().api()
                    .table().search('')
                    .columns().search('')
                    .draw();
                    // Clear all filters
                    // Datepicker
                    $datatableFilters.find('.js-datepicker').datepicker('clearDates');
                    // Daterange
                    $datatableFilters.find('.js-datepicker.input-daterange input').datepicker('clearDates');
                    // Chosen
                    $datatableFilters.find('select').val('').trigger('chosen:updated');
                });

                $buttonBlock.appendTo($datatableFilters);
            }

        }

    },

    externalRemoveRow: function($target, data){
        // In case dom selector is passed in
        if($target instanceof jQuery === false){
            $target = $($target);
        }

        // Get datatable
        var $targetTable = $target.find('.table:first'),
            thisTable = $targetTable.DataTable(),
            $thisTableDom = $(thisTable.table().container());

        // Get data
        var allData = thisTable.rows().data();
 
        // Loop through the data array
        if(data && data.length){
            for(var i=0; i<data.length; i++){
                // Get key / value from object
                var key = Object.keys(data[i])[0];
                var value = data[i][key];
                // loop through the data and find the row that matches
                for(var j=0; j<allData.length; j++){
                    if(allData[j][key] === value){
                        // get dom row
                        var $editRow = thisTable.row('#'+allData[j].id).node();
                        // remove row
                        $editRow.style.transition = 'opacity 0.2s linear';
                        $editRow.style.opacity = 0;
                        window.setTimeout(function(){
                            thisTable.row($editRow).remove().draw();
                        },400)
                        
                    }
                }
            }
        }

    }

}


// Helper function for inserting data
var dataTableInsert = function(){
    console.log('dataTableInsert');
}
/*!

 File: set-chart-anim.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'closest' polyfill
    'on' helper

 CSS Dependencies:

 Description:
     Animate / Reveal a chart when it comes into view
*/

var setChartAnim = {
    chartWatchers: [],
    init: function (elNodeList) {
        var me = setChartAnim;

        // Reset items ready for anim
        var resetCharts = function(el){
            // get all chart children
            var elAnimCharts = el.querySelectorAll('.js-chart-anim-item');
            forEachNode(elAnimCharts, function (i, el) {
                el.classList.add('anim-reset');
            });
        }

        // start anim
        var animateCharts = function(el){
            el.classList.add('anim-activated');
            // get all chart children
            var elAnimCharts = el.querySelectorAll('.js-chart-anim-item');
            forEachNode(elAnimCharts, function (i, el) {
                el.classList.remove('anim-reset');
                el.classList.add('animating');
            });
        }

        // set watchers
        forEachNode(elNodeList, function (i, el) {

            // Reset children
            //resetCharts(el);

            me.chartWatchers[i] = scrollMonitor.create(el, {bottom: -60, top:150});

            me.chartWatchers[i].enterViewport(function() {
                // activate anim
                if (!el.classList.contains('anim-activated') && !el.classList.contains('anim-reset')) {
                    resetCharts(el);
                }
            });

            me.chartWatchers[i].fullyEnterViewport(function() {
                // activate anim
                if (!el.classList.contains('anim-activated')) {
                    animateCharts(el);
                }
            });

        });

    }
}

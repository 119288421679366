/*!

 File: set-report-nav.js
 Author: Ember
 Version: 1.0.4 [MS-614] - fix page jump on hash change
 JS Dependencies:
    set-overlay,
    helper-on,
    'closest' polyfill

 CSS Dependencies:

 Description:
     Scroll to page on nav click
*/

var setReportNav = {
    pauseWatchers: false,
    init: function (elTarget) {
        var me = setReportNav;

        // When nav clicked check for hash and scroll to element
        var navClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-report-nav-click')) return;

            // get href for ID
            var btnHref = e.target.getAttribute('href');

            // If hash exists
            if (btnHref && btnHref.indexOf('#') > -1) {

                // We only want to scroll if currently in parent page
                if (!e.target.classList.contains('js-report-nav-click-scroll')) return;

                // Button clicked
                e.preventDefault();

                // Close mobile menu (only if open though)
                if(setMobileNav && typeof setMobileNav === 'object' && setMobileNav !== null){
                    if(document.body.classList.contains('mob-nav-open')){
                        setMobileNav.closeNav();
                    }
                }

                // show as active
                updateNavActive(e.target);

                // Remove focus
                document.activeElement.blur();

                // Scroll
                var hashText = '#' + btnHref.substring(btnHref.indexOf('#')+1);
                me.scrollToElement(hashText, true);

                // Change hash url (without affecting back button)
                // Best that this comes after the scrollToElement as it temporarily removes id of element
                setHashUrl(hashText);

            }

        };

        // Find nav item, set it to active and scroll to it
        var updateNavActive = function(el){
            if(el){
                // remove active
                var elActive = document.querySelectorAll('.active');
                if(elActive && elActive.length>0){
                    forEachNode(elActive, function (i, el) {
                        el.classList.remove('active');
                    });
                }
                // Show active
                el.classList.add('active');

                var parentUl = el.closest('ul')

                if(!parentUl.classList.contains('report-nav')){
                    parentUl.parentNode.querySelector('a').classList.add('active');
                }

                // Scroll to nav element
                //var elScrollDiv = el.closest('.simplebar-content');
                //if(!elScrollDiv){
                    var elScrollDiv = el.closest('.report-nav-container-inner');
                //}
                if(elScrollDiv){
                    elScrollDiv.scrollTop = el.offsetTop - 100;
                }

            }else{
                console.log('updateNavActive missing element');
            }
        }

        // Change hash url (without affecting back button)
        var setHashUrl = function(hash){
            // remove hash from element so browser doesn't scroll
            if(hash && hash!='' && hash!='#'){
                // we add ! to our hashes to stop the page jumping to that element when the URL changes
                var hashText = hash.split('#')[1];
                //
                if(history.replaceState){
                    history.replaceState(undefined, undefined, '#!'+hashText);
                }else{
                    window.location.hash = '#!'+hashText;
                }
            }
        }

        // On load set nav item if page has hash
        var pageHash = document.location.hash;

        if (pageHash) {
            pageHash = pageHash.replace('#!','#');
            // show as active
            var elLink = elTarget.querySelector('a[data-hash="' + pageHash.split('#')[1] + '"]');
            updateNavActive(elLink);

            // Scroll to page element
            me.scrollToElement(pageHash, false);
        }


        // change nav when section scrolls in
        var elReportItems = document.querySelectorAll('.js-report-item');
        var reportItemWatchers = [];

        if(elReportItems && elReportItems.length>0){

            forEachNode(elReportItems, function (i, el) {

                reportItemWatchers[i] = scrollMonitor.create(el, {top:120});
                    //, {top: 0, bottom: -200});

                reportItemWatchers[i].fullyEnterViewport(function() {
                    // only if user is scrolling rather than animated scroll
                    if(!me.pauseWatchers){

                        // Each link should have a data-hash to help us target it
                        var elLink = elTarget.querySelector('a[data-hash="' + el.id + '"]');

                        // show as active
                        updateNavActive(elLink);

                        // Change hash url (without affecting back button)
                        setHashUrl('#' + el.id);
                    }

                });

                reportItemWatchers[i].enterViewport(function() {
                    // Initialise any lazy images
                    var elLazyImages = el.querySelectorAll('.js-workbook-image-lazy');
                    forEachNode(elLazyImages, function (i, el) {
                        // switch image
                        setImgLazyLoad.init(el);
                    });
                });

            });

        }

        // attach event
        on('click', document, navClick);

    },
    scrollToElement: function(hashID, clicked){
        var me = setReportNav;

        // pause watchers while we scroll
        me.pauseWatchers = true;

        var pauseCallback = function(){
            // bit of delay just in case
            setTimeout(function(){
                me.pauseWatchers = false;
            },200);
        }

        // scroll to element
        var elScrollTo = document.querySelector(hashID);
        //scrollIt(elScrollTo, 400, 90, pauseCallback);
        ScrollIt({
            pos: elScrollTo,
            speed: 400,
            offset: 90,
            callback: pauseCallback
        }).runScroll();
    }
}

/*!

 File: form-pending.js
 Author: Ember
 Version: 1.0.4
 JS Dependencies:
    jQuery,
    helper-trigger-event

 Description:
    Vanilla JS
    Functions for disabling forms and buttons, showing spinners etc

*/



// ============================================== Pending Button with spinner //

var pendingButton = {
    showTimerA:{},
    hideTimerA:{},
    hideTimerB:{},
    show: function (elTarget){
        if(elTarget){
            // Disable button and add spinner
            //
            var me = pendingButton;

            // might have passed in a jquery object
            if (elTarget instanceof jQuery){
                elTarget = elTarget[0];
            }

            // disable button
            elTarget.classList.add('disabled');
            elTarget.disabled = true;

            // Blur
            triggerEvent(elTarget,'blur');
            triggerEvent(elTarget,'mouseleave');

            // clear any timers hanging around
            me.clearTimers();

            // Change padding ready for spinner
            elTarget.classList.add('btn-pending');

            // show spinner
            me.showTimerA = setTimeout(function(){
                elTarget.classList.add('btn-pending-show');
            }, 200);

        }else{
            console.log('pendingButton error: "target missing"');
        }
    },
    hide: function (elTarget, callback) {
        if(elTarget){
            var me = pendingButton;

            // might have passed in a jquery object
            if (elTarget instanceof jQuery){
                elTarget = elTarget[0];
            }

            // Enable button and remove spinner
            // Going to delay this a bit to allow the spinner to finish opening so user can see feedback
            me.hideTimerA = setTimeout(function(){
                // clear any timers hanging around
                me.clearTimers();
                //
                elTarget.classList.remove('btn-pending-show');
                //
                me.hideTimerB = setTimeout(function(){
                    elTarget.classList.remove('btn-pending');
                    elTarget.classList.remove('disabled');
                    elTarget.disabled = false;

                    // Run callback
                    if (typeof callback === 'function'){
                        callback();
                    }
                }, 200);

            }, 400);
        }else{
            console.log('pendingButton error: "target missing"');
        }
    },
    clearTimers: function(){
        var me = pendingButton;
        //
        clearTimeout(me.showTimerA);
        clearTimeout(me.hideTimerA);
        clearTimeout(me.hideTimerB);
    }
};

// ============================================== Pending Form Disabled //

var pendingForm = {
    disable: function (elTarget) {
        if(elTarget){
            // might have passed in a jquery object
            if (elTarget instanceof jQuery){
                elTarget = elTarget[0];
            }
            elTarget.classList.remove('fadeout')
            elTarget.classList.add('disabled');
        }else{
            console.log('pendingForm error: "target missing"');
        }
    },
    enable: function (elTarget, callback) {
        if(elTarget){
            // might have passed in a jquery object
            if (elTarget instanceof jQuery){
                elTarget = elTarget[0];
            }

            elTarget.classList.add('fadeout');
            // Pause for CSS transition end
            setTimeout(function(){
                elTarget.classList.remove('disabled')
                elTarget.classList.remove('fadeout');
                // Run callback
                if (typeof callback === 'function'){
                    callback();
                }
            }, 200);
        }else{
            console.log('pendingForm error: "target missing"');
        }
    }
};


// ============================================== Clear all form fields //

function resetForm($form) {
    if($form){
        $form.find('input:text, input:password, input:file, select, textarea')
            .val('');
        // may need to update chosen selects here
        $form.find('input:radio, input:checkbox')
            .removeAttr('checked').removeAttr('selected');
    }
}


// ============================================== Disable / enable main page save //

var disablePageSave = {
    disable: function () {
        $('.js-form-save').addClass('disabled').prop('disabled', true);
    },
    enable: function(){
        $('.js-form-save').removeClass('disabled').prop('disabled', false);
    }
}

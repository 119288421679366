/*!

 File: set-dropdown.js
 Author: Ember
 Version: 1.0.10 [MMP-5] - check for csrf token
 JS Dependencies:
    'closest' polyfill
    'helper-scrollit' animated scrolls
    'forEachNode' - helper

 CSS Dependencies:


 Description:
     Questionnaire events
*/


var setQuestionnaire = {
    currentQ: 0,
    totalQ: 0,
    submitFunction: null,
    keyboardNav: false,
    init: function (elTarget) {

        var me = setQuestionnaire,
            debounceTimer,
            navActive = true;

        elTarget.classList.add('q-in-progress');

        // Disable form submit
        var elSubmitBtn = document.querySelector('.js-q-submit'),
            elSubmitBackBtn = document.querySelector('.js-q-redo');

        if(elSubmitBtn){
            elSubmitBtn.disabled = true;
        }

        if(elSubmitBackBtn){
            elSubmitBackBtn.disabled = true;
        }

        // On focus event
        elTarget.addEventListener('focus', function (e) {
            // Question recieving focus event
            var elBox = e.target.closest('.js-q-item');
            // Current question (which 'should' be recieving the focus events)
            var elQBox = elTarget.querySelectorAll('.js-q-item')[me.currentQ];
            // Show parent box
            if(elBox){
                // this is to prevent duplicate IE11 focus events re-enabling the previous question
                if(elBox.getAttribute('data-question-id') == elQBox.getAttribute('data-question-id')){
                    elBox.classList.remove('q-item-disabled');
                }
            }
        }, true);

        // Disable click as it is being triggered by arrow keys
        elTarget.addEventListener('change', function (e) {
            var elCurrRadio = e.target.closest('.js-q-radio');
            if (!elCurrRadio) return;
            e.preventDefault();
        });

        // Disable change as it is being triggered by arrow keys
        elTarget.addEventListener('click', function (e) {
            var elCurrRadio = e.target.closest('.js-q-radio');
            if (!elCurrRadio) return;
            e.preventDefault();
        });

        // On mouse / touch select event
        var click = ('ontouchstart' in document.documentElement)  ? 'touchstart' : 'mousedown';

        elTarget.addEventListener(click, function (e) {

            // Stop double clicks
            if (!navActive) return;
            navActive = false;
            if(debounceTimer){
                clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(function(){
                navActive = true;
            }, 1000);

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            var elCurrRadio = e.target.closest('.js-q-radio');
            if (!elCurrRadio) return;

            e.preventDefault();

            // if a click / touch
            if(e.button === 0 || click==='touchstart') {
                // select current radio
                elCurrRadio.querySelector('input').checked = true;
                // AJAX save question
                me.saveQuestion(elTarget,elCurrRadio.closest('.q-item'));
                // pause to show change
                setTimeout(function(){ // pause for effect
                    // move to next question
                    me.advanceQuestionPre(elTarget,1);
                },300);
            };

        }, true);

        // when space / return pressed
        elTarget.addEventListener('keydown', function(e) {
            
            // user is using keyboard
            me.keyboardNav = true;
            elTarget.classList.add('q-keyboard-focus');

            if(e.keyCode == 32 || e.keyCode == 13){

                // Stop quick submissions
                if (!navActive) return;
                navActive = false;
                if(debounceTimer){
                    clearTimeout(debounceTimer);
                }
                debounceTimer = setTimeout(function(){
                    navActive = true;
                }, 1000);

                // if a q item is focussed
                var elCurrRadio = document.activeElement;
                //
                if(elCurrRadio && elCurrRadio.type){
                    if(elCurrRadio.type.toLowerCase()==='radio'){
                        // Check for 0 value submissions
                        if(elCurrRadio.value != '' && elCurrRadio.value != null ){
                            e.preventDefault();
                            //select current radio
                            elCurrRadio.checked = true;
                            // AJAX save question
                            me.saveQuestion(elTarget,elCurrRadio.closest('.q-item'));

                            // pause to show change
                            setTimeout(function(){ // pause for effect
                                // move to next question
                                me.advanceQuestionPre(elTarget,1);
                            },300);
                        }
                    }
                }
            }
        });

        // Back button click
        elTarget.addEventListener('click', function (e) {
            var elPrev = e.target.closest('.js-q-prev');

            if (!elPrev) return;

            e.preventDefault();

            me.advanceQuestionPre(elTarget, -1);
        });

        // Next button click
        elTarget.addEventListener('click', function (e) {
            var elNext = e.target.closest('.js-q-next');

            if (!elNext) return;

            e.preventDefault();

            me.advanceQuestionPre(elTarget, 1);
        });

        // Feedback Next button click
        elTarget.addEventListener('click', function (e) {
            var elNext = e.target.closest('.js-q-feedback-button');

            if (!elNext) return;

            if (elNext.disabled) return;

            e.preventDefault();

            me.advanceQuestion(elTarget, 1);
        });


        // Submit Back button click
        elTarget.addEventListener('click', function (e) {
            var elPrev = e.target.closest('.js-q-redo');

            if (!elPrev) return;

            e.preventDefault();

            // disable buttons
            if(elSubmitBtn){
                elSubmitBtn.disabled = true;
            }
            if(elSubmitBackBtn){
                elSubmitBackBtn.disabled = true;
            }

            me.advanceQuestionPre(elTarget, 0);
        });

        // Form submit if there is an ajax submit buton
        // stored as function so we can remove later
        me.submitFunction = function(e){
            e.preventDefault();
            me.submitForm(elTarget);
        }
        //
        elTarget.addEventListener('submit', me.submitFunction);


        // Initial setup
        me.setInitialState(elTarget);

    },

    // initial show / hide / disable
    setInitialState: function(elTarget){
        var me = setQuestionnaire;

        var elQBoxes = elTarget.querySelectorAll('.js-q-item'); // NodeList

        // Set total
        me.totalQ = elQBoxes.length;

        // Set initial states
        if(elQBoxes.length>0){

            forEachNode(elQBoxes, function (i, elQBox) {
                // disable box
                elQBox.classList.add('q-item-disabled');
                //elQBox.offsetHeight; // force reflow
                elQBox.classList.add('q-item-animate'); // we don't want animation on load
                // disable the fieldset
                elQBox.tabIndex = -1;
                // disable all inputs
                var elInputs = elQBox.querySelectorAll('input');
                forEachNode(elInputs, function (i, elQInput) {
                    elQInput.disabled = true;
                    elQInput.tabIndex = -1;
                });
            });

            // Show page at end
            setTimeout(function(){ // pause for effect
                elTarget.classList.add('show-q-items');
                //
                me.initFirstQ(elTarget);
            },200);

        }

    },

    // Init the first question
    initFirstQ: function(elTarget){

        var me = setQuestionnaire;

        // Loop through each question and find the first one that hasn't been answered
        var elQBoxes = elTarget.querySelectorAll('.js-q-item') // NodeList
            elQProgress = document.querySelector('.js-q-progress');  // progress
        var notFound = true;

        // Nodelist for loop so we can break
        for (var i = 0; i < elQBoxes.length; ++i) {
            var checked = elQBoxes[i].querySelector('input:checked');
            if(checked == null){
                // Set state
                me.currentQ = i;

                // Enable block
                me.initQuestion(elTarget, i);

                // Show as found
                notFound = false;

                // Break loop
                break;
            }
        }

        // might be that all questions are answered
        if(notFound){
            // show submit
            elSubmitBtn = document.querySelector('.js-q-submit'),
            elSubmitBackBtn = document.querySelector('.js-q-redo');

            // set state
            me.currentQ = elQBoxes.length-1;

            if(elSubmitBtn){
                elSubmitBtn.disabled = false;
            }
            if(elSubmitBackBtn){
                elSubmitBackBtn.disabled = false;
            }

            // update progress
            setProgress.changeValue(elQProgress, me.totalQ, me.totalQ, true);

            // Scroll to bottom
            var scrollPos = getPosition(elSubmitBtn).y;

            // force positive
            scrollPos = scrollPos > 0 ? scrollPos : 0;

            // Scroll with animation
            //scrollIt(scrollPos, 500, 0);
            ScrollIt({
                pos: scrollPos,
                speed: 400,
                offset: 90
            }).runScroll();
        }

    },

    // Check if we need to show feedback
    advanceQuestionPre: function (elTarget, dir) {
        var me = setQuestionnaire;
        //
        var elQBox = elTarget.querySelectorAll('.js-q-item')[me.currentQ];
        //
        var elFeedback = elQBox.querySelector('.js-q-feedback');
        //
        if(elFeedback){
            // Process feedback content
            var correctAnswer = elQBox.getAttribute('data-answer');
            var selectedAnswer = elQBox.querySelector('input:checked').value;
            var output = (correctAnswer === selectedAnswer) ? '<strong class="text-success">Correct</strong>' : '<strong class="text-danger">Incorrect</strong>';
            //
            var elFeedbackResult = elQBox.querySelector('.js-q-feedback-result')
            if(elFeedbackResult){
                elFeedbackResult.innerHTML = output;
            }

            // Disable radios
            var elRadioRow = elQBox.querySelector('.js-q-radio-row');
            if(elRadioRow){
                elRadioRow.classList.add('disabled');
                elRadioRow.offsetHeight; // force reflow
                elRadioRow.classList.add('animate');
            }

            // Show feedback
            elFeedback.classList.add('show');

            // Fade in feedback
            elFeedback.offsetHeight; // force reflow
            elFeedback.classList.add('animate');

            // Enable / Focus Button
            setTimeout(function(){
                // delay to stop double return press
                var elFeedbackBtn = elFeedback.querySelector('.js-q-feedback-button');
                if(elFeedbackBtn){
                    elFeedbackBtn.disabled = false;
                    elFeedbackBtn.focus();
                }
            }, 200);

        }else{
            // Move to next question
            me.advanceQuestion(elTarget, dir);
        }
    },

    // Enable all Radios for a page POST
    enableAllFields: function (elTarget) {

        var elQBoxes = elTarget.querySelectorAll('.js-q-item'); // NodeList
        //
        if(elQBoxes.length>0){

            forEachNode(elQBoxes, function (i, elQBox) {
                // enable all inputs
                var elInputs = elQBox.querySelectorAll('input');
                forEachNode(elInputs, function (i, elQInput) {
                    elQInput.disabled = false;
                });
            });

        }

    },

    // Move to next / prev question
    advanceQuestion: function (elTarget, dir) {
        var me = setQuestionnaire;
        //
        var nextQ = me.currentQ + dir,
            currentQ = me.currentQ;
        //
        var elQProgress = document.querySelector('.js-q-progress'),
            elSubmitBtn = document.querySelector('.js-q-submit'),
            elSubmitBackBtn = document.querySelector('.js-q-redo');

        // Advance q if possible
        if(nextQ > -1 && nextQ < (me.totalQ)){
            // update state
            me.currentQ += dir;

            // Shut prev question
            me.shutQuestion(elTarget, currentQ);

            // init next question
            me.initQuestion(elTarget, nextQ, currentQ);

        }else{
        // We're at end of questions

            // shut down last q
            me.shutQuestion(elTarget, currentQ);

            // enable buttons
            if(elSubmitBtn){
                elSubmitBtn.disabled = false;
            }
            if(elSubmitBackBtn){
                elSubmitBackBtn.disabled = false;
            }

            // scroll to button
            setTimeout(function(){ // pause for effect
                //scrollIt(elSubmitBtn, 500, 0);
                ScrollIt({
                    pos: elSubmitBtn,
                    speed: 400,
                    offset: 90
                }).runScroll();
            }, 400);

            // update progress
            setProgress.changeValue(elQProgress, me.totalQ, me.totalQ, true);
            // focus button
            setTimeout(function(){ // pause for effect
                if(elSubmitBtn){
                    elSubmitBtn.focus();
                }
            }, 300);
        }

    },

    // Make question ready to interact with
    initQuestion:function (elTarget, currentQ, prevQ) {

        var me = setQuestionnaire;

        var elCurrQBox = elTarget.querySelectorAll('.js-q-item')[currentQ],
            elPrevQBox = elTarget.querySelectorAll('.js-q-item')[prevQ],
            elQProgress = document.querySelector('.js-q-progress');

        // enable the next box
        elCurrQBox.tabIndex = 0;

        // enable all radios in next box
        var elInputs = elCurrQBox.querySelectorAll('input');
        forEachNode(elInputs, function (i, elQInput) {
            elQInput.disabled = false;
            elQInput.tabIndex = 0;
        });

        // trigger q (without scrolling)
        var x = window.pageXOffset,
            y = window.pageYOffset;


        // if we're advancing focus the selected fieldset
        if(!me.keyboardNav){
            elTarget.classList.remove('q-keyboard-focus');
        }else{
            elTarget.classList.add('q-keyboard-focus');
        }

        // focus the next item
        elCurrQBox.focus( { focusVisible: false } );
        
        

        // if we're advancing focus the selected radio
        //var elCheckedRadio = elCurrQBox.querySelector('input:checked');
        // A radio is checked and it isn't the hidden one
        //if (elCheckedRadio && elCheckedRadio.value && elCheckedRadio.value != 0){
            // focus the checked
            //elCheckedRadio.focus();
        //}else{
            // focus the hidden input
            //elInputs[0].focus();
        //}

        //
        window.scrollTo(x, y);

        // Show as active
        elCurrQBox.classList.add('q-item-active');

        // Prev / Next buttons
        if(currentQ>0){
            elCurrQBox.classList.add('show-prev');
        }

        // only show next arrow if already checked
        // Loop through each question and find the first one that hasn't been answered
        var elQBoxes = elCurrQBox.querySelectorAll('input:checked'); // NodeList
        if(elQBoxes && elQBoxes.length>0){
            elCurrQBox.classList.add('show-next');
        }

        //
        // animated scroll to next question
        //
        // Calculate center of viewport
        var heightOffset = (window.innerHeight-elCurrQBox.clientHeight)/2;

        // Calculate scroll position so next item centered
        var scrollPos = getPosition(elCurrQBox).y - heightOffset;

        // force positive
        scrollPos = scrollPos > 0 ? scrollPos : 0;


        // [MINA-674] initial scroll can be disabled on first load so user reads intro text
        if (currentQ === 0 && checkDataAttr(elTarget, 'data-not-scroll-to-first', true)) {
            // Just jump to top of page
            window.scrollTo(0, 0);
        } else{

            // do the scroll
            setTimeout(function(){ // pause for effect
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                // Distance to scroll
                if((scrollPos - scrollTop) < 2000){
                    // Scroll with animation
                    //scrollIt(scrollPos, 500, 0);
                    ScrollIt({
                        pos: scrollPos,
                        speed: 400,
                        offset: 90
                    }).runScroll();
                }else{
                    // Jump scroll
                    window.scroll(0, scrollPos)
                }
            }, 400);

        }

        // update progress
        setProgress.changeValue(elQProgress, me.currentQ, me.totalQ, true);

    },

    // Shut down a question (disable fields)
    shutQuestion: function (elTarget, currentQ) {

        var elQBox = elTarget.querySelectorAll('.js-q-item')[currentQ];
        elQBox.classList.add('q-item-disabled');
        elQBox.classList.remove('q-item-active');

        // Prev / Next buttons
        elQBox.classList.remove('show-prev');
        elQBox.classList.remove('show-next');

        // disable the next box
        elQBox.tabIndex = -1;

        // disable all radios in next box
        var elInputs = elQBox.querySelectorAll('input');
        forEachNode(elInputs, function (i, elQInput) {
            elQInput.disabled = true;
            elQInput.tabIndex = -1;
        });

    },

    // Ajax save question
    saveQuestion: function (elTarget, elQBox) {

        // Get data
        var questionUrl = elTarget.getAttribute('data-q-url'),
            respondentQuestionnaireId = elTarget.getAttribute('data-respondentquestionnaire_id'),
            qId = elQBox.getAttribute('data-question-id'),
            qValue = elQBox.querySelector('input:checked').value;

        // add data to params
        var params = {};
        params.respondentquestionnaire_id = respondentQuestionnaireId;
        params.questionnairequestion_id = qId;
        params.value = qValue;

        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
        var config = {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            }
        }

        // Add csrf token if exists
        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
        var csrf;

        if(elCsrf){
            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
        }

        if(questionUrl){
            // Axios AJAX request
            axios.post(questionUrl, params, config)
                .then(function (response) {
                    if(response.data.status!='success'){
                        console.log('Question save error');
                    }
                })
                .catch(function (error) {
                    if(error.response){
                        console.log('Question save error response: ' + error.response);
                    }
                });
        }else{
            console.log('Questionnaire is missing data-q-url');
        }

    },

    // Submit the whole form
    submitForm: function(elTarget) {

        var me = setQuestionnaire;

        // Get data
        var formUrl = elTarget.getAttribute('data-url'),
            respondentQuestionnaireId = elTarget.getAttribute('data-respondent_questionnaire-id');

        // do we need to check if any fields are empty and show them?

        // thinking
        var elSubmitBtn = document.querySelector('.js-q-submit');
        if(elSubmitBtn){
            pendingButton.show(elSubmitBtn);
        }

        // process data
        /*var elRadios = elTarget.querySelectorAll('input:checked'); // NodeList

        var radioArray = [];

        // Add value of radios to array
        if(elRadios.length>0){

            forEachNode(elRadios, function (i, elRadio) {
                var temp = {};
                temp.questionnairequestion_id = elRadio.name;
                temp.value = elRadio.value;
                //
                radioArray.push(temp);
            });

        }

        // add data to params
        var params = {};
        params.respondent_questionnaire_id = respondentQuestionnaireId;
        params.answers = radioArray
        */

        var errorFunc = function(){
            // refreshing the page should take the user to their first unanswered question
            window.location.href = window.location.href;
        }

        // Serialise all fields (true allows disabled fields)
        var params = serializeArray(elTarget, true);

        // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
        var config = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        }

        // Add csrf token if exists
        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
        var csrf;

        if(elCsrf){
            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
        }

        // Send data
        if(formUrl){
            axios.post(formUrl, params, config)
                .then(function (response) {
                    if(response.data.status=='success'){
                        pendingButton.hide(elSubmitBtn);
                        setTimeout(function(){ // pause for effect
                            // Open popup or page redirect
                            //
                            var redirectUrl = elTarget.getAttribute('data-redirect-url');
                            //
                            if(redirectUrl){
                                window.location.href = redirectUrl;
                            }else{
                                $.magnificPopup.open({
                                    items: {
                                        src: '#q-finished'
                                    },
                                    type: 'inline',
                                    alignTop: true, // Stick to top of page
                                    removalDelay: 500, //delay removal by X to allow out-animation
                                    overflowY: 'scroll',
                                    mainClass: 'mfp-zoom-in',
                                    modal: true
                                });
                            }


                        },600);
                    }else{

                        // bootbox
                        openMpDialog(null, "Sorry there was an error saving your answers. Please press 'OK' to refresh the page and try again.", errorFunc, null, true);

                    }
                })
                .catch(function (error) {
                    if(error.response){
                        console.log('Question save error response: ' + error.response);
                    }else{
                        console.log('Question save error: ' + error);
                    }
                    // bootbox
                    openMpDialog(null, "Sorry there was an error saving your answers. Please press 'OK' to refresh the page and try again.", errorFunc, null, true);
                });
        }else{
            console.log('Form Url missing - Submitting page...');
            // Remove the form submit listener so we don't get a loop
            elTarget.removeEventListener('submit', me.submitFunction);
            // Enable all Radios
            me.enableAllFields(elTarget);
            // Just submit the form anyway
            elTarget.submit();
        }


    }

}

/*!

 File: set-magnific.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:
    jQuery,
    jquery.magnific-popup.js,

 Description:
    Helper functions for displaying Magnific PopUps

*/

// Set an open modal button

var setMpForm = {
    init: function ($target, callbackOpen, callbackClose) {

        // If data attr exists and isn't empty
        var isModal = false;
        if (checkDataAttr($target, 'data-modal')) {
            isModal = true;
        }

        // attach a click event and call 'openMpForm'
        var $thisModal = $($target.attr('href')).first();
        $target.click(function(e){
            e.preventDefault();
            openMpForm($thisModal, isModal, false, callbackOpen, callbackClose);
        });

    },
    destroy: function () {

    }
};

// Open a magnific Form modal (from a js function)
var openMpForm = function($target, isModal, focusInput, callbackOpen, callbackClose){

    var scrollPos = 0;

    $.magnificPopup.open({
        items: {
            src: $target,
            type: 'inline'
        },

        alignTop: true, // Stick to top of page
        preloader: false,
        removalDelay: 500, //delay removal by X to allow out-animation
        overflowY: 'scroll',
        mainClass: 'mfp-slide-up', //'mfp-zoom-in',
        modal: isModal,
        //fixedContentPos: false,
        fixedBgPos: true, // Make sure bg overlay doesn't scroll on mobile

        // When element is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function() {
                if(focusInput){
                    if($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = 'input';
                    }
                }
                // Get current scrollpos
                //scrollPos = $('html').scrollTop();
            },
            open: function(){
                var $content = $(this.content);
                // Close button
                $target.find('.js-mfp-close').unbind('click').click(function(e){
                    e.preventDefault();
                    $.magnificPopup.close();
                });
                // Run Callback function
                if (typeof callbackOpen === 'function'){
                    callbackOpen();
                }
                //
                // BODY SCROLL LOCK
                //var $wrap = $('.mfp-wrap').first();
                //bodyScrollLock.disableBodyScroll($wrap[0]);
            },
            close:function(){
                // BODY SCROLL LOCK
                //bodyScrollLock.enableBodyScroll($target[0]);
                // reset scroll
                //$('html, body').scrollTop( scrollPos );
                // Enable any disabled Buttons
                $target.find('.btn-pending').each(function(){
                    pendingButton.hide($(this));
                });
                // Run Callback function
                if (typeof callbackClose === 'function'){
                    callbackClose();
                }
            }
        }
    });

}


// Open a magnific dialog (Bootbox replacement)
var openMpDialog = function(headline, message, cbConfirm, cbCancel, noCancel, btnText) {

    var classes = 'dialog-modal mfp-centered';
    if(!headline){
        classes += ' dialog-modal-no-headline';
    }
    var btnText = btnText ? btnText : 'OK';
    var dialogHtml = '<div role="dialog" aria-modal="true" tabindex="-1" class="mfp-modal mfp-with-anim">';
        if (headline) {
            dialogHtml += '<div class="modal-header"><h4 class="modal-title">' + headline + '</h4></div>';
        }
        if (message) {
            dialogHtml += '<div class="modal-body"><p>' + message + '</p></div>';
        }
        dialogHtml += '<div class="modal-footer">';
            if(!noCancel){
                dialogHtml += '<button type="button" class="btn btn-white js-btn-cancel">Cancel</button> ';
            }
            dialogHtml += '<button type="button" class="btn btn-primary js-btn-submit">' + btnText + '</button>';
        dialogHtml += '</div>';
    dialogHtml += '</div>';

    // Magnific doesn't support multiple popups, so cancel if one is already open
    // Answer would be to reinstate bootbox bootstrap modals just for these alerts
    if(!$.magnificPopup.instance.isOpen){

        // Open Popup
        $.magnificPopup.open({
            items: {
                src: dialogHtml,
                type: 'inline'
            },

            alignTop: true, // Stick to top of page
            preloader: false,
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-slide-up ' + classes, //'mfp-zoom-in',
            modal: true,

            fixedContentPos: true, // Stop scrolling on mobile
            fixedBgPos: true, // Make sure bg overlay doesn't scroll on mobile

            callbacks: {
                open: function() {
                    var $content = $(this.content);

                    $content.on('click', '.js-btn-submit', function() {
                        // Close popup
                        $.magnificPopup.close();
                        // Remove Key down
                        $(document).off('keydown', keydownHandler);
                        // Run Callback
                        if (typeof cbConfirm == 'function') {
                            setTimeout(function(){
                              cbConfirm();
                            }, 300);
                        }
                    });

                    $content.on('click', '.js-btn-cancel', function() {
                        // Close popup
                        $.magnificPopup.close();
                        // Remove Key down
                        $(document).off('keydown', keydownHandler);
                        // Run Callback
                        if (typeof cbCancel == 'function') {
                            setTimeout(function(){
                              cbCancel();
                            }, 300);
                        }
                    });

                    // Enter / Escape keycodes
                    var keydownHandler = function (e) {
                        if (e.keyCode == 13) {
                            $content.find('.js-btn-submit').click();
                            return false;
                        } else if (e.keyCode == 27) {
                            $content.find('.js-btn-cancel').click();
                            return false;
                        }
                    };

                    $(document).on('keydown', keydownHandler);

                    // Disable body scroll
                    //bodyScrollLock.disableBodyScroll($content[0]);

                },
                close: function() {
                    // Disable any body scroll
                    //bodyScrollLock.clearAllBodyScrollLocks();
                }
            }
        });

    }else{
        // just run callback anyway
        if (typeof cbConfirm == 'function') {
            cbConfirm();
        }
    }

};


// SideBar functions

var setMpSideBar = {
    open: function (elTarget, callbackOpen, callbackClose) {

        var elModal = document.querySelector(elTarget.getAttribute('href'));
        
        if(elModal){

            $.magnificPopup.open({

                items: {
                    src: $(elModal),
                    type: 'inline'
                },
        
                alignTop: true, // Stick to top of page
                preloader: false,
                removalDelay: 500, //delay removal by X to allow out-animation
                overflowY: 'scroll',
                mainClass: 'mfp-slide-right', //'mfp-zoom-in',
                modal: false,
                //fixedContentPos: false,
                fixedBgPos: true, // Make sure bg overlay doesn't scroll on mobile
        
                // When element is focused, some mobile browsers in some cases zoom in
                // It looks not nice, so we disable it:
                callbacks: {
                    open: function(){
                        document.body.classList.add('side-bar-open');
                        // Close button
                        var elClose = elModal.querySelector('.js-side-bar-close');
                        if(elClose){
                            on('click', elClose, function(e){
                                e.preventDefault();
                                $.magnificPopup.close();
                            });
                        }
                        // Run Callback function
                        if (typeof callbackOpen === 'function'){
                            callbackOpen();
                        }
                    },
                    close:function(){
                        document.body.classList.remove('side-bar-open');
                        // Run Callback function
                        if (typeof callbackClose === 'function'){
                            callbackClose();
                        }
                    }
                }
            });

        } else {
            console.log('Missing side bar modal markup')
        }

    }
};

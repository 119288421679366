/*!

 File: set-feelings-gauge-sliders.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Feelings Gauge Activity UI - Sliders
*/

var setFeelingsGaugeSliders = {
    init: function (elTarget) {
        var me = setFeelingsGaugeSliders;

        var elStartBtn = elTarget.querySelector('.js-fg-start-btn');

        var elSliderContent = elTarget.querySelector('.js-fg-slider-content');

        var elContentIntro = elTarget.querySelector('.js-fg-content-intro');


        /* START BUTTON FUNCTIONS */

        var startBtnClick = function(e){
            e.preventDefault();
            // Stop more clicks
            off('click', elStartBtn, startBtnClick);

            // Init Sliders
            me.initSliders(elTarget);

            // fade out button
            elContentIntro.classList.add('animate-hide');
            //
            setTimeout(function(){
                // Remove button from dom
                elContentIntro.parentNode.removeChild(elContentIntro);
                // Fade in new block
                elSliderContent.classList.remove('hidden');
                elSliderContent.offsetHeight; // force reflow
                elSliderContent.classList.remove('animate-hide');
            },200);
        }

        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr(elTarget, 'data-init-sliders', true)) {
            // Init Sliders
            me.initSliders(elTarget);
        }

        if(elStartBtn){
            on('click', elStartBtn, startBtnClick);
        }  

    },

    initSliders: function (elTarget) {
        var me = setFeelingsGaugeSliders;

        var elSliders = elTarget.querySelectorAll('.js-fg-slider-item');
        var elSliderInputs = elTarget.querySelectorAll('.js-fg-sliders-item-input');

        var sliderResults = [0,0,0,0];
        var sliderResultsProcessed = [0,0,0,0];

        // Get Index of min value in array
        function indexOfMin(arr) {

            if (arr.length === 0) {
                return -1;
            }

            var min = 100; // start with a high number
            var minIndex = 0;

            for (var j = 1; j < arr.length; j++) {
                // check that number isn't zero and is lower than previous
                if (arr[j] < min && arr[j] > 0) {
                    minIndex = j;
                    min = arr[j];
                }
            }

            return minIndex;
        }


        // Add all values together in array
        var getArrayTotal = function(array){
            var total = 0;
            // could use reduce here but loop is faster / easier to read
            for (var i = 0, len = array.length; i < len; i++) {
                total += parseFloat(array[i]);
            }
            //
            return total;
        }

        // Take results array and get percentages across all
        var processResults = function(){

            // Get total
            var total = getArrayTotal(sliderResults);

            var len = sliderResults.length;

            // Loop through results array
            for (var i = 0; i < len; i++) {
                if(total>0){
                    if(sliderResults[i] != total){
                        // Multiple sliders have been dragged
                        sliderResultsProcessed[i] = Math.floor((sliderResults[i]/total) * 100);
                    } else {
                        // Only one slider has been dragged
                        sliderResultsProcessed[i] = sliderResults[i];
                    }
                }else{
                    sliderResultsProcessed[i] = 0;
                }
            }

            var remainder = 100 - getArrayTotal(sliderResultsProcessed);


            // second part of loop should exclude the first dragged slider
            if (remainder>0 && remainder+total != 100) {
                // Loop through every remainder
                for (var j = 1; j < remainder+1; j++) {
                    // Find the lowest value in the array (which isn't zero)
                    var lowestIndex = indexOfMin(sliderResultsProcessed);
                    // ...and add 1 to it
                    sliderResultsProcessed[lowestIndex] = sliderResultsProcessed[lowestIndex] + 1;
                }
            }

            for (var k = 0; k < len; k++) {
                // put values into inputs
                elSliderInputs[k].value = sliderResultsProcessed[k];
            }

        }

        var sliderChangeCallback = function(){
            var elFinishBtn = elTarget.querySelector('.js-fg-finish-btn');
            if(elFinishBtn){
                elFinishBtn.classList.remove('disabled');
            }
        }

        var sliderMoveCallback = function(el){
            // Which slider am I?
            var sliderIndex = parseInt(el.getAttribute('data-slider-index')) - 1;
            // Set relavant result in array
            sliderResults[sliderIndex] = Math.round(parseInt(el.value));
            // Process everything in array
            processResults();
        }


        if(elSliders && elSliders.length>0){
            forEachNode(elSliders, function (i, el) {
                var elSlider = el.querySelector('.js-fg-range-slider');
                var range = new RangeSlider;
                range.init({
                    el: elSlider,
                    moveCallBack: sliderMoveCallback,
                    changeCallBack: sliderChangeCallback
                });
            });
        }

    }

}
/*!

 File: set-mindset-grid.js
 Author: Ember
 Version: 1.0.3 [MINA-764] - Button text in span to account for icon
 JS Dependencies:
    helper-on

 CSS Dependencies:
    _site-truncated-block.scss

 Description:
     Show / Hide mindset grid marker
*/

var setMindsetGrid = {
    init: function (elTarget) {

        var disabled = false;

        // Button click
        buttonClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            var elBtn = e.target.closest('.js-mindset-grid-btn');

            // Closest captures self as well a parents
            if (!elBtn) return;

            // Button clicked
            e.preventDefault();

            // Remove focus (shouldn't really do this)
            elBtn.blur();

            var elBtnSpan = elBtn.querySelector('span');

            // Open or close
            var showText = elBtn.getAttribute('data-show-text'),
                hideText = elBtn.getAttribute('data-hide-text');

            if(!disabled){
                disabled = true;
                //
                if(!elTarget.classList.contains('show-marker')){
                    if(hideText){
                        if(elBtnSpan){
                            elBtnSpan.innerText = hideText;
                        }else{
                            elBtn.innerText = hideText;
                        }
                    }
                    elTarget.classList.add('show-marker');
                    setTimeout(function(){
                        elTarget.classList.add('show-arrow');
                        disabled = false;
                    }, 50);
                }else{
                    if(showText){
                        if(elBtnSpan){
                            elBtnSpan.innerText = showText;
                        }else{
                            elBtn.innerText = showText;
                        }
                    }
                    elTarget.classList.remove('show-arrow');
                    setTimeout(function(){
                        elTarget.classList.remove('show-marker');
                        disabled = false;
                    }, 50);
                }
            }


            //console.log('click');

            /*if(document.body.classList.contains('report-nav-open')){
                me.closeNav();
                // remove close outside eventHandler
                off('click', document, me.dropOutClick);
            }else{
                me.openNav();
                // set close outside eventHandler
                on('click', document, me.dropOutClick);
            }*/
        };

        // attach event
        on('click', elTarget, buttonClick);

        /*var me = setTruncatedBlock,
            elBtns = elTarget.querySelectorAll('.js-truncated-block-btn'),
            elBlock = elTarget.querySelector('.js-truncated-block-inner'),
            timer,
            animSpeed = 400;

        // Need to move slower if tall
        if(elBlock.classList.contains('truncated-block-tall')){
            animSpeed = 800;
        }

        var getHeight = function(){
            // Get height of element (without max-height)
            var clone = elBlock.cloneNode(true);
            clone.style.cssText = 'visibility: hidden; display: block; margin: -999px 0; max-height:none;';
            var height = (elTarget.parentNode.appendChild(clone)).clientHeight;
            elTarget.parentNode.removeChild(clone);
            //height=520;
            //
            return height;
        }

        function btnClick(e) {
            //
            clearTimeout(timer);
            //
            var elBtn = e.target;

            if (!elTarget.classList.contains('animating')) {
                // Open Block
                elBlock.style.maxHeight = getHeight()+'px';
                //
                elTarget.classList.add('animating');
                // set button text
                setText('less');
                //
                timer = setTimeout(function(){
                    elTarget.classList.add('active');
                    //
                    elBlock.style.maxHeight = 'none';
                    // run open callback
                    if (typeof openCallback === 'function'){
                        openCallback();
                    }
                },animSpeed);
            }else{
                // Close Block
                // reset height so we can animate
                elBlock.style.maxHeight = getHeight()+'px';
                //
                elTarget.classList.remove('active');
                elTarget.offsetHeight; // force reflow
                elBlock.style.removeProperty('max-height');
                elTarget.classList.remove('animating');
                // set button text
                setText('more');
                // run close callback
                timer = setTimeout(function(){
                    if (typeof closeCallback === 'function'){
                        closeCallback();
                    }
                },animSpeed);
            }
        }

        // Go through all buttons and see if they need text changing
        function setText(type){
            forEachNode(elBtns, function (i, elBtn) {
                // Truncated blocks might be nested, btn queryselectorall will get all btns
                if(elTarget === elBtn.closest(selector)){
                    var lessText = elBtn.getAttribute('data-less-text'),
                        moreText = elBtn.getAttribute('data-more-text'),
                        lessClass = elBtn.getAttribute('data-less-class'),
                        moreClass = elBtn.getAttribute('data-more-class');

                    // Put text into span if it exists
                    var elBtnText;
                    elBtnText = elBtn.querySelector('.js-truncated-block-btn-text');
                    if(!elBtnText){
                        elBtnText = elBtn;
                    }

                    //
                    if(type==='more'){
                        if(moreText){
                            // delay to allow open anim
                            //timer = setTimeout(function(){
                                elBtnText.textContent = moreText;
                            //},400);
                        }
                    }else{
                        if(lessText){
                            elBtnText.textContent = lessText;
                        }
                    }
                }
            });
        }*/


    }
}

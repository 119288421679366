/*!

 File: wrap-inner.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:

 Description:
    Helper function for replacing jQuery's wrapInner



innerwrap an HTML element with the option to add an attribute & value with it.
Derived from:
http://stackoverflow.com/questions/21817538/how-can-i-implement-jquerys-wrapinner-function-in-plain-javascript

 Usage
 ------
 Four parameters you can use:
 'parent'           = the element that needs to be innerwrapped
 'wrapper'          = the element that will innerwrap the parent
 'attribute'        = the attribute that you need this innerwrapped element to have
 'attributevalue'   = the value of the attribute you've just created

 Example
 -------
 wrapInner('body', 'div', 'class', 'my-class');

*/

function wrapInner(parent, wrapper, attribute, attributevalue) {
    if (typeof wrapper === "string") {
        wrapper = document.createElement(wrapper);
    }
    var div = parent.appendChild(wrapper)
              .setAttribute(attribute, attributevalue);

    while (parent.firstChild !== wrapper) {
           wrapper.appendChild(parent.firstChild);
      }
}

/*!

 File: set-feelings-gauge-sliders.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Force user to open all feelings gauge modals before advancing 
*/

var setFeelingsGuageForceModal = {
    init: function (elTarget) {

        var elStartBtn = elTarget.querySelector('.js-fg-start-btn');

        // Before modal pop up click
        var modalClickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-fg-modal-btn')) return;

            // Button clicked
            e.preventDefault();

            el.classList.add('js-fg-modal-btn-clicked');

            var elModalBtnsClicked = elTarget.querySelectorAll('.js-fg-modal-btn-clicked');

            if(elModalBtnsClicked.length >= 4){
                /* INITIALISE START BUTTON */
                if(elStartBtn){
                    elStartBtn.classList.remove('disabled');
                }else{
                    console.log('setFeelingsGuageForceModal - missing .js-fg-start-btn element');
                }
            }
        }

        var elModalBtns = elTarget.querySelectorAll('.js-fg-modal-btn');
        
        if(elModalBtns && elModalBtns.length > 0){
            on('click', document, modalClickEvt);
        }

    }

}

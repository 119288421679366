/*!

 File: set-pwd-show.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'helper-on' - helper,

 Description:
    Show password input on click

*/

var setPwdShow = {
    init: function (elTarget) {

        var me = setPwdShow;

        var elInput = elTarget.querySelector('.js-show-pwd-input');

        var buttonClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            var elBtn = e.target.closest('.js-show-pwd-btn');

            // Closest captures self as well a parents
            if (!elBtn) return;

            // Button clicked
            e.preventDefault();

            if(elInput){

                if(elInput.type == 'password'){
                    me.showPwd(elTarget, elInput);
                }else{
                    me.hidePwd(elTarget, elInput);
                }

            }

        }

        // attach event
        on('click', elTarget, buttonClick);

    },
    showPwd: function (elTarget, elInput) {
        if(!elInput){
            var elInput = elTarget.querySelector('.js-show-pwd-input');
        }
        if(elInput){
            // Show the password
            elInput.type = 'text';
            // Change icon
            elTarget.classList.add('pwd-visible');
        }
    },
    hidePwd: function (elTarget, elInput) {
        if(!elInput){
            var elInput = elTarget.querySelector('.js-show-pwd-input');
        }
        if(elInput){
            // Hide the password
            elInput.type = 'password';
            // Change icon
            elTarget.classList.remove('pwd-visible');
        }

    }
}

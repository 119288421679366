/*!

 File: set-truncated-block.js
 Author: Ember
 Version: 1.0.6 [MS-882] - clone bug
 JS Dependencies:
    helper-on

 CSS Dependencies:
    _site-truncated-block.scss

 Description:
     Show more block
*/

var setTruncatedBlock = {
    init: function (elTarget, selector, openCallback, closeCallback) {

        var me = setTruncatedBlock,
            elBtns = elTarget.querySelectorAll('.js-truncated-block-btn'),
            elBlock = elTarget.querySelector('.js-truncated-block-inner'),
            timer,
            animSpeed = 400;

        // Need to move slower if tall
        if(elBlock.classList.contains('truncated-block-tall')){
            animSpeed = 800;
        }

        // allows data-attribute and data-attribute="true"  otherwise returns false
        var scrollTo = false;
        if (checkDataAttr(elTarget, 'data-scroll-to', true)) {
            scrollTo = true;
        }

        var getHeight = function(){
            // Get height of element (without max-height)
            var clone = elBlock.cloneNode(true);
            clone.style.cssText = 'visibility: hidden; display: block; margin: -999px 0; max-height:none;';

            // Getting issues with checkboxes unchecking their original when cloned
            // remove all for's
            var elLabels = clone.querySelectorAll('label');
            if(elLabels && elLabels.length>0){
                forEachNode(elLabels, function (i, el) {
                    el.removeAttribute('for');
                });
            }
            
            // remove all id's
            var elInputs = clone.querySelectorAll('input');
            if(elInputs && elInputs.length>0){
                forEachNode(elInputs, function (i, el) {
                    el.removeAttribute('id');
                    el.classList.remove('js-ajax-update'); // Just In case
                    // uncheck everything
                    el.checked = false;
                });
            }

            var height = (elTarget.parentNode.appendChild(clone)).clientHeight;
            elTarget.parentNode.removeChild(clone);
            //height=520;
            //
            return height;
        }

        function btnClick(e) {
            //
            clearTimeout(timer);
            //
            var elBtn = e.target;

            if (!elTarget.classList.contains('animating')) {
                // Open Block
                elBlock.style.maxHeight = getHeight()+'px';
                //
                elTarget.classList.add('animating');
                // set button text
                setText('less');
                
                //
                timer = setTimeout(function(){
                    elTarget.classList.add('active');
                    //
                    elBlock.style.maxHeight = 'none';
                    // run open callback
                    if (typeof openCallback === 'function'){
                        openCallback();
                    }
                    //
                    // Scroll to element
                    if(scrollTo){
                        ScrollIt({
                            pos: elTarget,
                            speed: 400,
                            offset: 90
                        }).runScroll();
                    }

                },animSpeed);
            }else{
                // Close Block
                // reset height so we can animate
                elBlock.style.maxHeight = getHeight()+'px';
                //
                elTarget.classList.remove('active');
                elTarget.offsetHeight; // force reflow
                elBlock.style.removeProperty('max-height');
                elTarget.classList.remove('animating');
                // set button text
                setText('more');
                // run close callback
                timer = setTimeout(function(){
                    if (typeof closeCallback === 'function'){
                        closeCallback();
                    }
                },animSpeed);
            }
        }

        // Go through all buttons and see if they need text changing
        function setText(type){
            forEachNode(elBtns, function (i, elBtn) {
                // Truncated blocks might be nested, btn queryselectorall will get all btns
                if(elTarget === elBtn.closest(selector)){
                    var lessText = elBtn.getAttribute('data-less-text'),
                        moreText = elBtn.getAttribute('data-more-text'),
                        lessClass = elBtn.getAttribute('data-less-class'),
                        moreClass = elBtn.getAttribute('data-more-class');

                    // Put text into span if it exists
                    var elBtnText;
                    elBtnText = elBtn.querySelector('.js-truncated-block-btn-text');
                    if(!elBtnText){
                        elBtnText = elBtn;
                    }

                    //
                    if(type==='more'){
                        if(moreText){
                            // delay to allow open anim
                            //timer = setTimeout(function(){
                                elBtnText.textContent = moreText;
                            //},400);
                        }
                    }else{
                        if(lessText){
                            elBtnText.textContent = lessText;
                        }
                    }
                }
            });
        }

        /* EVENTS */
        forEachNode(elBtns, function (i, elBtn) {
            // Truncated blocks might be nested, btn queryselectorall will get all btns
            if(elTarget === elBtn.closest(selector)){
                on('click', elBtn, function(e){
                    e.preventDefault();
                    //
                    elBtn.blur();
                    //
                    btnClick(e);
                });
            }
        });


    }
}

/*!

 File: set-dev-hover-content.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'closest' polyfill
    'on' helper

 CSS Dependencies:

 Description:
     Show some overlay content when hovering an element - to aid developer debugging
*/

var setDevHoverContent = {
    init: function () {

        var elCurrent;

        var contentOver = function(e){
            if (!e.target.closest('.js-dev-hover-content')) return;
            elCurrent = e.target.closest('.js-dev-hover-content');
            elCurrent.classList.add('dev-content-active');
            //
            on('click', document, contentOut);
        };

        var contentOut = function(e){
            console.log('contentOut')
            off('click', document, contentOut);
            elCurrent.classList.remove('dev-content-active');
            e.preventDefault();
        };

        on('mouseover', document, contentOver);
        //on('mouseout', document, contentOut);

    }
}

/*!

 File: set-mindset-grid-activity.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Mindset Grid Activity UI
*/

var setMindsetGridActivity = {
    init: function (elTarget) {
        var me = setMindsetGridActivity;

        var elDragBlocks = elTarget.querySelectorAll('.js-ms-grid-drag');

        var dragRefs = [];

        var elInputs = elTarget.querySelectorAll('.js-ms-grid-drag-output');
        var elStartBtn = elTarget.querySelector('.js-ms-grid-activity-start-btn');

        var checkDragged = function(){
            if(elInputs && elInputs.length>0){
                var total = 4;
                var inc = 0;
                forEachNode(elInputs, function (i, el) {
                    if(el.value != ''){
                        inc++;
                    }
                });
                if(inc>total-1){
                    if(elStartBtn){
                        elStartBtn.classList.remove('disabled');
                        elStartBtn.disabled = false;
                    }
                }
            }
        }

        if(elDragBlocks && elDragBlocks.length>0){
            forEachNode(elDragBlocks, function (i, el) {

                requestAnimationFrame(function(){
                    var dragVar = new MindsetGridDrag;

                    // Initialise drag
                    dragVar.init({
                        el: el,
                        callBack: checkDragged
                    });
    
                    // save refs for later
                    dragRefs.push(dragVar);
                });
                
                
            });
        }

    },


}

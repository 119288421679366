/*!

 File: set-lazy-load.js
 Author: Ember
 Version: 1.0.2
 JS Dependencies:

 CSS Dependencies:

 Description:
    Lazy load images when in view
*/


var setImgLazyLoad = {
    init: function (elTarget, callback) {
        var imgSrc = elTarget.getAttribute('data-src'),
            imgSrcSet = elTarget.getAttribute('data-src-set');

        if(imgSrc){

            elTarget.setAttribute('src', imgSrc);

            if(imgSrcSet){
                elTarget.setAttribute('srcset', imgSrcSet);
            }

            elTarget.addEventListener('load', function onImageLoaded() {
        		elTarget.removeEventListener('load', onImageLoaded);
                // Show as loaded
                elTarget.classList.add('is-image-loaded');
            });

        }
    }
}


// ============================================== TRIGGER A LAZY LOAD WHEN IN VIEW PORT //
var setLazyLoadInView = {
    init: function (elTarget, callback) {

        var targetWatcher = scrollMonitor.create( elTarget, {top: 200} );

        targetWatcher.enterViewport(function() {
            setImgLazyLoad.init(elTarget);
            // remove watcher
            targetWatcher.destroy();
        });

    }
}

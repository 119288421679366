/*!

 File: set-card-sorter-activity.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Feelings Gauge Activity UI
*/

var setCardSorterActivity = {
    init: function (elTarget) {

        var me = setCardSorterActivity;

        /* ELEMENTS */

        var elCardStack = elTarget.querySelector('.js-emotion-card-stack');
        var elCardStackWrapper = elTarget.querySelector('.js-card-sorter-activity-content');

        var elContentOutro = elTarget.querySelector('.js-card-sorter-content-outro');
        var elFinishBtn = elTarget.querySelector('.js-card-sorter-finish-btn');

        var elForm = elTarget.querySelector('.js-card-sorter-form');

        var cardStack = new CardStack;

        /* BUTTON FUNCTIONS */

        var btnClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-card-sorter-btn')) return;

            // Button clicked
            e.preventDefault();

            // Get direction from button
            var btnDirection = el.getAttribute('data-direction');
            var btnColour = el.getAttribute('data-colour');
            var btnId = el.getAttribute('data-id');


            // Make sure don't double click
            if(!elCardStack.classList.contains('is-animating')){
                // Update card stack
                cardStack.removeCard({
                    cardDirection: btnDirection,
                    cardColour: btnColour,
                    clickId: btnId
                });

            }

        }


        on('click', elTarget, btnClick);

        /* CARD STACK FUNCTIONS */

        var cardsInitCallbackFunc = function(){
        };

        var lastCardCallbackFunc = function(){
            // Fade out card area
            elCardStackWrapper.classList.add('animate-hide');
            //
            setTimeout(function(){
                // Remove from flow
                elCardStackWrapper.classList.add('hidden');
                // Enable submit button
                elFinishBtn.classList.remove('disabled');
                elFinishBtn.disabled = false;
                // Show finish content
                elContentOutro.classList.remove('hidden');
                // Show
                elContentOutro.offsetHeight; // force reflow
                elContentOutro.classList.remove('animate-hide');
                //
            }, 200);

        };


        // Initialise stack
        cardStack.init({
            el: elCardStack,
            cardsInitCallback: cardsInitCallbackFunc,
            lastCardCallback: lastCardCallbackFunc
        });

    },


}

/*!

 File: set-file-upload-async.js
 Author: Ember
 Version: 1.0.4 - [MMP-48] - CSRF token
 JS Dependencies:
    'dropzone' - plugin

 CSS Dependencies:

 Description:
    File upload UI
*/


;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.FileUploadAsync = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var FileUploadAsync = (function () {

        var Constructor = function () {

            //
    		// Variables
            //
            var publicAPIs = {};
            var settings = {};

            var elUploadDz,
                elUploadDzWrapper,
                elUploadButton,
                elActionsBlock,
                elUpdatesBlock,
                elProgressBar,
                elResponseBlock,
                elResponseMarkupBlock,
                elResponseValidate,
                elUploadBtn,
                elError,
                errorMessageDefault;

            var myDropzone,
                updateUrl,
                thisParamName;


            //
    		// Methods
            //
            
            /**
    		* Private methods
            */

            var initUploader = function(elTarget){

                elUploadDz = elTarget.querySelector('.js-async-file-upload-dz');
                elUploadDzWrapper = elTarget.querySelector('.js-async-file-upload-dz-wrapper');
                elUploadButton = elTarget.querySelector('.js-async-file-upload-btn');
                elActionsBlock = elTarget.querySelector('.js-async-file-upload-actions');
                elUpdatesBlock = elTarget.querySelector('.js-async-file-upload-update');
                elProgressBar = elTarget.querySelector('.js-progress-bar');
                elResponseBlock = elTarget.querySelector('.js-async-file-upload-response');
                elResponseMarkupBlock = elTarget.querySelector('.js-async-file-upload-response-markup');
                elResponseValidate = elTarget.querySelector('.js-async-file-upload-validate');
                elUploadBtn = elTarget.querySelector('.js-async-file-upload-btn');
                elError = elTarget.querySelector('.js-async-file-upload-error');
                errorMessageDefault = 'Sorry, there was a problem with your request.';
                // Get upload URL
                updateUrl = elTarget.getAttribute('data-update-url');

                // Get param name
                thisParamName = 'file';
                // If data attr exists and isn't empty
                if (checkDataAttr(elTarget, 'data-name')) {
                    thisParamName = elTarget.getAttribute('data-name');
                }

                // remove item click
                on('click', elTarget, removeBtnClick);

                // Initialise Dropzone
                if(updateUrl && elUploadDz){
                    initDropzone(elTarget)
                }
            }


            // Remove file button
            var removeBtnClick = function(e){

                // Don't run if right-click or command/control + click
                if (e.button && e.button !== 0) return;

                // Element within link could have been clicked
                // Closest captures self as well a parents
                if (!e.target.closest('.js-async-file-upload-remove')) return;

                // Button clicked
                e.preventDefault();

                // confirm removal
                var title = 'Remove file';
                var message = 'Are you sure you want to remove this file?'
                openMpDialog(title, message, function(){
                    
                    // fade out content
                    elResponseBlock.classList.add('animate-hide');
                    setTimeout(function(){ 
                        // hide content
                        elResponseBlock.classList.add('hidden');
                        // reset uploader
                        resetUploader();
                        //
                        // remove elements
                        if(elResponseBlock){
                            elResponseBlock.innerHTML = '';
                        }
                        if(elResponseMarkupBlock){
                            elResponseMarkupBlock.innerHTML = '';
                        }
                        if(elResponseValidate){
                            elResponseValidate.value = '';
                        }
                        // fade in buttons
                        elUploadDzWrapper.classList.add('animate-hide');
                        elUploadDzWrapper.classList.remove('hidden');
                        elUploadDzWrapper.offsetHeight; // force reflow
                        elUploadDzWrapper.classList.remove('animate-hide');

                    }, 400);
                   
                }, null, null, null);

            }

            // Reset uploader
            var resetUploader = function(){
                myDropzone.removeAllFiles(true);
                // Enable upload button
                elUploadButton.disabled = false;
                // Show Uploader
                elActionsBlock.classList.remove('hidden');
                // hide update
                elUpdatesBlock.classList.add('hidden');
                // Reset update
                elProgressBar.style.width = 0;
                // remove markup
                if(elResponseBlock){
                    elResponseBlock.innerHTML = '';
                }
            }

            // Initialise Dropzone
            var initDropzone = function(elTarget){

                var acceptedFiles = elTarget.getAttribute('data-accepted-files');
                var maxFilesize = elTarget.getAttribute('data-max-file-size');

                var config = {
                    headers: {
                    }
                }

                var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
                var csrf;

                if(elCsrf){
                    config.headers['X-CSRF-TOKEN'] = elCsrf.content;
                }
                
                // Init dropzone
                myDropzone = new Dropzone(elUploadDz, {
                    url: updateUrl, // Set the url
                    // acceptedFiles: 'image/*,application/pdf,.psd', // Accepted files by mime type
                    // maxFilesize: 100, // Max file size in MB
                    paramName: thisParamName, // The name that will be used to transfer the file
                    uploadMultiple: false, // single upload
                    maxFiles: 1, // single upload
                    //autoProcessQueue: false, // Make sure the files aren't queued until manually added
                    previewTemplate: '<div style="display:none"></div>', // We'll make our own previews
                    previewsContainer: null, // We'll make our own previews
                    createImageThumbnails: false, // We'll make our own previews
                    clickable: elUploadBtn, // Define the element that should be used as click trigger to select files.
                    maxFilesize: maxFilesize,
                    acceptedFiles: acceptedFiles,
                    headers: config.headers
                });

                // Dropzone File selected event
                myDropzone.on('addedfile', function (file) {
                    // reset error
                    elError.innerText = '';
                    elError.classList.add('hidden');
                    // Disable upload button
                    elUploadButton.disabled = true;
                    // Hide Uploader
                    elActionsBlock.classList.add('hidden');
                    // Reset update
                    elProgressBar.style.width = 0;
                    // Show update
                    elUpdatesBlock.classList.remove('hidden');
                    // Start uploading (doesn't work anyway)
                    // myDropzone.processQueue();
                });

                // Sending
                myDropzone.on('sending', function (file, xhr, formData) {


                    //Add any extra data found in the file element into the formData submitted.
                    var extraData = elTarget.getAttribute('data-extra');
                    if(extraData && extraData != ''){
                        extraData = JSON.parse(extraData);
                        for (var i in extraData) {
                            formData.append(i, extraData[i]);
                            //console.log(i + ' : ' +extraData[i]);
                        }
                    }

                });

                // Update the progress bar
                myDropzone.on('uploadprogress', function (file, progress, bytesSent) {
                    elProgressBar.style.width = (progress + '%');
                });

                // Response from server
                myDropzone.on('success', function (file, responseJson) {

                    var response = $.parseJSON(responseJson);

                    // Returned Success
                    if(response.status=='success'){

                        // Validate field
                        if(elResponseValidate){
                            elResponseValidate.value = response['data'].path;
                            // trigger validation update
                            elResponseValidate.focus();
                            elResponseValidate.blur();
                        }

                        // place any returned markup
                        if(response.html && response.html != ''){
                            if(elResponseMarkupBlock){
                                elResponseMarkupBlock.innerHTML = response.html;
                            }else{
                                console.log('setFileUploadAsync: missing .js-async-file-upload-response-markup element');
                            }
                        }

                        if(elResponseBlock){

                            setTimeout(function(){ 
                                // fade out buttons / progress
                                elUploadDzWrapper.classList.add('animate-hide');
                            }, 800);

                            setTimeout(function(){ 
                                // hide buttons / progress
                                elUploadDzWrapper.classList.add('hidden');
                                elUploadDzWrapper.classList.remove('animate-hide');
                                // add returned element
                                var templateContent = processThumb(response['data']);
                                elResponseBlock.innerHTML = templateContent;

                                // Initialise video
                                var elVideo = elResponseBlock.querySelector('.video-js');
                                if(elVideo){ 
                                    videojs(elVideo, {}, function(){
                                        this.load();
                                    });
                                }
                                // fade in content
                                elResponseBlock.classList.add('animate-hide');
                                elResponseBlock.classList.remove('hidden');
                                elResponseBlock.offsetHeight; // force reflow
                                elResponseBlock.classList.remove('animate-hide'); 
                                
                            }, 1000);
                        
                        }else{
                            console.log('setFileUploadAsync: missing .js-async-file-upload-response element');
                        }

                    }

                    // Returned Error
                    if(response.status=='error'){
                        // See if a message was returned
                        if(response.message && response.message.text && response.message.text != ''){
                            errorMessageDefault = response.message.text;
                        }
                        // reset uploader
                        resetUploader();
                        // show error
                        elError.innerText = 'Error: ' + errorMessageDefault;
                        elError.classList.remove('hidden');
                    }
                });

                myDropzone.on('error', function (file, errorMessage, xhr) {
                    // reset uploader
                    resetUploader();
                    // show error
                    elError.innerText = 'Error: ' + errorMessage;
                    elError.classList.remove('hidden');
                });

            }

            // Return markup for thumbnail
            var processThumb = function(data){

                var imgTemplate = '<div class="async-file-upload-item">\
                        <div class="async-file-upload-item-img">\
                            <img src="{path}" alt="" />\
                            <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        </div>\
                        <p class="text-smaller">{filename}</p>\
                    </div>';

                var fileTemplate = '<div class="async-file-upload-item">\
                        <div class="async-file-upload-item-icon">\
                            <div class="file-icon">\
                                <img src="/assets/front/dist/img/icon-doc-{suffix}.svg" alt="{suffix} Icon">\
                            </div>\
                            <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        </div>\
                        <p class="text-smaller">{filename}</p>\
                    </div>';

                var videoTemplate = '<div class="async-file-upload-item">\
                        <div class="async-file-upload-item-video">\
                            <div class="ms-video-player report-video-player vjs-big-play-centered">\
                                <video id="my-video" class="video-js vjs-16-9" controls="" preload="auto" data-setup="{}" width="640" height="360">\
                                    <source src="{path}" type="video/mp4">\
                                    <p class="vjs-no-js">\
                                        To view this video please enable JavaScript, and consider upgrading to a web browser that\
                                        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>\
                                    </p>\
                                </video>\
                            </div>\
                            <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        </div>\
                        <p class="text-smaller">{filename}</p>\
                    </div>';

                var audioTemplate = '<div class="async-file-upload-item">\
                        <div class="async-file-upload-item-audio">\
                            <div class="ms-audio-player report-audio-player">\
                                <audio id="my-audio" class="video-js" controls\
                                    preload="auto"\
                                    data-setup=\'{"aspectRatio": "1:0","fluid": true,"controlBar": {"fullscreenToggle": false, "pictureInPictureToggle": false}}\'>\
                                    <source src="{path}" type="audio/mp3"/>\
                                </audio>\
                            </div>\
                            <button type="button" class="async-file-upload-item-del js-async-file-upload-remove">Remove this item</button>\
                        </div>\
                        <p class="text-smaller">{filename}</p>\
                    </div>'

                var fileType = setFileSuffix(data.extension);
                if(fileType === undefined){
                    fileType = setFileSuffix(data.name);
                }
                if(fileType === undefined){
                    fileType = setFileSuffix(data.type);
                }
                var template;

                var downloadLink = (data.path && data.path != '') ? '<a href="{path}" target="_blank" download>' + data.name + '</a>' : data.name;

                if(fileType == 'img') {
                    template = imgTemplate;
                    template = template.replaceAll('{path}', data.path);
                    template = template.replaceAll('{filename}', downloadLink);
                }

                if(fileType == 'pdf' || fileType == 'doc' || fileType == 'xcl' || fileType == 'ppt') {
                    template = fileTemplate;
                    template = template.replaceAll('{path}', data.path);
                    template = template.replaceAll('{filename}', downloadLink);
                    template = template.replaceAll('{suffix}', fileType);
                }

                if(fileType == 'mov') {
                    template = videoTemplate;
                    template = template.replaceAll('{path}', data.path);
                    template = template.replaceAll('{filename}', downloadLink);
                }

                if(fileType == 'audio') {
                    template = audioTemplate;
                    template = template.replaceAll('{path}', data.path);
                    template = template.replaceAll('{filename}', downloadLink);
                }
                
                return template;

            }

            // determine the filetype
            var setFileSuffix = function (filename) {

                if(filename){
            
                    var fileExtension = filename.substring(filename.lastIndexOf('.') + 1);
            
                    fileExtension = fileExtension.toLowerCase();

                    // check for back slash escapes
                    fileExtension = fileExtension.replace(/\\\//g, "/");
            
                    var extension;
            
                    switch (fileExtension) {
                        case 'pdf':
                            extension = 'pdf';
                            break;
                        case 'doc':
                        case 'docx':
                            extension = 'doc';
                            break;
                        case 'xls':
                        case 'xlsx':
                            extension = 'xcl';
                            break;
                        case 'ppt':
                        case 'pptx':
                            extension = 'ppt';
                            break;
                        case 'jpg':
                        case 'jpeg':
                        case 'gif':
                        case 'png':
                        case 'image/jpeg':
                        case 'image/png':
                            extension = 'img';
                            break;
                        case 'mov':
                        case 'mp4':
                        case 'webm':
                        case 'ogg':
                        case 'video/mpeg':
                            extension = 'mov';
                            break;
                        case 'mp3':
                        case 'aiff':
                        case 'wav':
                        case 'ogg':
                        case 'audio/mpeg':
                            extension = 'audio';
                            break;
                        default:
                            undefined
                    }
                    //
                    return extension;
                }else{
                    return false;
                }
                
            }
            

            /**
            * Public methods
            */

            publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;

                if(settings.el){
                    initUploader(settings.el);
                }else{
                    console.log('set-file-upload-async.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

            };

            //
    		// Return the Public APIs
    		//

    		return publicAPIs;

        };

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the FileUploadAsync function to be assigned to the window object/module
    return FileUploadAsync;

});


/*!

 File: set-checklist-exercise.js.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Participant Checklist Exercise UI
*/

var setChecklistExercise = {
    init: function (elTarget) {

        var elNextBtn = elTarget.querySelector('.js-checklist-exercise-btn');

        var minChecked = elTarget.getAttribute('data-min-required') ? parseInt(elTarget.getAttribute('data-min-required')) : 2;
        var maxChecked = elTarget.getAttribute('data-max-required') ? parseInt(elTarget.getAttribute('data-max-required')) : null;

        // Store all enabled checkboxes
        var elEnabled = elTarget.querySelectorAll('input[type=checkbox]:not([disabled])');

        //
        var checkTotal = function(){
            if(elNextBtn){
                var elChecked = elTarget.querySelectorAll('input[type=checkbox]:checked');
                if(elChecked && elChecked.length >= minChecked){
                    elNextBtn.classList.remove('disabled');
                }else{
                    elNextBtn.classList.add('disabled');
                }
            }
        };

        /**
         * Check the reflection content fields are valid and enable the finish button if they are
         */
        var checkReflectionFields = function(){
            if(elNextBtn){
                var elTextarea = elTarget.querySelectorAll('textarea');
                if(elTextarea && elTextarea.length){
                    //There are textareas - check each one has some text in before enabling the button
                    elInvalid = elTarget.querySelectorAll('textarea:invalid');
                    if(elInvalid.length){
                        elNextBtn.classList.add('disabled');
                    }else{
                        elNextBtn.classList.remove('disabled');
                    }

                }else{
                    //There are no text areas - do not touch the button

                }
            }
        };

        var checkMaxTotal = function(){

            if(maxChecked){
                var elChecked = elTarget.querySelectorAll('input[type=checkbox]:checked');
                if(elChecked && elChecked.length >= maxChecked){

                    // Disable all originally enabled excl. checked
                    forEachNode(elEnabled, function (i, el) {
                        if(!el.checked){
                            el.disabled = true;
                            var elWrapper = el.closest('.js-checklist-exercise-checkbox-wrapper');
                            console.log(elWrapper);
                            if(elWrapper){
                                elWrapper.classList.add('disabled');
                            }
                        }
                    });

                }else{

                    // Enable all originally enabled
                    forEachNode(elEnabled, function (i, el) {
                        el.disabled = false;
                        var elWrapper = el.closest('.js-checklist-exercise-checkbox-wrapper');
                        if(elWrapper){
                            elWrapper.classList.remove('disabled');
                        }
                    });

                }
            }
        };



        /* Change Events */

        var checklistChange = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest('.js-checklist-exercise-checkbox')) return;

            // Button clicked
            e.preventDefault();

            // Check total
            checkTotal();
            //checkReflectionFields();
            checkMaxTotal();

        };


        on('change', elTarget, checklistChange);


    },


};
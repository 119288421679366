/*!

 File: set-scroll-anchor.js
 Author: Ember
 Version: 1.0.2 - [MINA-764] update scrollit
 JS Dependencies:
     scrollToElement

 CSS Dependencies:

 Description:
    Scroll to anchor in page

*/

var setScrollAnchor = {
    init: function (selector) {

        var me = setScrollAnchor;

        var clickEvt = function(e){

            var el = e.target;

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;


            // get href for ID
            var btnHref = e.target.getAttribute('href');

            // If hash exists
            if (btnHref && btnHref.indexOf('#') > -1) {

                // Button clicked
                e.preventDefault();

                // Destroy any sticky titles
                /*if(setStickyTitles){
                    setStickyTitles.destroy();
                }*/

                // Scroll
                me.scrollToElement(btnHref, true);

                // Change hash url (without affecting back button)
                /*if(history.replaceState){
                    history.replaceState(undefined, undefined, btnHref)
                }else{
                    window.location.hash = '#' + btnHref;
                }*/

            }

        }

        // Attach open event
        on('click', document, clickEvt);

    },
    scrollToElement: function(hashID, clicked){
        var me = setReportNav;

        var pauseCallback = function(){
            // re init Sticky titles
            /*if(clicked){
                var elStickyTitles = document.querySelectorAll('.js-sticky-title');
                if(elStickyTitles && elStickyTitles.length>0){
                    setStickyTitles.init(elStickyTitles);
                }
            }*/
        }

        // scroll to element
        if(hashID!='#'){
            var elScrollTo = document.querySelector(hashID);
        }else{
            var elScrollTo = document.querySelector('.main');
        }

        if(elScrollTo){
            //scrollIt(elScrollTo, 400, 90, pauseCallback);
            ScrollIt({
                pos: scrollPos,
                speed: 400,
                offset: 90
            }).runScroll();
        }
    },
    destroy: function (target) {
    }
}

/*!

 File: set-mobile-nav.js
 Author: Ember
 Version: 1.0.4 [MS-614] - body scroll
 JS Dependencies:
    set-overlay,
    helper-on,
    'closest' polyfill

 CSS Dependencies:

 Description:
     Open close mobile menu
*/

var setMobileNav = {
    buttonClick: null,
    scrollPos: 0,
    // Click outside
    navOutClick: function (e) {
        var me = setMobileNav;
        if(!e.target.closest('.js-mobile-nav-click')){
            // remove close eventHandler
            off('click', document, me.navOutClick);
            //
            e.preventDefault();
            me.closeNav();
        }
    },
    init: function () {

        var me = setMobileNav;

        // Button click
        me.buttonClick = function(e){

            // Don't run if right-click or command/control + click
            if (e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-mobile-nav-btn')) return;

            // Button clicked
            e.preventDefault();

            // Open or close
            if(document.body.classList.contains('mob-nav-open')){
                me.closeNav();
            }else{
                me.openNav();
                // set close eventHandler
                on('click', document, me.navOutClick);
            }
        };

        // attach event
        on('click', document, me.buttonClick);
    },
    openNav: function(){
        var me = setMobileNav;
        // Show overlay
        setOverlay.show();
        // add class
        document.body.classList.add('mob-nav-open');
        // Body scroll lock
        var elNavContainer = document.querySelector('.js-nav-scroll-lock');
        if(elNavContainer){
            // Need to pause the scroll watchers
            if(setReportNav && typeof setReportNav === 'object' && setReportNav !== null){
                setReportNav.pauseWatchers = true;
            }
            //
            me.scrollPos = window.scrollY;
            bodyScrollLock.disableBodyScroll(elNavContainer, {
                reserveScrollBarGap: true
            });
        }
    },
    closeNav: function(){
        var me = setMobileNav;

        // remove close eventHandler
        off('click', document, me.navOutClick);
        // Body scroll lock
        bodyScrollLock.clearAllBodyScrollLocks();
        window.scrollTo(0, me.scrollPos);
        // Need to pause the scroll watchers while we scroll
        setTimeout(function(){ 
            if(setReportNav && typeof setReportNav === 'object' && setReportNav !== null){
                setReportNav.pauseWatchers = false;
            }
        }, 400);
        // hide overlay
        setOverlay.hide();
        // remove class
        document.body.classList.remove('mob-nav-open');
    },
    destroy: function(){

        var me = setMobileNav;

        // close nav
        me.closeNav();

        // detatch event
        off('click', document, me.buttonClick);

    }
}

/*!

 File: set-date-picker.js
 Author: Ember
 Version: 1.0.10 - [MS-911] disable-before / disable-after
 JS Dependencies:
    jQuery,
    bootstrap-datepicker.js     https://bootstrap-datepicker.readthedocs.io/en/latest/,
    moment

 CSS Dependencies:
    _custom-datepicker.scss

 Description: Date picker UI helper

*/


var setDatePicker = {
    init: function ($target, callback) {

        var $input = $target.children('input').first();

        if (!$input.is('[readonly]')) {// don't want datepicker on readonly fields

            // Set date format
            var format = $input.data('format');
            var pickerFormat = 'dd-M-yyyy';
            var jsFormat = 'DD-MMM-yyyy';
            var thisMinViewMode = 'days';

            // Convert dateformat from PHP to JS
            if (format) {

                //get seperator
                var s = '-';
                if(format.includes('/')){
                    s = '/';
                }

                switch(format) {

                    case 'j'+s+'M'+s+'Y': // 3~Jan~2016
                        pickerFormat = 'd'+s+'M'+s+'yyyy';
                        jsFormat = 'D'+s+'MMM'+s+'YYYY';
                        break;

                    case 'd'+s+'M'+s+'Y': // 03~Jan~2016
                        pickerFormat = 'dd'+s+'M'+s+'yyyy';
                        jsFormat = 'DD'+s+'MMM'+s+'YYYY';
                        break;

                    case 'j'+s+'n'+s+'Y': // 3~1~2016
                        pickerFormat = 'd'+s+'m'+s+'yyyy';
                        jsFormat = 'D'+s+'M'+s+'YYYY';
                        break;

                    case 'd'+s+'m'+s+'Y': // 03~01~2016
                        pickerFormat = 'dd'+s+'mm'+s+'yyyy';
                        jsFormat = 'DD'+s+'MM'+s+'YYYY';
                        break;

                    case 'd'+s+'m'+s+'y': // 03~01~16
                        pickerFormat = 'dd'+s+'mm'+s+'yy';
                        jsFormat = 'DD'+s+'MM'+s+'YY';
                        break;

                    case 'd'+s+'n'+s+'Y': // 03~1'+s+'~2016
                        pickerFormat = 'dd'+s+'m'+s+'yyyy';
                        jsFormat = 'DD'+s+'M'+s+'YYYY';
                        break;

                    case 'j'+s+'m'+s+'Y': // 3~01~2016
                        pickerFormat = 'd'+s+'mm'+s+'yyyy';
                        jsFormat = 'D'+s+'MM'+s+'YYYY';
                        break;

                    case 'M'+s+'Y': // Jan~2016
                        pickerFormat = 'M'+s+'yyyy';
                        jsFormat = 'MMM'+s+'YYYY';
                        thisMinViewMode = 'months';
                        break;

                    case 'm'+s+'Y': // 01~2016
                        pickerFormat = 'mm'+s+'yyyy';
                        jsFormat = 'DD'+s+'YYYY';
                        thisMinViewMode = 'months';
                        break;

                    case 'M'+s+'y': // Jan~16
                        pickerFormat = 'M'+s+'yy';
                        jsFormat = 'MMMM'+s+'YY';
                        thisMinViewMode = 'months';
                        break;

                    case 'm'+s+'y': // 01~16
                        pickerFormat = 'mm'+s+'yy';
                        jsFormat = 'DD'+s+'YY';
                        thisMinViewMode = 'months';
                        break;

                    default:
                        pickerFormat = format;

                }
            }

            // disable all dates before today
            var startDate = null;
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr($input, 'data-past-disabled', true)) {
                var nowDate = new Date();
                var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
                startDate = today;
            }

            // disable all dates before chosen date
            if (checkDataAttr($input, 'data-disable-before')) {
                defaultDateObj = moment($input.data('disable-before'), jsFormat);
                //
                var beforeDate = new Date(defaultDateObj.year(), defaultDateObj.month(), defaultDateObj.date(), 0, 0, 0, 0);
                startDate = beforeDate;
            }

            var endDate = null;
            // disable all dates after chosen date
            if (checkDataAttr($input, 'data-disable-after')) {
                defaultDateObj = moment($input.data('disable-after'), jsFormat);
                //
                var afterDate = new Date(defaultDateObj.year(), defaultDateObj.month(), defaultDateObj.date(), 0, 0, 0, 0);
                endDate = afterDate;
            }

            // Init date range
            var dateRange = false;
            if($target.hasClass('input-daterange')){
                dateRange=true;
            }

            // Disable days
            var disableDays = null;
            // If data attr exists and isn't empty
            if (checkDataAttr($input, 'data-disable-days')) {
                disableDays = $input.data('disable-days');
            }

            // Disable dates
            var disableDates = null;
            // If data attr exists and isn't empty
            if (checkDataAttr($input, 'data-disable-dates')) {
                disableDates = $input.data('disable-dates');
                // convert all the dates in array to universal format
                for(var i = 0; i<disableDates.length; i++){
                    var newDate = moment(disableDates[i], jsFormat).toISOString();
                    disableDates[i] = newDate;
                }
            }

            // Enable dates (disable all except...)
            var enableDates = null;
            // If data attr exists and isn't empty
            if (checkDataAttr($input, 'data-enable-dates')) {
                enableDates = $input.data('enable-dates');
                // convert all the dates in array to universal format
                for(var i = 0; i<enableDates.length; i++){
                    var newDate = moment(enableDates[i], jsFormat).toISOString();
                    enableDates[i] = newDate;
                }
            }

            // Override any disabled days / dates
            var disableExceptions = null;
            // If data attr exists and isn't empty
            if (checkDataAttr($input, 'data-disable-exceptions')) {
                disableExceptions = $input.data('disable-exceptions');
                // convert all the dates in array to universal format
                for(var i = 0; i<disableExceptions.length; i++){
                    var newDate = moment(disableExceptions[i], jsFormat).toISOString();
                    disableExceptions[i] = newDate;
                }
            }

            // Jump to date
            var defaultDate = null;
            // If data attr exists and isn't empty
            if (checkDataAttr($input, 'data-default-date')) {
                defaultDateObj = moment($input.data('default-date'), jsFormat);
                //
                defaultDate = {};
                defaultDate.year = defaultDateObj.year();
                defaultDate.month = defaultDateObj.month();
                defaultDate.day = defaultDateObj.date();
            }

            // Highlight dates
            var highlightDates = null;
            // If data attr exists and isn't empty
            if (checkDataAttr($input, 'data-highlight-dates')) {
                highlightDates = $input.data('highlight-dates');
                // convert all the dates in array to universal format
                for(var i = 0; i<highlightDates.length; i++){
                    var newDate = moment(highlightDates[i], jsFormat).toISOString();
                    highlightDates[i] = newDate;
                }
            }

            // Custom event which Vanilla js scripts can hook into
            var changeEvt = new CustomEvent('datePickerChange', {
                bubbles: true,
                cancelable: true,
            });

            // Function to wrap init so we can fire it from getJson success
            var initDatePicker = function(){
                if (dateRange) {

                    $target.datepicker({
                        format: pickerFormat,
                        assumeNearbyYear: true,
                        keyboardNavigation: true,
                        todayBtn: "linked",
                        // forceParse: false, // This was submitting the form on 'enter' press
                        todayHighlight: true,
                        autoclose: true,
                        startDate: startDate,
                        endDate: endDate,
                        defaultViewDate: defaultDate,

                    }).on('changeDate', function(e) {
                        // dispatch Custom event for vanilla js
                        $input[0].dispatchEvent(changeEvt);
                        // Run callback
                        if (typeof callback === 'function'){
                            callback();
                        }
        		    });

                    $target.find('.input-group-addon:first').click(function(e){
                        e.preventDefault();
                        $(this).parent().find('.form-control:first').focus();
                    });

                    $target.find('.input-group-addon:last').click(function(e){
                        e.preventDefault();
                        $(this).parent().find('.form-control:last').focus();
                    });

                } else {

                    $target.datepicker({
                        format: pickerFormat,
                        assumeNearbyYear: true,
                        keyboardNavigation: true,
                        todayBtn: "linked",
                        // forceParse: false, // This was submitting the form on 'enter' press
                        todayHighlight: true,
                        autoclose: true,
                        weekStart: 1,
                        startDate: startDate,
                        endDate: endDate,
                        minViewMode: thisMinViewMode,
                        //daysOfWeekDisabled: disableDays,
                        //datesDisabled: disableDates,
                        defaultViewDate: defaultDate,
                        beforeShowDay: function(date) {
                            // convert date to universal format
                            var string = date.toISOString();

                            // Highlight dates
                            if(highlightDates){
                                if(highlightDates.indexOf(string) != -1){
                                    return {
                                        classes: 'highlight'
                                    };
                                }
                            }

                            var thisEnabled = true;
                            var day = date.getDay();

                            // disable dates
                            if(disableDates){
                                // return false if exists in array | true if not
                                thisEnabled = disableDates.indexOf(string) == -1;
                            }

                            // enable dates (disabling everything else)
                            if(enableDates){
                                // return true if exists in array | false if not
                                thisEnabled = enableDates.indexOf(string) != -1;
                            }

                            // disable days (after dates)
                            if(disableDays){
                                for(var i=0; i<disableDays.length; i++){
                                    if (day == disableDays[i]){
                                        thisEnabled = false;
                                    }
                                }
                            }

                            // Override any disabled days / dates
                            if(disableExceptions){
                                // update var if exists in array
                                if(disableExceptions.indexOf(string) != -1){
                                    thisEnabled = true;
                                }
                            }

                            //return enabled;
                            return thisEnabled;

                        },
                    }).on('changeDate', function(e) {
                        // dispatch Custom event for vanilla js
                        $input[0].dispatchEvent(changeEvt);
                        // Run callback
                        if (typeof callback === 'function'){
                            callback();
                        }
        		    });
                }
            }


            // allows data-attribute and data-attribute="true" otherwise returns false
            if (checkDataAttr($input, 'data-disable-bank', true)) {

                // Check for exceptions
                // If data attr exists and isn't empty
                var bankExceptions = [];
                if (checkDataAttr($input, 'data-bank-exceptions')) {
                    bankExceptions = $input.data('bank-exceptions');
                }

                // Make ajax request to get bank holidays
                var bankPath = 'https://www.gov.uk/bank-holidays.json';
                $.getJSON( bankPath )
                    .done(function( data ) {

                        $.each(data, function( key, val ) {
                            if(key=='england-and-wales'){

                                for(var i=0; i<this.events.length; i++){

                                    var dateValid = false;

                                    // Check if in future
                                    if(moment(this.events[i].date).isAfter()){
                                        dateValid = true;
                                    }

                                    // Check if not exception
                                    if(bankExceptions.length){
                                        for(var j=0; j<bankExceptions.length; j++){
                                            if(bankExceptions[j] === this.events[i].title){
                                                dateValid = false;
                                            }
                                        }
                                    }

                                    //
                                    if(dateValid){
                                        // convert to correct format and add to list
                                        if(disableDates==null){
                                            disableDates=[];
                                        }
                                        // convert to universal format
                                        var tempDate = moment(this.events[i].date).format(jsFormat);
                                        disableDates.push(moment(tempDate, jsFormat).toISOString());
                                    }

                                }

                            }
                        });

                        initDatePicker();


                    })
                    .fail(function( jqxhr, textStatus, error ) {
                        console.log( 'Datepicker error connecting to '+bankPath );
                        initDatePicker();
                });

            }else{

                // Just init datepicker
                initDatePicker();

            }

        }

        $target.on('focus', 'input', function () {
            $(this).parent().children('.input-group-addon').addClass('active');
        });

        $target.on('blur', 'input', function () {
            $(this).parent().children('.input-group-addon').removeClass('active');
        });

    },

    destroy: function () {
    }

};

/*!

 File: set-home-screen-popup.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    JS Cookies

 CSS Dependencies:

 Description:
    Show a popup on iOS phones to point to the share icon

*/
var setHomeScreenPopup = {
    init: function () {

        var homeScreenCookie = Cookies.get('homescreen_viewed');

        var timer;

        var ua = window.navigator.userAgent;
        var webkit = !!ua.match(/WebKit/i);
        var iOSPhone = !!ua.match(/iPhone/i);
        //var iOSTablet = !!ua.match(/iPad/i);
        //var platformiPad = !!navigator.platform.match(/iPad/i);
        
        var iOSSafariPhone = iOSPhone && webkit && !ua.match(/CriOS/i);
        //var iOSSafariTablet = iOSTablet && webkit && !ua.match(/CriOS/i);
        //var iOSSafariTabletB = platformiPad && webkit && !ua.match(/CriOS/i);
        
        var hideHome = function(){
            // kill timer
            clearTimeout(timer);
            // remove close eventHandler
            off('click', document, hideHome);
            //
            setOverlay.hide();
            document.body.classList.add('hide-home-screen-popup');
            document.body.classList.remove('show-home-screen-popup');
            //
            Cookies.set('homescreen_viewed', 'yes', { expires: 365 });
        }

       if(iOSSafariPhone){
            if(homeScreenCookie!='yes'){
                setTimeout(function(){
                    //
                    setOverlay.show();
                    //
                    document.body.classList.add('show-home-screen-popup');
                    //
                    timer = setTimeout(function(){
                        hideHome();
                    }, 6000);

                    // set close eventHandler
                    on('click', document, hideHome);

                }, 1000);
            }
        }

    },
    destroy: function () {

    }
};
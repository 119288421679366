/*!

 File: polyfill-array-foreach.js
 Author: Mozilla
 Version: 1.0.1
 JS Dependencies:

 Description:
    https://vanillajstoolkit.com/polyfills/arrayforeach/

*/

if (!Array.prototype.forEach) {
	Array.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

/*!

 File: set-report-hidden-feedback.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    helper-on,
    helper-off

 CSS Dependencies:


 Description:
     Enable button on slide, show content on button press
*/

var setReportHiddenFeedback = {
    init: function (elTarget) {

        var elbtn = elTarget.querySelector('.js-report-hidden-feedback-btn'),
            elContent = elTarget.querySelector('.report-hidden-feedback-content'),
            elSlider = elTarget.querySelector('.js-report-hidden-feedback-slider');

        var sliderChangeCallback = function(){
            elbtn.disabled = false;
            elbtn.focus();
            //
            //elSlider.classList.add('disabled');
            //elSlider.querySelector('.range-slider-dragger-pulse').classList.remove('range-slider-dragger-pulse');
        };

        var getHeight = function(){
            // Get height of element (without max-height)
            var clone = elContent.cloneNode(true);
            clone.style.cssText = 'visibility: hidden; margin: 0; display: block; max-height:none; ';
            var height = (elTarget.parentNode.appendChild(clone)).clientHeight;
            elTarget.parentNode.removeChild(clone);
            //
            return height;
        }

        on('click', elbtn, function(e){
            e.preventDefault();

            // animate open
            elContent.style.maxHeight = getHeight()+'px';

            // hide button after open
            setTimeout(function(){
                elbtn.style.display = 'none';
                elContent.style.maxHeight = 'none';
                elContent.style.overflow = 'visible';
                // Reacluclate any sticky titles
                /*if(setStickyTitles){
                    setStickyTitles.recalculate();
                }*/
            },400);

        });

        // init slider with callback
        if(elSlider){
            var range = new RangeSlider;
            range.init({
                el: elSlider,
                changeCallBack: sliderChangeCallback
            });
        }
    }
}

/*!

 File: set-marker-place.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill
    'PEP' - Pointer events polyfill

 CSS Dependencies:

 Description:
     Participant Mindset Grid Dragging UI
*/

// Basic helper for checking if Pointer event is supported (for <= iOS 13 )
function isMouseEventSupported(eventName) {
    var el = document.createElement('div');
    eventName = 'on' + eventName;
    var isSupported = (eventName in el);
    if (!isSupported) {
        el.setAttribute(eventName, 'return;');
        isSupported = typeof el[eventName] == 'function';
    }
    el = null;
    return isSupported;
}



;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.MarkerPlace = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var MarkerPlace = (function () {

        var Constructor = function () {

            //
    		// Variables
            //
            var publicAPIs = {};
            var settings = {};

            var offsetLeft,
                offsetTop,
                bboxWidth,
                bboxHeight,
                xposRatio,
                yposRatio,
                raf;

            var boundsOffset;

            var elBox,
                elMarker,
                elBtn,
                elOutputX,
                elOutputY;

            //
    		// Methods
            //
            
            /**
    		* Private methods
            */
            
            var initPlace = function(el){

                elBox = el.querySelector('.js-place-marker-container');

                elMarker = el.querySelector('.js-place-marker-disc');

                boundsOffset = elMarker.offsetWidth/2
        
                elOutputX = el.querySelector('.js-place-marker-output-x');
                elOutputY = el.querySelector('.js-place-marker-output-y');

                // Press Event
                if(!isMouseEventSupported('pointerdown')){
                    // Pointer events arent supported in old iOS so using this as a basic fallback
                    el.addEventListener('mousedown', userPressed);
                    el.addEventListener('touchdown', userPressed);
                    // just run callback
                    if (typeof settings.callBack === "function") { 
                        settings.callBack();
                    }
                }else{
                    el.addEventListener('pointerdown', userPressed, { passive: true });
                }

            }

            var userPressed = function(e){

                elBtn = e.target.closest('.js-place-marker-click');
                if (!elBtn) return;
    
                elBox.classList.add('show-marker');
                elBox.classList.add('show-marker-down');
    
                offsetLeft = elBtn.getBoundingClientRect().left;
                offsetTop = elBtn.getBoundingClientRect().top;
                
                bboxWidth = elBtn.offsetWidth;
                bboxHeight = elBtn.offsetHeight;
    
                userMoved(e);
    
                document.addEventListener('pointermove', userMoved, { passive: true });
                document.addEventListener('pointerup', userReleased, { passive: true });
                document.addEventListener('pointercancel', userReleased, { passive: true });
    
                if(!elBox.classList.contains('animating')){
                    setTimeout(function(){
                        elBox.classList.add('animating');
                    }, 200);
                }
    
            }
    
            var userMoved = function(e){
                if (!raf) {
    
                    // Convert mouse X pos to a rounded percentage
                    var xpos = e.clientX-offsetLeft;
                    xpos = xpos > boundsOffset ? xpos : boundsOffset; // Left constraint
                    xpos = xpos < bboxWidth-boundsOffset ? xpos : bboxWidth-boundsOffset; // Right constraint
                    xposRatio = (xpos / bboxWidth) * 100;
                    xposRatio = Math.round(xposRatio * 100) / 100; // round to 2 decimal places
                    
                    // Convert mouse Y pos to a rounded percentage
                    var ypos = e.clientY-offsetTop;
                    ypos = ypos > boundsOffset ? ypos : boundsOffset // Left constraint
                    ypos = ypos < bboxHeight-boundsOffset? ypos : bboxHeight-boundsOffset; // Right constraint
                    yposRatio = (ypos / bboxHeight) * 100;
                    yposRatio = Math.round(yposRatio * 100) / 100; // round to 2 decimal places
    
                    raf = requestAnimationFrame(userMovedRaf);
    
                }
            }
    
            var userMovedRaf = function(e){
                //
                elMarker.style.left = xposRatio + '%';
                elMarker.style.top = yposRatio + '%';
                //
                if(elOutputX){
                    elOutputX.value = xposRatio;
                }
                if(elOutputY){
                    elOutputY.value = yposRatio;
                }
                //
                raf = null;
            }
    
            var userReleased = function(e){
                elBox.classList.remove('show-marker-down');
                //
                document.removeEventListener('pointermove', userMoved);
                document.removeEventListener('pointerup', userReleased);
                document.removeEventListener('pointercancel', userReleased);
                // run callback
                if (typeof settings.callBack === "function") { 
                    settings.callBack();
                }
            }


            /**
            * Public methods
            */

            publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;
                settings.callBack = options.callBack || null;

                if(settings.el){
                    initPlace(settings.el);
                }else{
                    console.log('marker-place.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

            };

            //
    		// Return the Public APIs
    		//

    		return publicAPIs;

        };

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the MarkerPlace function to be assigned to the window object/module
    return MarkerPlace;

});
/*!

 File: set-ajax-button.js
 Author: Ember
 Version: 1.0.1 [MS-732] - 
 JS Dependencies:
    'helper-on' - helper,

 Description:
    Smooth scroll to block when clicked

*/

var setPageScroll = {
    init: function (selector) {

        var me = setAjaxButton;

        var clickEvt = function(e){

            var el = e.target;

            // Closest captures self as well a parents
            if (!el.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            var elTarget = e.target.closest('a');

            // scroll to element
            var hashId = elTarget.getAttribute('href');
            var elScrollTo = document.querySelector(hashId);
            if(elScrollTo){
                ScrollIt({
                    pos: elScrollTo,
                    speed: 400,
                    offset: 90
                }).runScroll();
            }
            

        }

        // Attach open event
        on('click', document, clickEvt);

    }
}

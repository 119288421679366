/*!

 File: form-alerts.js
 Author: Ember
 Version: 1.0.5 - [MMP-5] - check for csrf token
 JS Dependencies:
    helper-on

 Description:
    Helper function for showing / hiding alerts

*/

var formAlert = {
    showTimerA:{},
    showTimerB:{},
    hideTimerA:{},
    hideTimerB:{},
    hideAfterTimer:{},
    //
    show: function (elTarget, alertText, hideAfter, status) {

        var me = formAlert;

        // might have passed in a jquery object
        if (elTarget instanceof jQuery){
            elTarget = elTarget[0];
        }

        // Elements
        var elAlertInner = elTarget.querySelector('.alert');
        var elAlertText = elTarget.querySelector('.js-alert-content');


        // Clear Timers
        me.clearTimers();


        // Set Text
        if(alertText && alertText!=''){
            if(elAlertText){
                elAlertText.innerText = alertText;
            }
        }

        // Set classes
        elAlertInner.classList.remove('alert-success', 'alert-danger', 'alert-warning', 'alert-info');
        if(status === 'error' || status === 'danger'){
            elAlertInner.classList.add('alert-danger');
        }else{
            elAlertInner.classList.add('alert-success');
        }


        // Get height
        var alertHeight = elAlertInner.offsetHeight;
        alertHeight += parseInt(window.getComputedStyle(elAlertInner).getPropertyValue('margin-bottom'));
        // Set height
        elTarget.style.maxHeight = alertHeight + 'px';

        // Show alert
        me.showTimerA = setTimeout(function(){
            // Fade in
            elTarget.classList.remove('alert-hidden');
            // Remove inline styles after anim
            me.showTimerB = setTimeout(function(){
                elTarget.style.maxHeight = '';
                // Hide again
                if(hideAfter){
                    me.hideAfterTimer = setTimeout(function(){
                        me.hide(elTarget);
                    }, 5000);
                }
            }, 500);
        }, 300);
    },
    
    hide: function (elTarget) {

        var me = formAlert;

        // might have passed in a jquery object
        if (elTarget instanceof jQuery){
            elTarget = elTarget[0];
        }

        // post on dismiss?
        var dismissUrl = elTarget.getAttribute('data-update-url')

        if(dismissUrl && dismissUrl != ''){
            me.postDismiss(elTarget, dismissUrl);
        }else{
            me.hideAlert(elTarget);
        }

    },

    hideAlert: function (elTarget) {

        var me = formAlert;

        // Elements
        var elAlertInner = elTarget.querySelector('.alert');

        // Clear Timers
        me.clearTimers();

        // Get height
        var alertHeight = elAlertInner.offsetHeight;
        alertHeight += parseInt(window.getComputedStyle(elAlertInner).getPropertyValue('margin-bottom'));

        // Set Height
        elTarget.style.maxHeight = alertHeight + 'px';

        // Fade out
        elTarget.classList.add('alert-hidden');

        // Close gap
        me.hideTimerA = setTimeout(function(){
            elTarget.style.maxHeight = '0px';
            me.hideTimerB = setTimeout(function(){
                elTarget.style.maxHeight = '';
            }, 500);
        }, 300);

    },

    postDismiss: function(elTarget, dismissUrl) {
        var me = formAlert;

        // Disable button        
        var elCloseBtn = elTarget.querySelector('.js-alert-close');
        if(elCloseBtn){
            elCloseBtn.disabled = true;
        }

        // Get any extra data
        var params = {};

        var extraData = elTarget.getAttribute('data-extra');
        if(extraData && extraData != ''){
            extraData = JSON.parse(extraData);
            for (var i in extraData) {
                params[i] = extraData[i];
            }
        }

        // Call back function name can be passed in as a data attribute.
        var callback;
        // If data attr exists and isn't empty
        if (checkDataAttr(elTarget, 'data-callback')) {
            callback = elTarget.getAttribute('data-callback');
        }

        // AXIOS Request
        var config = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        }

        // Add csrf token if exists
        var elCsrf = document.head.querySelector('meta[name="csrf-token"]');
        var csrf;

        if(elCsrf){
            config.headers['X-CSRF-TOKEN'] = elCsrf.content;
        }

        // Default error message
        var errorMessage = 'Sorry, there was a problem with your request.';

        // Send Request
        axios.post(dismissUrl, params, config)
            .then(function (response) {

                var responseJson = response.data;

                // Returned Success
                if(responseJson.status=='success'){

                    // Returned Message
                    if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                        
                        Notify({
                            text: responseJson.message.text,
                            status: 'success',
                            offset: 100
                        }).showNotify();

                    }

                    // Hide the alert
                    me.hideAlert(elTarget);

                    // Run callback
                    if (typeof window[callback] === 'function') {
                        window[callback](elTarget, response.data);
                    }

                }

                // Returned Error
                // Just show a toast
                if(responseJson.status=='error'){

                    // See if a message was returned
                    if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                        errorMessage = responseJson.message.text;
                    }
                    
                    Notify({
                        text: errorMessage,
                        status: 'error',
                        offset: 100
                    }).showNotify();

                }

                if(elCloseBtn){
                    elCloseBtn.disabled = false;
                }
            })
            .catch(function (error) {

                console.log(error);

                if(elCloseBtn){
                    elCloseBtn.disabled = false;
                }

                if(error.response){
                    errorMessage  += '<br>' + error.response;
                }

                // Show error notification
                Notify({
                    text: errorMessage,
                    status: 'error',
                    offset: 100
                }).showNotify();

            });
            
    },

    reset: function (elTarget) {
        elTarget.classList.add('alert-hidden');
        elTarget.style.maxHeight = '';
    },

    clearTimers: function(){
        var me = formAlert;
        //
        clearTimeout(me.showTimerA);
        clearTimeout(me.showTimerB);
        clearTimeout(me.hideTimerA);
        clearTimeout(me.hideTimerB);
        clearTimeout(me.hideAfterTimer);
    }
};

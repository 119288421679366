/*!

 File: set-progress.js
 Author: Ember
 Version: 1.2.7 [EGS-414]

 JS Dependencies:

 CSS Dependencies:

 Description: set the data of a progress bar

*/

var setProgress = {
    init: function (elTarget) {
    },
    changeValue : function (elTarget, current, total, showText) {
        if(elTarget){
            var p = Math.floor(((current)/total)*100);
            elTarget.style.width = p + '%';
            elTarget.setAttribute('aria-valuenow', p);
            // show text
            if(showText){
                var elText = elTarget.querySelector('.progress-bar-text');
                if(elText){
                    elText.style.display = 'block';
                    elText.innerHTML = current + ' of ' + total;
                }
            }
        }else{
            console.log('no target element');
        }
    }
}

/*!

 File: set-button-validate.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:
    'forEachNode' - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 CSS Dependencies:

 Description:
     Disable submit button unless valid
*/

var setButtonValidate = {
    init: function (elTarget) {
        var me = setButtonValidate;

        var changeEvt = function(e){
            me.checkFields(elFields, elBtn);
        }

        if(elTarget){
            
            var elBtn = elTarget.querySelector('.js-button-validate-btn');
            var elFields = elTarget.querySelectorAll('[required]');

            if(elFields && elBtn){
                me.checkFields(elFields, elBtn);

                // Attach open event
                on('change', document, changeEvt);
                on('input', document, changeEvt);

                // Custom selects have their own event
                on('customSelectChange', document, changeEvt);

                // Date pickers have their own event
                on('datePickerChange', document, changeEvt);
                
            }

        }

    },
    checkFields: function(elFields, elBtn){

        var valid = true;

        forEachNode(elFields, function (i, el) {
            if(!el.value || el.value===''){
                valid = false;
            }
        });

        if (valid){
            elBtn.disabled = false;
        }else{
            elBtn.disabled = true;
        }

    }

}
